.wrap {
  padding: 4.8rem;
  position:relative;

}





.whatwe-client{
  background-image: url("./../../images/network-hook.jpg");
  background-position: right center;
  /* background-size: cover; */
  background-repeat: no-repeat;
}

.new-title-outline {
  color: #3a3a3a;
  display: block;
  font-size: 144px;
  font-weight: 900;
  letter-spacing: -1px;
  line-height: .85;
  text-transform: uppercase;
  width: -webkit-max-content;
  width: max-content;
}

.new-lead {
  font-weight: 500!important;
  line-height: 35px;
  margin-top: 1.5rem;
  word-break: break-word;
  font-size: 1.25rem;
}


.grayscale{
  filter: grayscale(1);
}
.pt150
{
  padding-top: 385px !important;
}
.margintop500
  {
    margin-top: 130px !important;
  }
h3 {
  font-size:2.4rem;
  font-weight: 600;
  text-align:center;
}
.clients {
    align-items: center;
    -webkit-box-align: center;
    display: grid;
    gap: 4.8rem 4.8rem;
    grid-template-columns: auto auto;
    justify-items: center;
    margin: 4.8rem auto;
    max-width: 1024px; }

    .clients li {
      list-style: none;
      display: flex;
      margin: 0;
      transition: all .3s ease-in-out;
      border-radius: 13px;
      min-height: 109px;
      padding: 20px;
      transform: skew(-6deg, 0deg);
      /* vertical-align: middle; */
      justify-content: center;
      align-items: center;
      background-color: white;
  }

.clients:hover li {
filter: blur(0px);
-webkit-filter: blur(0px);
}
.clients:hover li:not(:hover)  {
filter: blur(2px);
-webkit-filter: blur(2px);
}
.clients li:hover {
-webkit-transform: translateY(-0.3rem);
transform: translateY(-0.3rem); 
}

@media (min-width: 1024px) {
.clients {
    grid-template-columns: auto auto auto auto; }
    
/* .clients img {
  height: 4rem; 


} */


}

/* --- Images (gray logo) --- */
/* .clients img {
  -webkit-filter: grayscale(100%) brightness(10%) contrast(10%);
          filter: grayscale(100%) brightness(10%) contrast(10%); } */

.clients img:hover {
  background: none;

  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out; }

  .d3
  {
    font-size: 2.5rem;
    font-weight: 700;
  }

  .partner
  {

    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url(./../../images/certificate.png); */
    /* background-image:url(./../../images/our-certification-black-bg.jpg); */
    /* background-attachment: fixed; */
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;

  }
.grayscale
{
  filter: grayscale() !important;
}

.box-shadow-100
{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.client-bg {
  width: 100%;
  /* background-image: url(http://localhost:3000/static/media/client-wave-bg.824fd319d167d91cceb3.svg); */

  background-size: cover;
  /* background-position: inherit; */
  background-position: center bottom -344px;
  z-index: -999;
  background-attachment: fixed;
  min-height: 100vh;
  overflow: hidden;
}

.width22
{
  width: 50%;
  margin-left: 15px;
}

.ribbon
{
  background-color: #efefef;
  padding: 25px;
}
.clienbgall {
  background-image: url("./../../images/network-hook.jpg");
  background-position: 122% -98%;
  /* background-size: cover; */
  background-repeat: no-repeat;
}


.clients li img {
  width:100%;
}
  /* Responsive Styles */

  @media only screen and (max-width:767px) {
    .clients li img {
      width: 50%;
  }

    .whatwe-client{
      background-image:none;
      background-position: right center;
      /* background-size: cover; */
      background-repeat: no-repeat;
    }
    

    .new-title-outline {
  
      font-size: 55px;
      line-height: .55;
   
  }

  .width22 {
    width: 94%;
    margin-left: 15px;
}

.clients {
  align-items: center;
  -webkit-box-align: center;
  display: grid;
  gap: 4.8rem 4.8rem;
  grid-template-columns: auto;
  justify-items: center;
  margin: 4.8rem auto;

}


h2.bigfont {
  /* font-size: 5vw; */
  font-size: clamp(2.3rem, 10vw, 2rem);
  line-height: 40px;
}

  }

  /* mobile responsive code end */