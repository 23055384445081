.container-case {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;
}

.casebox {
    background-color: #f6f5fa;
    padding:50px !important;
    border-radius: 8px;

}


.container-case-1 { 
    min-height: 700px; 

    /* background-image: url("./../../images//case-2.jpg");  */
    background-repeat: no-repeat; 
    background-position: center center; 
    background-size: cover; 
    /* display: flex; 
    flex-direction: row; 
    align-items: center; */
    background-attachment: fixed;
    background-color: #fff;
  } 
  

  ul.case-list li {
    list-style: disc !important;
    margin-bottom: 14px;
}




.container-listing-1 { 
    min-height: 700px; 
    padding-top: 176px;
    padding-bottom: 100px;

    /* background-image: url("./../../images//case-2.jpg");  */
    background-repeat: no-repeat; 
    background-position: center center; 
    background-size: cover; 
    display: flex; 
    flex-direction: row; 
    align-items: center;
    background-attachment: fixed;
    background-color: #fff;
  } 

@media (min-width: 768px) {
    .container-case {
        flex-direction: row;
        /* align-items: center; */
        padding: 95px 0px;
    }
}
.left-section, .right-section {
    flex: 1;
    padding: 16px;
}
.left-section h1 {
    font-size: 2.5rem;
    color: #1F2937;
    margin-bottom: 16px;
}
.left-section img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.right-section {
    margin-top: 32px;
}
@media (min-width: 768px) {
    .right-section {
        margin-top: 0;
        padding-left: 32px;
    }
}
.right-section p {
    font-size: 1.25rem;
    color: #9CA3AF;
    margin-bottom: 16px;
}
.right-section h2 {
    font-size: 2rem;
    color: #1F2937;
    margin-bottom: 16px;
}
.right-section .description-case {
    color: #212519 !important;
    margin-bottom: 32px !important;
    font-size: 1rem !important;
    line-height: 30px !important;
}
.cta-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #1D4ED8;
    color: white;
    padding: 10px 20px;
    border-radius: 9999px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    font-size: 1rem;
}
.cta-button:hover {
    background-color: #1E40AF;
}
.cta-button svg {
    margin-right: 8px;
}


/* jumbtron css */
.jumbotron {

    /* display: flex; */
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background: url('./../../images/grnd.jpg') no-repeat center center; */
    background-size: cover;

    position: relative;
}

.jumbotron::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 1;
}

.jumbotron .container {
    position: relative;
    z-index: 2;
}

.hero-text {
    font-size: 4rem;
    font-weight: bold;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    font-family: 'Playfair Display', serif;
}
/* jumbtron css end */


/* case listing page */

#casestudy {
  background-color: #fff;
  height: 59vh;
  background-image: url('./../../images/casestudybg.png');
  background-size: contain;
  background-position: 125% 41%;
  background-attachment: fixed;
}
.overlayer-case {
  background-color: rgba(000, 000, 000, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.casepara
{
  font-weight: 800 !important;
}
.blackheading {
  font-size: 4.8em;
  color: #201818;
  font-weight: 900;
}
.band-case{
    width: 90%;
    max-width: 1240px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
  }
  @media (min-width: 30em) {
    .band-case{
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (min-width: 60em) {
    .band-case{
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .card-case {
    background: white;
    text-decoration: none;
    color: #444;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: relative;
    top: 0;
    transition: all 0.1s ease-in;
  }
  .card-case:hover {
    top: -2px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  }
  .card-case article {
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .card-case h1 {
    font-size: 20px;
    margin: 0;
    color: #333;
  }
  .card-case p {
    flex: 1;
    line-height: 1.4;
    color: #212519 !important;
    margin-top: 14px;
    font-size: 14px;
  }
  .card-case span {
    font-size: 12px;
    font-weight: bold;
    color: #999;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin: 2em 0 0 0;
  }
  .card-case .thumb {
    padding-bottom: 60%;
    background-size: cover;
    background-position: center center;
  }
  
  @media (min-width: 60em) {
    .item-1 {
      grid-column: 1/span 2;
    }
    .item-1 h1 {
      font-size: 24px;
    }
  }
   
  
  /* case listing page css end */

/* Mobile css */

@media only screen and (max-width:767px) {
  .blackheading {
    font-size: 3.8em;
    color: #201818;
    font-weight: 900;
    /* overflow-wrap: break-word; */
    word-break: keep-all;
}

.container-case {
  display: block;
  flex-direction: row;
  max-width: 1200px;
  margin: 25px auto;
  padding: 16px;
}
.caseH {
  font-size: 35px;
  font-weight: 800;
  position: absolute;
  margin: 0 auto;
  left: 117px;
  top: 102px;
}
#casestudy {

  height: 42vh;

}

}