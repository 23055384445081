#banking {
    /* background: rgb(242,237,238); */
    background-color: linear-gradient(149deg, rgba(253,253,253,1) 66%, rgba(254,248,248,1) 88%);
    /* height: 100vh; */
    background-image: url(http://localhost:3000/static/media/banking-ai.ae7335cc496cd65d78b9.jpg);
    background-size: contain;
    background-position: 104%;
    background-repeat: no-repeat;
}

.mt123
{
 
  align-items: baseline;
}
.absolute
{
    position: relative !important;
}

.fulltxt {
    position: absolute;
    top: 85px;
    left: 352px;
}
.digi
{
    height: 100vh;
    background-image: url('./.././images/cloud-bg-banking.jpg');
    background-size: cover;
    background-position: 104%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

h1.digifont {
    font-size: 5vw;
    /* font-size: clamp(4.3rem, 10vw, 2rem); */
    line-height: 80px;
    font-weight: 700;
    color: #3a3a3a;
}

#digital-wrap {
    color: #222;
    display: inline-block;
    float: left;
    width: 701px;
    margin: 8%;
    font-size: 1.15vw;
}

.digitalbank
{

    /* height: 100vh; */
    background: linear-gradient(rgba(251, 251, 251, 0.2), rgba(252, 249, 249, 0.2)), url("./.././images/cloud-bg-banking.jpg");
    background-size: cover;
    background-position: 111%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}


.container-vul-bank {
    min-height: 700px;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-attachment: fixed;
    background-image: linear-gradient(45deg,
        rgba(255, 255, 255, 0.75),
        rgba(99, 166, 233, 0.75)), 
          url(
http://localhost:3000/static/media/managed-service-bg.b267279c25335a6368c7.jpg);
}
.digitalbank-sec3
{

    /* height: 100vh; */
    /* background-image: url('./.././images/cloud-bg-banking.jpg'); */
    background: linear-gradient(rgba(255,255,255,.2), rgba(252, 249, 249, 0.2)), url("./.././images/cloud-bg-banking.jpg");
    background-size: cover;
    background-position: 104%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.digi-text {
    letter-spacing: -.03em;

    margin-top: 0;
    margin-bottom: 0;
    font-size: 2.25rem !important;
    font-weight: 900;
    line-height: .9;
    background: radial-gradient(circle, #022815 0%, #ff6161 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.ptop22
{
  padding-top: 175px;
}

/* digi section last before */

.switch-osn { 
    --switcher-scale: var(--spacing); 
    --switcher-threshold: 33.5rem; 
    display: flex; 
    flex-wrap: wrap; 
    gap: var(--switcher-scale); 
    -webkit-padding-start: 0; 
    padding-inline-start: 0; 
    list-style: none;
} 

.switch-dip { 

} 

.max-4tv { 
    max-inline-size: 45rem;
} 

.switch-osn > *  { 
    flex-basis: calc((var(--switcher-threshold) - 100%)*999); 
    flex-grow: 1;
} 

figure { 
    margin-inline: auto;
} 

.image-y2v { 
    display: flex; 
    justify-content: center;
   
} 


/* [class*="heading:"],h2 { 
    display: block;
}  */

.hea-218 { 
    font-size: clamp(1.125rem,1.125rem + 0.375*(100vw - 45rem)/23*1.5111111111,1.5rem);
} 

.hea-218 { 
    font-weight: 700; 
    line-height: 1.25em; 
    letter-spacing: -.01em;
} 



.sta-r86:not([class*="stack--s:"]) > h2 + :not(div, form)  { 
    -webkit-margin-before: var(--s-1); 
    margin-block-start: var(--s-1);
} 

.image-y2v > *  { 
    flex: 1 1; 
    overflow: hidden; 
    border-radius: .375rem;
} 

.image-y2v > *  { 
    max-inline-size: 33.5rem;
} 

strong { 
    font-weight: bolder;
} 

strong { 
    font-weight: 700;
} 

img { 
    border-style: none;
} 

img { 
    max-inline-size: 100%; 
    block-size: auto;
} 


.switch-8wm { 
  --switcher-scale: var(--spacing); 
  --switcher-threshold: 33.5rem; 
  display: flex; 
  flex-wrap: wrap; 
  gap: var(--switcher-scale); 
  -webkit-padding-start: 0; 
  padding-inline-start: 0; 
  list-style: none;
} 

.minuz25
{
  margin-top: -25px;
}

.max-av6 { 
  max-inline-size: 45rem;
} 
.image-mqh > *  { 
  flex: 1 1; 
  overflow: hidden; 
  border-radius: .375rem;
} 

.switch-8wm > *  { 
  flex-basis: calc((var(--switcher-threshold) - 100%)*999); 
  flex-grow: 1;
} 

figure { 
  margin-inline: auto;
} 

.image-mqh { 
  display: flex; 
  justify-content: center;
} 

/* digi section end */

/* last section */
@media all{   

.container-vjx { 
    position: relative; 
    width: 100%; 
    z-index: 1;
  } 

  .container-vjx { 
    padding-left: 19.25vw; 
    padding-right: 10vw;
  } 

}
@media screen and (max-width: 1680px){ 
  .container-vjx { 
    padding-left: 15.25vw; 
    padding-right: 10vw;
  } 
}     

@media all{ 
  .padding-rge  { 
    padding-bottom: 90px;
  } 

  .container-vjx:not(.container-bg) { 
    opacity: 0; 
    transition: opacity 1.2s ease-in-out; 
    transition-delay: 1.2s;
  } 

  .container-vjx  { 
    display: flex; 
    justify-content: space-between;
  } 

  .container-49v  { 
    margin-top: 65px;
  } 

  .container-vjx:not(.container-bg)  { 
    opacity: 1;
  } 

  .container-vjx:before,.container-banner:before,.container:before { 
    background-color: var(--white); 
    bottom: 0; 
    content: ""; 
    display: block; 
    left: auto; 
    pointer-events: none; 
    position: absolute; 
    right: 0; 
    top: 0; 
    width: 100%; 
    z-index: -1;
  } 

  html ::-webkit-scrollbar { 
    width: 0;
  } 

  .section-yia { 
    opacity: 0; 
    transition: opacity .8s; 
    transition-delay: .6s;
  } 

  .slide-rp1 { 
    display: flex; 
    overflow: hidden; 
    position: relative;
  } 

  .section-yia.ani-if8 { 
    opacity: 1;
  } 

  .slide-rp1 .wrapper-8tw  { 
    display: flex; 
    left: 0; 
    position: relative;
  } 

  .slide-rp1 .slide-ega  { 
    flex-shrink: 0;
  } 

  .slide-bpw .slide-ega  { 
    transition: background-color .1s ease-in; 
    width: 33.3333333333%;
  } 

  .slide-bpw .slide-ega:nth-child(n)  { 
    background-color: var(--gray-bg);
  } 

  .slide-bpw .slide-ega.slide-9m6  { 
    background-color: var(--gray-5-hover);
  } 

  .slide-bpw .slide-ega.slide-olt  { 
    background-color: var(--gray-7-hover);
  } 
  .slide-p6o { 
    display: flex; 
    flex-direction: column;
  } 

  .slide-p6o .omixt  { 
    display: flex; 
    height: 46vh; 
    max-height: 444px; 
    min-height: 260px; 
    position: relative;
  } 

  .slide-p6o .omixt::before { 
    background: linear-gradient(347.3deg,var(--gray-bg) 12.49%,hsla(0,0%,100%,0) 42.05%),hsla(0,0%,88%,.5); 
    content: ""; 
    height: 100%; 
    left: 0; 
    opacity: .5; 
    position: absolute; 
    top: 0; 
    transition: opacity .1s ease-in; 
    width: 100%;
  } 

  .slide-oxk { 
    padding: 50px 66px 50px 50px;
  } 

  .slide-bpw .slide-ega.slide-9m6 .omixt::before { 
    background: linear-gradient(185.79deg,var(--gray-bg) 7.72%,hsla(0,0%,100%,0) 45.61%),hsla(0,0%,88%,.5);
  } 

  .slide-bpw .slide-ega.slide-olt .omixt::before { 
    background: linear-gradient(178.99deg,#f5f5f5 -9.32%,hsla(0,0%,100%,0) 52.83%),hsla(0,0%,88%,.3);
  } 

  img { 
    border-style: none;
  } 

  .slide-rp1 .slide-ega img  { 
    pointer-events: none;
  } 

  .slide-bpw .slide-ega img  { 
    height: 100%; 
    -o-object-fit: cover; 
    object-fit: cover; 
    width: 100%;
  } 

 
}     

@media screen and (max-width: 1680px){ 
  h3 { 
    font-size: 40px;
  } 
}     

@media all{ 
  .thi-a7n { 
    font-weight: 300;
  } 

  .bla-trm { 
    color: var(--black);
  } 

  .title-n8i { 
    margin-bottom: 15px;
  } 

  h3:not(.v1, .v4) { 
    font-family: var(--neue_r); 
    font-size: clamp(18px,1.46vw,28px); 
    font-weight: 400; 
    letter-spacing: .01em; 
    line-height: 136%;
  } 

  h3.title-2x9 { 
    font-size: 38px;
  } 


  .gra-cvv { 
    color: var(--gray-1);
  } 

  sup { 
    font-size: 75%; 
    line-height: 0; 
    position: relative; 
    vertical-align: baseline;
  } 

  sup { 
    top: -.5em;
  } 

  p sup  { 
    font-size: 100%; 
    font-weight: 300; 
    top: -.3em;
  } 
}     


@media all and (min-width:1800px) {

    
  h2.digifont {
    font-size: 4vw;
    /* font-size: clamp(4.3rem, 10vw, 2rem); */
    line-height: 91px;
    font-weight: 700;
    color: #3a3a3a;
}
}



@media only screen and (max-width:767px) {

}


/* transformation solution css */
.trans-solution
{

    /* height: 100vh; */
    background: linear-gradient(rgba(251, 251, 251, 0.2), rgba(252, 249, 249, 0.2));
    background-size: cover;
    background-position: 111%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}


.container-trans {
  display: flex;
  align-items:flex-start;
  padding: 20px; /* Add top and bottom padding */
}

.left-content-trans {

  padding: 40px; /* Add padding to left content */
  flex-grow:10;
  width: 60%;


}

  
.intro-digital {
  height: 100vh;
  /* background: #6e4ce1; */
  background-size: contain;
  color: #0c0612;
  background-image: url('./../images/count.jpg');
  opacity: 1;
  /* z-index: -2; */
  position: bottom center;
}
.intro-digital {
  height: 60vh;
}
.contact-form-trans {
  flex-grow:1;
  width: 40%;
  padding: 40px; /* Add padding to contact form */

  /* box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px; */
}

.trans form {
  padding:20px !important;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.ptop75
{
  padding-top: 100px;
}

.rfs input
{
  margin-bottom: 18px;
  border-radius: 5px;
  border: 1px solid green;
  padding: 10px;
}
.rfs input textarea
{
  margin-bottom: 10px;
  border-radius: 5px !important;
  border: 1px solid green !important;
  padding: 10px;
}

img#style-qfoSG {
  border-radius:16px;
}
/* tranforamtion css end */