
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }


  .telecom-sec1
  {
    position: relative;
    background-image: linear-gradient(360deg,deepskyblue,#d5feff,#ffffff);
    background-size: 180% 180%;

    /* background: linear-gradient(360deg,deepskyblue,#d5feff,#ffffff); */
    /* background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    width: 100%;
    min-height: 100vh;
    /* background-size: 180% 180%;
     
    padding-bottom: 100px; */

    /* width: 100%;
    height: 100%; */
    /* background: rgb(12, 16, 18); */
    position: relative;
    overflow: hidden;
  }

  .hero-section {
    width: 100%;
    min-height: 100vh;
    background-image: linear-gradient(360deg,deepskyblue,#d5feff,#ffffff);
    background-image: url('https://preview.uideck.com/items/appify/assets/img/hero/hero-bg.svg');
    background-position: left bottom;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
}
  .white-color
  {
    color: white;
  }

  .telecom-sec1::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;

    background: linear-gradient(360deg,deepskyblue,#d5feff,#ffffff);
    background-size: 180% 180%;
    animation: moveBackground 8s linear infinite; /* Adjust the animation duration and timing function */
  }
  
  @keyframes moveBackground {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(20%, 20%);
    }
  }

  h2.h2-xs {
    font-size: 4rem;
}
  @keyframes moveBackground {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(20%, 20%);
    }
  }
  

  .telecom-sec-2{
    /* background-image: url('./../images/snap-bg1.png'); */
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    background-attachment: fixed;
    background-repeat: no-repeat;
    min-height: 100vh;
    /* background-color: #fff; */
    background-image:url(./../../images/gr1.png);
    /* background-size: 240% 240%;
    animation: gradient-animation-4 45s ease infinite; */
}

.telecom-sec-3
{
    width: 100%;
    background-position: 50% 50%;

    background-size: cover;

    z-index: -999;
    background-image:url(./../../images/gr2.png);
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    height: 100vh;
    margin-top: 1px;


}
.imgadjust img
{
width: 600px;
height: 450px;
display: block;
}
  section.telecom-sec h2 {
    color: #fff;
}
section.telecom-sec p {
    color: #fff;
}

ul.simple-list li {
    color: #212529;
}

.hero-head .ctrl-break {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to bottom, #292c32, #3e3b42, #544b50, #685c5c, #7a6f6a);
}

.telecom-sec-4
{
  width: 100%;
  

    /* background-size: cover; */
    background-position: 50% 50%;
    z-index: -999;
    background-image:url(./../../images/gr3.png);
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    height: 100vh;
  /* background: linear-gradient(189deg,#172023,#2d1f0f,#342702,#1e1821); */

}

.telecom-sec-6
{
  width: 100%;
  

    /* background-size: cover; */
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url(./../../images/gr6-gird.jpg); */
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    height: 100vh;

      background-size: 240% 240%;
    animation: gradient-animation-4 450s ease infinite;
    background-image:
    linear-gradient(45deg,
        rgba(97, 97, 97, 0.75),
        rgba(4, 15, 25, 0.75)), 
          url(
'./../../images/gr6-gird.jpg');
margin-top: 1px;

}

.telecom-sec-7
{
  width: 100%;
  

    /* background-size: cover; */
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url(./../../images/gr6-gird.jpg); */
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    height: 100vh;

      background-size: 240% 240%;
    animation: gradient-animation-4 450s ease infinite;
    background-image:
    linear-gradient(45deg,
        rgba(97, 97, 97, 0.75),
        rgba(4, 15, 25, 0.75)), 
          url(
'./../../images/gr1.jpg');

}

.pt100
{
  padding-top: 176px;
}


@keyframes gradient-animation-4 {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.telecom-sec-5
{
  background: linear-gradient(82deg,#acccfd,#d2bce9,#f9cbd5);
  background-size: 180% 180%;
  animation: gradient-animation-5 24s ease infinite;
  background-position: 50% 50%;
  background-image:url(./../../images/gr4.png);
}


@keyframes gradient-animation-5 {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


/* tringle bg end */
.pill {
  font-size: 12px;

  padding: 0.5em 1em;
  margin: 0.25em;
  border-radius: 1em;
  border: none;
  outline: none;
  background: #dddddd;
  cursor: pointer;
  color: #280606;
}
.pill a{
  text-decoration: none;
  color: inherit;
  font-weight: 600;
}
.pill:not(.pill--selected):hover {
  background: #cccccc;
}

.pill--selected {
  background: #009578;
  color: #280606;
}

.pill--selected2 {
  background: #debbf5;
  color: #280606;
}
.pill--selected3 {
  background: #46473a;
  color: #eeeeee;
}
.pill--selected4 {
  background: #cd9844;
  color: #190f0f;
}
/* pills button css start */



.colorbox{
    /* Required properties */
    position: relative;
    background: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* Use  your own styles here */
    margin: 45px;
    width: 250px;
    height: 123px;  
}
.colorbox:before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top right, #6fffe9 14%, #0b132b 100% );
  transform: translate3d(-1px, 12px, 0) scale(0.94);
  filter: blur(20px);
  opacity: var(0.7);
  transition: opacity 0.3s;
  border-radius: inherit;
}

/* 
* Prevents issues when the parent creates a 
* stacking context. (For example, using the transform
* property )
*/
.colorbox::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  border-radius: inherit;
}
      


.book
{
  width: 100%;
  

    /* background-size: cover; */
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url(./../../images/gr6-gird.jpg); */
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    height: 50vh;

      background-size: 100% 100%;
    animation: gradient-animation-4 450s ease infinite;
    background-image:
           url(
'./../../images/book-bg.jpg');

}


/* From Uiverse.io by gharsh11032000 */ 

.btnpos {

  position: absolute !important;
  top: 76% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;

}

.codepen-button {
  display: block;
  cursor: pointer;
  color: white;
  margin: 0 auto;
  position: relative;
  text-decoration: none;
  font-weight: 600;
  border-radius: 6px;
  overflow: hidden;
  padding: 3px;
  isolation: isolate;
}

.codepen-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 400%;
  height: 100%;
  background: linear-gradient(115deg,#4fcf70,#fad648,#a767e5,#12bcfe,#44ce7b);
  background-size: 25% 100%;
  animation: an-at-keyframe-css-at-rule-that-translates-via-the-transform-property-the-background-by-negative-25-percent-of-its-width-so-that-it-gives-a-nice-border-animation_-We-use-the-translate-property-to-have-a-nice-transition-so-it_s-not-a-jerk-of-a-start-or-stop .75s linear infinite;
  animation-play-state: paused;
  translate: -5% 0%;
  transition: translate 0.25s ease-out;
}

.codepen-button:hover::before {
  animation-play-state: running;
  transition-duration: 0.75s;
  translate: 0% 0%;
}

@keyframes an-at-keyframe-css-at-rule-that-translates-via-the-transform-property-the-background-by-negative-25-percent-of-its-width-so-that-it-gives-a-nice-border-animation_-We-use-the-translate-property-to-have-a-nice-transition-so-it_s-not-a-jerk-of-a-start-or-stop {
  to {
    transform: translateX(-25%);
  }
}

.codepen-button span {
  position: relative;
  display: block;
  padding: 1rem 1.5rem;
  font-size: 1.1rem;
  background: #000;
  border-radius: 3px;
  height: 100%;

}


.btnpos a
{
  text-decoration: none !important;
  margin-top: 10px;
}
/* ThreeJs css */
.large-header {
  position: relative;
  width: 100%;
  background: #d2ea00;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  z-index: 1;
  background-image: url('https://www.marcoguglie.it/Codepen/AnimatedHeaderBg/demo-1/img/demo-1-bg.jpg');
  /* background-image: url('./../../images/case-2.jpg');
  background-attachment: fixed;*/
} 

.main-title {
  position: absolute;
  margin: 0;
  padding: 0;
  color: #f3d300;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 1.5em;
}

.main-title .thin {
  font-weight: 200;
}

@media only screen and (max-width: 767px) {
  .main-title {

    top: 36%;

}

.main-title {
    font-size: 1.3em;
}

h2.h2-xs {
  font-size: 2rem;
}
h5, .h5 {
  font-size: 1.2rem;
}

.pt100 {
  padding-top: 10px;
}


.imgadjust img {
  width: 600px;
  height: 316px;
  display: none;
}


.telecom-sec-6,.telecom-sec-5,
.telecom-sec-4,
.telecom-sec-3,
.telecom-sec-2
{

    margin-top: 40px;


}

.telecom-sec-4
{

}
}

/* ThreeJs Css end */