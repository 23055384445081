.map
{
    width: 100%;


}
figure {
    margin: 0 auto;
    /* width: auto; */
    height: 800px;
}
.maptext {
    position: absolute;
    top: 25px;
    right: 372px;
    color: rgb(136, 136, 136);
    font-weight: 900;
}



/* gsap */
:root {
    --dark: #242423;
  }
  
  html {
    box-sizing: border-box;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  html, body {
    min-height: 100vh;
  }
  

  .logo,

  p {
    margin: 0;
  }
  
  .stage {
    position: relative;
    background: white;
    visibility: hidden;
  }
  
  /*  ========================================================================== 
      Nav Header
      ========================================================================== */
  .header {
    position: fixed;
    left: 40px;
    top: 24px;
    z-index: 100;
    display: flex;
  }
  @media all and (max-width: 768px) {
    .header {
      display: block;
      left: 24px;
    }
  }
  
  .logo {
    font-size: 27px;
    letter-spacing: -1px;
  }
  
  .nav-btn,
  .nav-btn__svg {
    width: 56px;
    height: 30px;
  }
  
  .nav-btn {
    display: block;
    margin: -2px 0 0 56px;
  }
  @media all and (max-width: 768px) {
    .nav-btn {
      margin: 18px 0 0 -6px;
    }
  }
  
  .nav-btn__svg {
    pointer-events: none;
  }
  
  /*  ========================================================================== 
      Intro
      ========================================================================== */
  .intro,
  .footer {
    height: 100vh;
  }
  
  .intro {
    position: relative;
    padding: 5vw;
    /* background: #C0D7D8; */
    overflow: hidden;
  }
  
  .intro__content {
    position: absolute;
    right: 8%;
    bottom: 15%;
    z-index: 3;
  }
  @media all and (max-width: 768px) {
    .intro__content {
      right: auto;
    }
  }
  
  .intro__title {
    font-size: 25vw;
    overflow: hidden;
    letter-spacing: -2.3vw;
    padding-right: 2.3vw;
  }
  @media all and (max-width: 768px) {
    .intro__title {
      margin-bottom: 5vh;
    }
  }
  
  .intro__img {
    position: absolute;
    width: 35%;
    max-width: 390px;
    height: auto;
  }
  @media all and (max-width: 768px) {
    .intro__img {
      width: 75vw;
    }
  }
  
  .intro__img--1 {
    z-index: 2;
    left: 10%;
    bottom: 35%;
  }
  @media all and (max-width: 768px) {
    .intro__img--1 {
      left: 50%;
      bottom: 50vh;
    }
  }
  
  .intro__img--2 {
    z-index: 1;
    left: 25%;
    bottom: 40%;
  }
  @media all and (max-width: 768px) {
    .intro__img--2 {
      left: 70%;
      bottom: 60vh;
    }
  }
  
  .intro__txt {
    max-width: 35vw;
    margin-left: 25vw;
  }
  @media all and (max-width: 768px) {
    .intro__txt {
      max-width: 80vw;
      margin-left: 5vw;
    }
  }
  
  /*  ========================================================================== 
      Slides
      ========================================================================== */
  .slide {
    display: flex;
    align-items: stretch;
    height: 100vh;
    overflow: hidden;
  }
  .slide:nth-of-type(even) {
    background: #C4CDC4;
  }
  @media all and (max-width: 768px) {
    .slide {
      display: block;
      position: relative;
    }
  }
  
  /*  Columns
      ========================================================================== */
  .col {
    flex-basis: 50%;
  }
  @media all and (max-width: 768px) {
    .col {
      display: block;
      width: 100%;
      height: 100vh;
    }
  }
  
  .col--1 {
    position: relative;
    z-index: 1;
  }
  @media all and (max-width: 768px) {
    .col--1 {
      position: relative;
      z-index: 1;
    }
  }
  
  .col--2 {
    position: relative;
    overflow: hidden;
  }
  @media all and (max-width: 768px) {
    .col--2 {
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
    }
  }
  
  /*  ========================================================================== 
      Column Content
      ========================================================================== */
  .col__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    height: 100%;
    padding: 6vw 6vw 10vw;
  }
  @media all and (max-width: 768px) {
    .col__content {
      width: 80%;
    }
  }
  
  .col__content--1 {
    background: #D8C0C0;
  }
  @media all and (max-width: 768px) {
    .col__content--1 {
      background: rgba(216, 192, 192, 0.9);
    }
  }
  
  .col__content--2 {
    background: #CDD5E0;
  }
  @media all and (max-width: 768px) {
    .col__content--2 {
      background: rgba(205, 213, 224, 0.9);
    }
  }
  
  .col__content--3 {
    background: #F3D3B0;
  }
  @media all and (max-width: 768px) {
    .col__content--3 {
      background: rgba(243, 211, 176, 0.9);
    }
  }
  
  .col__content--4 {
    background: #F8E9E6;
  }
  @media all and (max-width: 768px) {
    .col__content--4 {
      background: rgba(248, 233, 230, 0.9);
    }
  }
  
  .col__content--5 {
    background: #D1E2EC;
  }
  @media all and (max-width: 768px) {
    .col__content--5 {
      background: rgba(209, 226, 236, 0.9);
    }
  }
  
  .col__content--6 {
    background: #D7CEC5;
  }
  @media all and (max-width: 768px) {
    .col__content--6 {
      background: rgba(215, 206, 197, 0.9);
    }
  }
  
  /*  Column Content
      ========================================================================== */
  .col__content-title {
    margin: 0 0 2vw;
    font-size: 11vw;
    letter-spacing: -0.8vw;
  }
  @media all and (max-width: 768px) {
    .col__content-title {
      margin: 0 0 6vw;
      font-size: 18vw;
    }
  }
  
  .col__content-wrap {
    display: flex;
    justify-content: flex-end;
  }
  @media all and (max-width: 768px) {
    .col__content-wrap {
      flex-direction: column;
    }
  }
  
  .col__content-txt {
    max-width: 22vw;
    order: 2;
    margin-left: 32px;
  }
  @media all and (max-width: 768px) {
    .col__content-txt {
      order: 1;
      max-width: 40vw;
      margin: 0 0 10vw 10vw;
    }
  }
  
  .slide-link {
    position: relative;
    order: 1;
    display: flex;
    justify-content: flex-end;
    width: 75px;
    height: 53px;
  }
  .slide-link > * {
    pointer-events: none;
  }
  @media all and (max-width: 768px) {
    .slide-link {
      order: 2;
      align-self: flex-end;
    }
  }
  
  .slide-link__circ {
    width: 53px;
    height: 53px;
    border-radius: 50%;
    border: 1px solid var(--dark);
  }
  
  .slide-link__line {
    position: absolute;
    top: 25px;
    left: 0;
    width: 64px;
    height: 3px;
    background: var(--dark);
  }
  
  .line {
    overflow: hidden;
  }
  .line:nth-of-type(even) {
    margin-top: -1vw;
  }
  
  .line__inner {
    display: block;
  }
  
  .slide__scroll-link {
    position: absolute;
    right: -113px;
    bottom: 3.5vw;
    display: block;
    width: 140px;
    height: 140px;
    background: var(--dark);
    overflow: hidden;
  }
  @media all and (max-width: 768px) {
    .slide__scroll-link {
      display: none;
    }
  }
  
  .slide__scroll-line {
    position: absolute;
    left: 26px;
    bottom: 0;
    width: 1px;
    height: 100%;
  }
  
  .slide--0 .slide__scroll-line {
    background: #C0D7D8;
  }
  
  .slide--1 .slide__scroll-line {
    background: #D8C0C0;
  }
  
  .slide--2 .slide__scroll-line {
    background: #CDD5E0;
  }
  
  .slide--3 .slide__scroll-line {
    background: #F3D3B0;
  }
  
  .slide--4 .slide__scroll-line {
    background: #F8E9E6;
  }
  
  .slide--5 .slide__scroll-line {
    background: #D1E2EC;
  }
  
  .slide--6 .slide__scroll-line {
    background: #D7CEC5;
  }
  
  /*  ========================================================================== 
      Column Image
      ========================================================================== */
  .col__image-wrap {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 160vh;
  }
  
  .img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  /*  ========================================================================== 
      Footer
      ========================================================================== */
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #cecece;
  }
  
  .footer__link {
    font-size: 5vw;
    color: var(--dark);
    text-decoration: none;
    font-family: "Cinzel", serif;
  }
  
  .footer__link-top {
    position: absolute;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background: var(--dark);
    font-size: 18px;
    color: white;
    text-decoration: none;
    font-family: "Cinzel", serif;
  }
  
  .footer__link-top-line {
    position: absolute;
    top: -50px;
    left: 50%;
    width: 1px;
    height: 50px;
    background: var(--dark);
  }
  
  .footer__copyright {
    position: absolute;
    left: 50%;
    bottom: 24px;
    transform: translateX(-50%);
  }
/* gsap code end */
/* top banner 3d ani */


.loading *,
.loading *:before,
.loading *:after {
  animation-play-state: play !important;
}

.test {
  opacity: 40%;
  width: 100%;
}

.art-board {
  opacity: 1;
  width: 1000px;
  height: 668px;
  min-width: 1000px;
  position: relative;
  animation: fade_in 0.9s 0.8s forwards cubic-bezier(0.17, 0.77, 0.38, 0.87);

}

.asset {
  position: absolute;
  transform-style: preserve-3d;
  will-change: transform;
  display: block;
}

.asset img {
  transform: scale(0.64);
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.asset1 {
  top: 190px;
  left: -83px;
  animation: floatDown 3.6s ease-in-out infinite;
}

.asset2 {
  top: 370px;
  left: 77px;
  animation: floatUp 3.6s 0.1s ease-in-out infinite;
}

.asset3 {
  top: 142px;
  left: 117px;
  animation: floatLeft 3.6s 0.2s ease-in-out infinite;
}

.asset4 {
  top: 338px;
  left: 60px;
  animation: floatUp 3.6s 0.3s ease-in-out infinite;
}

.asset5 {
  top: 397px;
  left: 409px;
  animation: floatLeft 3.6s 0.1s ease-in-out infinite;
}

.asset6 {
  top: -13px;
  left: 397px;
  animation: floatUp 3.6s 0.2s ease-in-out infinite;
}

.asset7 {
  top: -15px;
  left: 291px;
  animation: floatDown 3.6s 0.3s ease-in-out infinite;
}

.asset8 {
  top: 112px;
  left: 18px;
  animation: floatUp 3.6s 0.1s ease-in-out infinite;
}

.asset9 {
  top: 57px;
  left: 569px;
  animation: floatRight 3.6s 0.2s ease-in-out infinite;
}

.asset10 {
  top: 467px;
  left: 525px;
  animation: floatRight 3.6s 0.3s ease-in-out infinite;
}

.asset11 {
  top: 397px;
  left: 597px;
  animation: floatLeft 3.6s 0.1s ease-in-out infinite;
}

.asset12 {
  top: 398px;
  left: 554px;
  animation: floatRight 3.6s 0.2s ease-in-out infinite;
}

.asset13 {
  top: 356px;
  left:-77px;
  animation: floatLeft 3.6s 0.3s ease-in-out infinite;
}

.asset14 {
  top: 256px;
  left: 948px;
  animation: floatUp 3.6s 0.1s ease-in-out infinite;
}

.asset15 {
  top: 145px;
  left:827px;
  animation: floatDown 3.6s 0.2s ease-in-out infinite;
}

.asset16 {
  top: 191px;
  left: 820px;
  animation: floatDown 3.6s 0.3s ease-in-out infinite;
}

.asset17 {
  top: 163px;
  left: 793px;
  animation: floatUp 3.6s 0.1s ease-in-out infinite;
}

.asset18 {
  top: 167px;
  left: 732px;
  animation: floatLeft 3.6s 0.2s ease-in-out infinite;
}

.container {
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.title {
  position: absolute;
  opacity: 90%;
  line-height: 87px;
  font-size: 100px;
  /* font-family: "neue-haas-grotesk-display", sans-serif; */
  font-weight: 900;
  font-style: normal;
  letter-spacing: -2px;
  top: 191px;
  left: 237px;
  opacity: 0;
  animation: showMain 0.5s 0.6s forwards cubic-bezier(0.17, 0.77, 0.38, 0.87);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
}
.fontsize30
{
  font-size: 55px;
  top: 158px;
}

.titlepara {
  font-size: 19px;
  position: absolute;
  /* color: rgb(206 212 200); */
  padding: 25p;
  opacity: 90%;
  text-transform: none;
  line-height: 0.82;
  font-family: "neue-haas-grotesk-display", sans-serif;
  /* font-weight: 600; */
  font-style: normal;
  /* letter-spacing: -2px; */
  top: 375px;
  left: 247px;
  /* opacity: 0; */
  animation: showMain 0.5s 0.6s forwards cubic-bezier(0.17, 0.77, 0.38, 0.87);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
}

.titlepara> span{

  line-height: 20px;

}
@keyframes floatUp {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, -5%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes floatDown {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 5%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes floatRight {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(-5%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes floatLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(5%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fade_in {
  100% {
    opacity: 1;
  }
}
@keyframes show {
  0% {
    opacity: 0;
    transform: translate(0, -20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes showMain {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
/* top 3d end */
@media only screen and (max-width:767px) {

.asset {

  display: none;
}

.title {
  position: absolute;
  opacity: 90%;
  line-height: 40px;
  font-size: 41px;
  /* font-family: "neue-haas-grotesk-display", sans-serif; */
  font-weight: 900;
  font-style: normal;
  letter-spacing: -2px;
  top: 191px;
  left: 237px;
  opacity: 0;
  animation: showMain 0.5s 0.6s forwards cubic-bezier(0.17, 0.77, 0.38, 0.87);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
}


.titlepara {
  font-size: 13px;
  position: absolute;
  /* color: rgb(206 212 200); */
  padding: 25p;
  opacity: 90%;
  text-transform: none;
  line-height: 0.82;
  font-family: "neue-haas-grotesk-display", sans-serif;
  /* font-weight: 600; */
  font-style: normal;
  /* letter-spacing: -2px; */
  top: 375px;
  left: 229px;
  /* opacity: 0; */
  animation: showMain 0.5s 0.6s forwards cubic-bezier(0.17, 0.77, 0.38, 0.87);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  width: 100%;
}

.mobheader {
  width: 100%;
  height: 250px;
  position: relative;
  top: 12px;
  right: 222px;
}



}