  
    .container-al { 
        min-height: 700px; 
        margin-top: 6%; 
        background-image: url("./../../images/ai-ml.jpg"); 
        background-repeat: no-repeat; 
        background-position: center center; 
        background-size: cover; 
        display: flex; 
        flex-direction: row; 
        align-items: center;
        background-attachment: fixed;
      } 
    

        
    .container-al > .container-d1s { 
        padding-top: 8%; 
        padding-bottom: 8%; 
        width: 100%;
      } 