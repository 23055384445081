  
    .container-al-big { 
        min-height: 700px; 
        margin-top: 6%; 
        background-image: url("./../../images/bigdata-center-bg.jpg"); 
        background-repeat: no-repeat; 
        background-position: center center; 
        background-size: cover; 
        display: flex; 
        flex-direction: row; 
        align-items: center;
        background-attachment: fixed;
      } 
    

        
    .container-al-big > .container-d1s { 
        padding-top: 8%; 
        padding-bottom: 8%; 
        width: 100%;
      } 
 
  :root { 
      --swatch--black: #122022; 
      --light--primary: var(--swatch--black); 
      --light--secondary: var(--swatch--grey-3); 
      --light--inverse: var(--swatch--black); 
      --light--quaternary: var(--swatch--white); 
      --white: white; 
      --primary: #d14424; 
      --light--background: var(--swatch--grey-1); 
      --light--tertiary: var(--swatch--grey-2); 
      --swatch--grey-2: #d9dada; 
      --swatch--grey-3: #676767; 
      --swatch--grey-1: #efefef; 
      --swatch--white: #f7f7f7; 
      --dark--primary: var(--swatch--white); 
      --dark--secondary: var(--swatch--grey-1); 
      --dark--tertiary: var(--swatch--grey-2); 
      --dark--quaternary: var(--swatch--grey-3); 
      --dark--background: var(--swatch--black); 
      --dark--inverse: var(--swatch--white);
  } 
  
  :selection { 
      color: var(--swatch--white); 
      background-color: var(--swatch--black);
  } 
  
  .container-rze { 
      width: 90%; 
      max-width: 90rem; 
      margin-left: auto; 
      margin-right: auto; 
      display: block;
  } 
  
  .u-position-21c { 
      position: relative;
  } 
  
  .row-jsz { 
      flex-wrap: wrap; 
      align-content: stretch; 
      margin-bottom: -2.5rem; 
      margin-left: -1.25rem; 
      margin-right: -1.25rem; 
      display: flex;
  } 
  
  .u-z-index-vag { 
      z-index: 1; 
      position: relative;
  } 
  
  .u-mb-17o { 
      /* margin-bottom: 5rem; */
  } 
  
  .row-8oo { 
      z-index: 0; 
      flex-wrap: wrap; 
      align-content: stretch; 
      justify-content: center; 
      align-items: center; 
      margin-bottom: -2.5rem; 
      margin-left: -1.25rem; 
      margin-right: -1.25rem; 
      display: flex; 
      position: absolute; 
      top: 0%; 
      bottom: 0%; 
      left: 0%; 
      right: 0%;
  } 
  
  .col-wjg { 
      flex: 1; 
      margin-bottom: 2.5rem; 
      padding-left: 1.25rem; 
      padding-right: 1.25rem;
  } 
  
  .col-wjg.col-phw { 
      max-width: 8.33333%; 
      flex-basis: 8.33%;
  } 
  
  .col-wjg.col-e7o {
    max-width: 49.6667%;
    flex-basis: 41.67%;
}
  
  .col-wjg.col-z42 { 
      max-width: 25%; 
      flex-basis: 25%;
  } 
  
  .col-wjg.col-b63 { 
      max-width: 33.3333%; 
      flex-basis: 33.33%;
  } 
  
  .col-wjg.col-s8h { 
      max-width: 53%; 
      flex-basis: 50%;
  } 
  
  .container-rze  h2 { 
      margin-bottom: 10px; 
      font-weight: bold;
  } 
  
  .container-rze  h2 { 
      margin-top: 20px; 
      font-size: 32px; 
      line-height: 36px;
  } 
  
  .container-rze h2 { 
      letter-spacing: -.03em; 
      text-transform: uppercase; 
      margin-top: 0; 
      margin-bottom: 0; 
      font-size: 3.25rem; 
      font-weight: 900; 
      line-height: .9;
    
  } 
  
  .container-rze h2 { 
      text-wrap: balance;
  } 
  
  .container-rze h3 { 
      margin-bottom: 10px; 
      font-weight: bold;
  } 
  
  .container-rze h3 { 
      margin-top: 20px; 
      font-size: 24px; 
      line-height: 30px;
  } 
  
  .container-rze h3 { 
      letter-spacing: -.03em; 
      text-transform: uppercase; 
      margin-top: 0; 
      margin-bottom: 0; 
      font-size: 2.25rem; 
      font-weight: 500; 
      line-height: .9;
  } 
  
  .container-rze h3 { 
      text-wrap: balance;
  } 
  
  .des-5xp { 
      letter-spacing: -.02em; 
      text-transform: uppercase; 
      margin-top: 0; 
      margin-bottom: 0; 
      font-size: .875rem; 
      font-weight: 400; 
      line-height: 1.2;
  } 
  
  .text-ggq { 
      text-align: center;
  } 
  
  .des-5xp.text-ggq { 
      padding-left: 0;
  } 
  
  .u-img-a9d { 
      justify-content: center; 
      align-items: center; 
      display: flex; 
      position: relative; 
      overflow: hidden;
  } 
  
  p { 
      margin-top: 0; 
      margin-bottom: 10px;
  } 
  
  p { 
      margin-top: 0; 
      margin-bottom: 0;
  } 
  
  .ygqvp { 
      letter-spacing: -.03em; 
      text-transform: uppercase; 
      margin-top: 0; 
      margin-bottom: 0; 
      font-size: 3.25rem; 
      font-weight: 500; 
      line-height: .9;
      background: radial-gradient(circle, #80ffc1 0%, #ff6161 100%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
  } 
  
  .text-vn6 { 
      text-align: right;
  } 
  
  .ygqvp { 
      text-wrap: balance;
  } 
  
  img { 
      border: 0;
  } 
  
  img { 
      max-width: 100%; 
      vertical-align: middle; 
      display: inline-block;
  } 
  
  .u-img-hcf { 
      width: 100%; 
      height: 100%; 
      object-fit: cover;
  } 
  
  
  /* These were inline style tags. Uses id+class to override almost everything */
  #style-GAVo4.style-GAVo4 {  
     will-change: transform;  
      transform: translate3d(0px, -8.554%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);  
      transform-style: preserve-3d;  
  }  
  #style-CgO1k.style-CgO1k {  
     will-change: transform;  
      transform: translate3d(0px, -8.554%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);  
      transform-style: preserve-3d;  
  }  
  #style-RWfkB.style-RWfkB {  
     will-change: transform;  
      transform: translate3d(0px, -8.554%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);  
      transform-style: preserve-3d;  
  }  
  


  /* mobile css start */

  @media only screen and (max-width: 767px) {
  .col-wjg.col-z42 {
    max-width: 71%;
    flex-basis: 19%;
}


.col-wjg.col-e7o {
    max-width: 99.6667%;
    flex-basis: 41.67%;
}

}