.container-server { 
    min-height: 700px; 
    margin-top: 6%; 

    background-repeat: no-repeat; 
    background-position: center center; 
    background-size: cover; 
    display: flex; 
    flex-direction: row; 
    align-items: center;
    background-attachment: fixed;
    background-image:
    linear-gradient(45deg,
        rgba(179, 84, 84, 0.75),
        rgba(99, 166, 233, 0.75)), 
          url(
'./../../images/managed-service-bg.jpg');
  } 


    
.container-server > .container-d1s { 
    padding-top: 8%; 
    padding-bottom: 8%; 
    width: 100%;
  } 


  .text-vn6.ygqvp {
    text-align-last: right;

    letter-spacing: -.03em;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 3.25rem;
    /* font-weight: 900; */
    line-height: .9;
    background: radial-gradient(circle, #060606 0%, #444141 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}



/* mobile css */

@media only screen and (max-width: 767px) {
.text-vn6.ygqvp {
  text-align-last: right;
  letter-spacing: -.03em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.55rem;
  /* font-weight: 900; */
  line-height: .9;
  background: radial-gradient(circle, #060606 0%, #444141 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
}