.actionIcon {
    color: gray;
    margin-right: 1rem;
    cursor: pointer;
}

.logOutBtn {
    float: right;
    margin-right: 2rem;
}
.topLogobar {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.profileList {
    display: block;
    right: 1.8rem;
    font-size: 1.5rem;
}

.dashbordtab li .nav-item
{
    display: block;
    width: 45%;
}

.tabs-left {
    border-bottom: none;

  }

  a.nav-link.fw-bold {
    width: 50%;
}

  a.nav-link.fw-bold.active {
    border: 1px solid rgb(237, 237, 237);
    border-radius: 12px;
    background-color: #0d6efd;
    color: white !important;
}
  

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    isolation: isolate;
    border: 1px solid black;
    border-radius: 15px;
    cursor: pointer;
}
  .tabs-left>li {
    float: none;
   margin:0px;
    
  }
  
  .tabs-left>li.active,
  .tabs-left>li.active:hover,
  .tabs-left>li.active:focus {
    border-bottom-color: #ddd;
    border-right-color: transparent;
    background:#f90;
    border:none;
    border-radius:0px;
    margin:0px;
  }
  .nav-tabs>li:hover {
      /* margin-right: 2px; */
      line-height: 1.42857143;
      border: 1px solid transparent;
      /* border-radius: 4px 4px 0 0; */
  }
  .tabs-left>li.active::after{content: "";
      position: absolute;
      top: 10px;
      right: -10px;
      border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    
    border-left: 10px solid #f90;
      display: block;
      width: 0;}

      ul.nav.nav-tabs.tabs-left.sideways.flex-column {
        border-bottom: none !IMPORTANT;
    }