button.tw-flex.tw-items-center.tw-w-full.tw-py-2.tw-text-left.tw-md\:text-center {
  background-color: transparent !important;
}

button.tw-flex.tw-items-center.tw-w-full.tw-text-left {
  background-color: transparent;
}




* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
/*   
.navbar {
  background-color:none;
  color: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  font-size: 24px;
}

.menu-icon {
  display: none;
  font-size: 24px;
  color: #fff;
  background: none;
  border: none;
}

.nav-menu {
  list-style: none;
  display: flex;
}

.nav-item {
  position: relative;
}

.nav-links {
  color: #fff;
  padding: 10px 15px;
  text-decoration: none;
  display: block;
}

.nav-links:hover {
  background-color: #555;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #444;
  min-width: 160px;
  top: 100%;
  left: 0;
  list-style: none;
  z-index: 1;
}

.dropdown-item:hover .sub-dropdown-menu {
  display: block;
}

.sub-dropdown-menu {
  display: none;
  position: absolute;
  background-color: #555;
  left: 100%;
  top: 0;
  min-width: 160px;
}

.dropdown-links {
  color: #fff;
  padding: 10px;
  text-decoration: none;
  display: block;
}

.dropdown-links:hover {
  background-color: #666;
}
 */
/* Mobile Responsive */
@media (max-width: 768px) {
  .nav-menu {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .nav-menu.active {
    display: flex;
  }

  .menu-icon {
    display: block;
  }

  .dropdown-menu, .sub-dropdown-menu {
    position: static;
    background-color: #444;
  }
}


.dock {

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;
}

.masonry-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 16px;
  max-width: 1000px;
}

.grid-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.grid-item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.grid-item:hover img {
  transform: scale(1.05);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 8px;
  opacity:1;
  transition: opacity 0.3s ease;
}

.grid-item:hover .overlay {
  opacity: 1;
}

/* Responsive Text Scaling */
@media (max-width: 600px) {
.masonry-grid {
  grid-template-columns: 1fr;
}
.overlay {
  font-size: 0.9rem;
}
}