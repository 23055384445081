.fiveg-sec1
{
    background: linear-gradient(82deg,#acccfd,#d2bce9,#f9cbd5);
    background-size: 180% 180%;
    animation: fiveg 24s ease infinite;
    background-position: 50% 50%;
    width: 100%;
    min-height: 100vh;
}
.fivg-sec-2
{
	width: 100vw;

	/* background: linear-gradient(334deg, #6b97f7, #7525e2, #f7137e);
	background-size: 180% 180%;
	animation: gradient-animation 6s ease infinite; */
  background-color: #fff;
}

.feature-list {
  display: flex;

  /* OPTIONAL: Spacing between items */
  margin: 0.5rem 0;
}

/* Reverse the order of image and content */
.feature-list--reverse {
  flex-direction: row-reverse;
}

.feature-list__image {
  width: 25%;
}

.feature-list__desc {
  /* Take the remaining width */
  flex: 1;
}

@keyframes fiveg {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .displacement {
    filter: url(#displacementFilter);
  }

  .top-0 {
    top: 0;
}

.absolute {
  position: absolute;
}

.right-0 {
  right: 0;
}


.topleft{

top: 64px;
left: 128px;
}

.bottomleft {
  bottom: -967px;
  right: 97px;
}

.relative {
  position: relative;
}

.bigleft {
  bottom: 211px;
  left: -1px;
}


/* raingbow section */
.light-theme #start h1, .light-theme #start h2, .light-theme #start h3 {
  color: #06225d;
  font-size: 60px;
  line-height: 1.2em;
  font-weight: 800;
  text-align: center;
}

.light-theme #start .products {
  background-color: #fafbff;
}

a{
  text-decoration: none;
}
#start .products__background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 220px 0;
  background-image: url("https://codemagic.io/media/landing/homepage/gradient-bg-dark.svg");
}
#start .products {
  margin-top: 0;
  background-color: #13161d;
}
#start .products__content {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: center;
  margin-top: 66px;
}

.light-theme #start h1, .light-theme #start h2, .light-theme #start h3 {
  color: #06225d;
}

.light-theme #start .products {
  background-color: #fafbff;
}


.light-theme #start .products__background {
  background-image: url("https://codemagic.io/media/landing/homepage/gradient-bg.svg");
}

.products .product {
  display: flex;
  width: 199px;
  height: 99px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: 2px solid #1a1f28;
  background: linear-gradient(#1a1f28, #1a1f28) padding-box,#1a1f28 border-box;
  color: #fff;
  border-radius: 10px;
  font-weight: 600;
  background-origin: border-box;
  box-sizing: border-box;
  transition: all 200ms linear;
  box-shadow: 0px 4px 30px 0px rgba(6,34,93,.15);
}
#start .products__background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 220px 0;
  /* background-image: url("https://codemagic.io/media/landing/homepage/gradient-bg-dark.svg"); */
}

.light-theme #start .products .product:hover {
  border: 2px solid rgba(0,0,0,0);
  background: linear-gradient(#ffffff, #ffffff) padding-box,linear-gradient(90deg, #00ceff 0%, #fe19ff 100%) border-box;
}

.products .product {
  border: 2px solid #fff;
  background: linear-gradient(#ffffff, #ffffff) padding-box,#fff border-box;
  color: #06225d;
}
/* 
.hero-head .ctrl-break {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to bottom, #292c32, #3e3b42, #544b50, #685c5c, #7a6f6a);
}

.hero-head h2 {
  font-size: 65px;
  font-weight: 600;
 
  text-align: left;
} */

.hero-head h5 {
  text-transform: lowercase;
}
.fiveg h5 {
  font-size: 1rem;
  line-height: 25px;
  font-weight: normal;
  color: #222;
}

.gradient-bg {
	width: 100vw;
	height: 100vh;
	background: linear-gradient(334deg, #6b97f7, #7525e2, #f7137e);
	background-size: 180% 180%;
	animation: gradient-animation 6s ease infinite;
}

@keyframes gradient-animation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* rainbow section end */

/* out line css */

.outline-box {
  position: relative;
  top: 0px;
}

.section-outline {
  width: 100%;
  height: 100vh;
  min-height:500px;
}

.title-outline h2{
  margin-bottom: 0px !important;
}

.section-head-outline {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  /* flex-direction: column;
  justify-content: center; */
  align-items: center;
  flex-direction: row;
}

.section-head__title {
  position: relative;
}

.section-head-outline .title--hello,
.section-head-outline .title-outline,
.section-head-outline .title--outline {
  display: block;
  font-size: 144px;
  letter-spacing: -1px;
  line-height: 0.85;
  text-transform: uppercase;
  color: #3a3a3a;
  width: max-content;
  font-weight: 900;
}
.section-head .title--hello {
  color: #111;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #525252;
}

.section-head .title-outline {
  position: relative;
  z-index: 2;
}

.section-head-outline .title--outline {
  position: absolute;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #525252;
  bottom: 1px;
}

.flex-basis-666
{
    flex-basis: 666px  !important;
    /* z-index: 10000; */
    z-index: 1;
}
.section-head__images {
  position: absolute;
  width: 100%;
  height: 100%;
}

.section-head__images img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 150%;
  object-fit: cover;
}

.image-box {
  position: relative;
 width: 100%;
  height: 100%;
  /* aspect-ratio: 1/1; */
  /* left: 20%; */
  top: 1%;
  overflow: hidden;
}



/* outline css */

/* glosy glass css */

.card-blur {
  width: 490px;
  height: 354px;
  transition: all 0.2s;
  position: relative;
  cursor: pointer;
}

.card-inner {
  width: inherit;
  height: inherit;
  background: rgb(60, 179, 113,0.2);
  box-shadow: 0 0 10px rgba(0,0,0,0.25);
  backdrop-filter: blur(12px);
  border-radius: 8px;
}

.card-blur:hover {
  transform: scale(1.04) rotate(1deg);
}

.circle-dot {
  width: 100px;
  height: 100px;
  background: radial-gradient(#b0e633, #53ef7d);
  border-radius: 50%;
  position: absolute;
  animation: move-up6 2s ease-in infinite alternate-reverse;
}

.circle-dot:nth-child(1) {
  top: -25px;
  left: -25px;
}

.circle-dot:nth-child(2) {
  bottom: -25px;
  right: -25px;
  animation-name: move-down1;
}

@keyframes move-up6 {
  to {
    transform: translateY(-10px);
  }
}

@keyframes move-down1 {
  to {
    transform: translateY(10px);
  }
}
/* glosy glass css end */

/* mobile vesion css */

@media only screen and (max-width:767px) {

  .outline-box {
    position: relative;
    top: 35px;
  }


  .section-head-outline .title--hello, .section-head-outline .title-outline, .section-head-outline .title--outline {

    font-size: 45px;

}

.section-head-outline {

  align-items: start;
}


.section-head-outline {

  flex-direction: column;
}



section {
  /* padding-top: 50px; */
  padding-bottom: 10px;
}


.section-head-outline {

  height: 71vh;

}

}