.cocreate-sec1 {
    width: 100%;
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    /* background-image: url('./../images/ai-testing.jpg'); */
    background-image: url('./../../images/grnd33.jpg');
    background-attachment: fixed;
    height: 100vh;
}


#cocreate-sec2 {
    background-color: #fff;
    height: 100vh;
    background-image: url('./../../images/cocreate-abstract-bg.jpg');
    background-size: cover;
    background-position: 60% -1%;
    background-attachment: fixed;

}

.co-padding
{
    padding: 100px 0px;
}

#cocreate-sec3 {
    background-color: #fff;
    height: 100vh;
    background-image: url('./../../images/cocreate-abstract-bg-left.png');
    background-size: cover;
    background-position: 60% -1%;
    background-attachment: fixed;
   

}

#the-div1:after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.3);
 
}

#cocreate-txt {
    color: #000;
   
    float: left;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

}

#why-txt {
  color: #000;
 
  float: left;
display: flex;

flex-direction: column;

}
.rightvision img
{
    height: 639px;
}

/* 
.card-feature {
    background: #fff;
    border-radius: 2px;
    display: inline-block;
    height: 300px;
    margin: 1rem;
    position: relative;
    width: 300px;
    padding: 25px;
  } */
  
  .card-feature-1,
  .card-feature-2,
  .card-feature-3,
  .card-feature-4,
  .card-feature-5,
  .card-feature-6,
  .card-feature-7
  {
    width:300px;
    height: 300px;
    padding: 25px;
    background: #fff;
    border-radius: 2px;
    position: relative;

    margin: 12px;
  }
  .card-feature-1 {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
 
  }
  
  .card--feature-1:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  
  .card-feature-2 {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
  
  .card-feature-3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
  
  .card-feature-4 {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  
  .card-feature-5 {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
  


  .card-feature  {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: normal;

  }
  

  .cocreate-sec100 {
    width: 100%;
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    /* background-image: url('./../images/ai-testing.jpg'); */
    background-image: url('./../../images/grnd33.jpg');
    background-attachment: fixed;
    
}


.pb120
{
  padding-bottom: 100px;
}

.paddingall
{
  padding: 75px 0px;
}

ul.newlist li
{
  display:inline-block;
  margin-bottom: 10px;
  font-size: 22px;
  
}



ul.newlist li:before {
  content: '';
  width: 37px /* width of the image */;
  height: 46px/* height of the image */;
  top: 16px;
  display: inline-block;
  position: relative;
  background:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="48" viewBox="0 0 24 24"><path fill="%23E494FF" d="M22 11c-4.96 0-9-4.04-9-9 0-1.32-2-1.32-2 0 0 4.96-4.04 9-9 9-1.32 0-1.32 2 0 2 4.96 0 9 4.04 9 9 0 1.32 2 1.32 2 0 0-4.96 4.04-9 9-9 1.32 0 1.32-2 0-2Z"></path></svg>')
}