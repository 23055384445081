
.whatwedo
{
  width: 100%;

  background-color: #fff;
  background-size: cover;
  background-position: 50% 50%;
  z-index: -999;
  background-image:url(./../images/potential-bg.png);
  background-attachment: fixed;
  /* dimensions are important, ensure it is tall enough to scroll */ 
  min-height: 100vh;
}

.who
{
    text-align: center;

    margin: 0 auto;
    width: 100%;
}

.who h2{
    font-size: 7rem;
    font-weight: 600;
    text-shadow: 0px 4px 8px rgba(6,4,4,0.38);
}

.gallery {
    display: flex;
  padding-bottom: 10px;
}

.left {
    width: 50%;
    /* margin-left: auto; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.left .details {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40vw;
    /* margin-left: auto; */
    color: #000;
    font-size: 3rem;
    font-weight: 900;
}

.rightblock {
	width:50%;
	height:100vh;
	display:flex;
	flex-direction:column;
	/* justify-content:center; */
}

.photos {
  width: 40vw;
  height: 40vw;

  position: relative;
  right: -89px;
}
.photo {
	position:absolute;
	width:100%;
	height:100%;
}
.photo img{

  width:100%;
	height:100%;
}
  
.photo img:nth-child(2){
  filter: grayscale(2);
}

.photo img:nth-child(2) {
transform: scale(0.5);
filter: sepia();
}

.spacer
{
  width: 100%;
  height: 100vh;
  background-color: #252525;
  text-align: center;
  color: white;
  margin: 0 auto;
  vertical-align: middle;
}
  /* Acc end */


  /* :horizontal css start */

  .intro{


    height: 100vh;
  }
  
  .intro {
    height: 100vh;
    /* background: #6e4ce1; */
    background-size: contain;
    color: #0c0612;
    background-image: url('./../images/count.jpg');
    opacity: 1;
    /* z-index: -2; */
    position: bottom center;
}
  span.num {
    font-size:6rem;
    font-weight: 900;
    text-shadow: 0px 4px 8px rgba(6,4,4,0.38);
    color: #8624e9;
  }
   
  span.text {
    font-size: 4rem;
    font-weight: 900;
    text-shadow: 0px 4px 8px rgba(6,4,4,0.38);
    color: #8624e9;
  }

  .purpletext
  {
    color: #8624e9 !important;
  }
  
  .intro-item {
    text-align: center;
    font-size: 1.5rem;
    padding: 15px;
    font-weight: 500;
    text-shadow: 0 0 BLACK;
    display: inline-block;
    min-width: 325px;
    margin-top: 58px;
}
.center-align
{
    text-align: center;
}

.numberlrg {
  font-size: 4rem;
  font-weight: 500;
  text-shadow: 0px 4px 8px rgba(6,4,4,0.38);
}
  footer {
    background: #04091E;
  }
  
  h1 {
    font-size: 4rem;
  }
  
  h2 {
    font-size: 2rem;
  }
/*   
  a {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    display: block;
    margin: 20px 0 0 0;
    transition: all 0.3s ease-in-out;
    font-size: 1.2rem;
    color: #F6F2E8;
    text-decoration: none;
  }
   */
  a:hover {
    color: #6e4ce1;
  }
  
  /* horizontal scroll */
  
  #horizontal-scoll {
    padding: 200px 0;
  }
  
  .horizontal-scoll-wrapper {
    overflow: hidden;
    height: 100%;
  }
  
  .horizontal {
    display: flex;
    height: 100%;
  }
  
  .horizontal > div {
    display: flex;
    flex-shrink: 0;
    padding: 0 5px;
  }
  
  .horizontal > div:first-child {
    padding: 0 5px 0 15px;
  }
  
  .horizontal > div:last-child {
    padding: 0 15px 0 5px;
  }
  
  .horizontal .card {
    align-items: stretch;
    width: 540px;
    padding: 50px 40px;
    background: #fff;
    border-radius: 38px;
    color: #222222;
  }
  


/* mobile responsive */


@media only screen and (max-width:767px) {
  .who h2 {
    font-size: 4rem;
    font-weight: 600;
    text-shadow: 0px 4px 8px rgba(6,4,4,0.38);
  }
  


  .intro-item {

    margin-top: 0;
}
}
