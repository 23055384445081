/* Navbar.css */


  .navbar-brand {
    color: #666;
    font-size: 1.5rem;
  }
  
  .nav-links {
    display: flex;
    align-items: center;
    list-style: none;
  }
  
  .nav-links a {
    color: #666;
    padding: 14px 20px;
    text-decoration: none;
  }
  
  .nav-links a:hover {
    background-color: #fff;
    color: aqua;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;

    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }
  
  .show {
    display: block;
  }
  
  .menu-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .menu-toggle .bar {
    height: 3px;
    width: 25px;
    background-color: #fff;
    margin: 4px 0;
  }
  
  @media screen and (max-width: 768px) {
    .nav-links {
      display: none;
      width: 100%;
      flex-direction: column;
    }
  
    .nav-links.open {
      display: flex;
    }
  
    .menu-toggle {
      display: flex;
    }
  }
  