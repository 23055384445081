.container-indus { 

 

    /* background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)), url("./../../images/indus-bg3.png "); */
  /* background: linear-gradient(rgba(255,255,255,.1), rgba(255,255,255,.1)), url("./../../images/indus-bg-ai.jpg"); */
    background-repeat: no-repeat; 
    background-position: center center; 
    background-size: cover; 
    display: flex; 
    flex-direction: row; 
    align-items: center;
    background-attachment: fixed;
    padding-bottom: 88px !important;
  } 

  .indus-sub
  {
    background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)), url("./../../images/indus-sub-bg.jpg");
    background-repeat: no-repeat; 
    background-position: center center; 
    background-size: cover; 
    display: flex; 
    flex-direction: row; 
    align-items: center;
    background-attachment: fixed;
  }
  .greylayer{
    background: #efefef;
    padding: 50px;
}
  #industries {
    background-color: #fff;
    height: 100vh;
    background-image: url('./../../images/export-bg.png');
    background-size: cover;
    background-position: 60% 70%;
    background-attachment: fixed;
}

.overlayer {
    background-color: rgba(000, 000, 000, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
    .headingtop
    {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
    }
.container-indus > .container-d1s { 
    padding-top: 8%; 
    padding-bottom: 8%; 
    width: 100%;
  } 

.whiteheading
{

        font-size: 3.8em;
        color: white;

   
}
  .sectionZZ { 
    display: block;
} 

 .sectionZ { 
    background-color: #eeeeee;
} 

 .sectionZ { 
    position: relative; 
    word-wrap: break-word;
} 

 .sectionZ { 
    background-position: 50% 50%; 
    background-repeat: no-repeat; 
    background-size: cover;
} 

.cid-zw8 { 
    padding-top: 90px; 
    padding-bottom: 90px; 
    background-color: #ffffff;
} 

*,:after,:before { 
    box-sizing: border-box;
} 

*,:after,:before { 
    box-sizing: inherit;
} 

.pt50
{
    padding-top: 175px;
}
.container-o74 { 
    width: 100%; 
    padding-right: 15px; 
    padding-left: 15px; 
    margin-right: auto; 
    margin-left: auto;
} 

@media (min-width: 576px){ 
  .container-o74 { 
    max-width: 540px;
  } 
}     

@media (min-width: 768px){ 
  .container-o74 { 
    max-width: 720px;
  } 
}     

@media (min-width: 992px){ 
  .container-o74 { 
    max-width: 960px;
  } 
}     

@media (min-width: 1200px){ 
  .container-o74 { 
    max-width: 1140px;
  } 
}     

.mb-cr8 { 
    margin-bottom: 1.5rem!important;
} 

.container-o74 { 
    position: relative; 
    word-wrap: break-word;
} 

.container-o9a { 
    display: -ms-flexbox; 
    display: -webkit-flex; 
    display: flex; 
    -webkit-flex-direction: row; 
    -ms-flex-direction: row; 
    flex-direction: row; 
    -webkit-flex-wrap: wrap; 
    -ms-flex-wrap: wrap; 
    flex-wrap: wrap; 
    -webkit-justify-content: center; 
    -ms-flex-pack: center; 
    justify-content: center; 
    -webkit-align-content: center; 
    -ms-flex-line-pack: center; 
    align-content: center; 
    -webkit-align-items: start; 
    -ms-flex-align: start; 
    align-items: start;
} 

@media (min-width: 992px){ 
  .container-o9a { 
    -webkit-flex-wrap: nowrap; 
    -ms-flex-wrap: nowrap; 
    flex-wrap: nowrap;
  } 
}     

.row-aqd { 
    display: -webkit-box; 
    display: -ms-flexbox; 
    display: flex; 
    -ms-flex-wrap: wrap; 
    flex-wrap: wrap; 
    margin-right: -15px; 
    margin-left: -15px;
} 

.content-7pi { 
    -webkit-box-pack: center!important; 
    -ms-flex-pack: center!important; 
    justify-content: center!important;
} 

.align-8tg { 
    text-align: center;
} 

.col-s8g { 
    position: relative; 
    width: 100%; 
    min-height: 1px; 
    padding-right: 15px; 
    padding-left: 15px;
} 

.col-s8g { 
    -webkit-box-flex: 0; 
    -ms-flex: 0 0 100%; 
    flex: 0 0 100%; 
    max-width: 100%;
} 

.col-s8g,.col-3l1,.col-8sz { 
    position: relative; 
    width: 100%; 
    min-height: 1px; 
    padding-right: 15px; 
    padding-left: 15px;
} 

@media (min-width: 768px){ 
  .col-8sz { 
    -webkit-box-flex: 0; 
    -ms-flex: 0 0 50%; 
    flex: 0 0 50%; 
    max-width: 50%;
  } 
}     

@media (min-width: 992px){ 
  .col-3l1 { 
    -webkit-box-flex: 0; 
    -ms-flex: 0 0 25%; 
    flex: 0 0 25%; 
    max-width: 25%;
  } 
}     

.p-1lq { 
    padding: 1rem!important;
} 

.sectionZ h2 { 
    margin-top: 0; 
    margin-bottom: .5rem;
} 

.sectionZ h2 { 
    margin-bottom: .5rem; 
    font-family: inherit; 
    font-weight: 500; 
    line-height: 1.2; 
    color: inherit;
} 

.sectionZ h2 { 
    font-size: 2rem;
} 

.display-w2h { 
    font-size: 5.5rem; 
    font-weight: 300; 
    line-height: 1.2;
} 

.pb-1mo { 
    padding-bottom: .5rem!important;
} 

h2,.display-w2h { 
    font: inherit;
} 

h2,.display-w2h { 
    line-height: 1; 
    word-break: break-word; 
    word-wrap: break-word;
} 

. .sectionZ-5l1 { 
    font-style: normal; 
    line-height: 1.2;
} 

.display-w2h { 
    font-family: 'Montserrat', sans-serif; 
    font-size: 1.8rem;
} 

.sectionZ h3 { 
    margin-top: 0; 
    margin-bottom: .5rem;
} 

.sectionZ h3 { 
    margin-bottom: .5rem; 
    font-family: inherit; 
    font-weight: 500; 
    line-height: 1.2; 
    color: inherit;
} 

.sectionZ h3 { 
    font-size: 1.75rem;
} 

.sectionZ h3 { 
    font: inherit;
} 

.sectionZ h3 { 
    line-height: 1; 
    word-break: break-word; 
    word-wrap: break-word;
} 

.mbr-5va { 
    font-weight: 300;
} 

.subtitle-i6m { 
    line-height: 1.3;
} 

.display-xyh { 
    font-family: 'Montserrat', sans-serif; 
    font-size: 1rem;
} 

.cid-zw8 .subtitle-i6m  { 
    color: #8d97ad;
} 

.cid-zw8 .wrap-o8a  { 
    padding-bottom: 1rem;
} 

.sectionZ img { 
    vertical-align: middle; 
    border-style: none;

    object-fit:contain;
    height:50px;
    width:75%;
    aspect-ratio: 3/2;
    mix-blend-mode: color-burn;
} 

.cid-zw8 .wrap-o8a img  { 
    max-width: 100%;
} 

.display-999 { 
    font-family: 'Montserrat', sans-serif; 
    font-size: 1.2rem;
} 

.cid-zw8 .client-ehb  { 
    color: #8d97ad;
} 
.wrap-img {
  padding-bottom: 1rem;
}


/* new 7 section css start */
/* Global Styles */
/* Global Styles */

  
  .container-7 {
    /* max-width: 1200px;
    margin: 40px auto; */
    padding: 20px;
  }
  
  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;
    transform: translateZ(0); /* Add this to enable GPU acceleration */
  }
  
  /* Parallax Effect */
  .parallax {
    perspective: 1px;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .parallax .bg-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateZ(-1px) scale(1.1); /* Add this to create the parallax effect */
  }
  
  /* Section Styles */
  .section-1 {
    background-color: #f7f7f7;
    padding: 60px 0;
    padding: 60px 0;
    background-image: url('./../../images/life-sci.jpg');
    background-repeat: no-repeat;

    background-attachment: fixed;

  }
  
  .section-2 {

    padding: 60px 0;
    background-image: url('./../../images/indus-pralex1.jpg');
    background-repeat: no-repeat;
    background-position : calc(100% - 10px) 0;
    background-attachment: fixed;

  }
  
  .section-3 {
    
    padding: 60px 0;
    background-image: url('./../../images/paralex-2.jpg');
    background-repeat: no-repeat;

    background-attachment: fixed;
  }
  
  .section-4 {

    padding: 60px 0;
    background-image: url('./../../images/baking-indu.jpg');
    background-repeat: no-repeat;
    background-position : calc(100% - 10px) 0;
    background-attachment: fixed;
  }
  
  .section-5 {

    padding: 60px 0;
    background-image: url('./../../images/consumer-para.jpg');
    background-repeat: no-repeat;

    background-attachment: fixed;
  }
  
  .section-6 {

    padding: 60px 0;
    background-image: url('./../../images/insur-para.jpg');
    background-repeat: no-repeat;
    background-position : calc(100% - 10px) 0;
    background-attachment: fixed;
  }
  
  .section-7 {
 
    padding: 60px 0;
    background-image: url('./../../images/soft-para.jpg');
    background-repeat: no-repeat;
    background-position : calc(100% - 10px) 0;
    background-attachment: fixed;
  }
  
  /* Responsive Styles */

  @media only screen and (max-width:767px) {

  .mobpl
  {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  
  }


  @media (max-width: 768px) {
    .container {
      padding: 10px;
    }
    .bg-image {
      opacity: 0.3;
    }
  }
/* new 7 section css end */