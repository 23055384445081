.car {
    height: 100vh;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjAwIDIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgPHBhdGggb3BhY2l0eT0iLjUiIGZpbGw9InRvbWF0byIgZD0iTTU2LjIsLTM2LjlDNjYuOCwtMTQuMiw2NSwxMS4yLDUzLjYsMzEuOUM0Mi4yLDUyLjYsMjEuMSw2OC43LC0zLjgsNzAuOEMtMjguNiw3MywtNTcuMiw2MS4zLC02NC44LDQyLjhDLTcyLjQsMjQuMywtNTksLTEsLTQ0LjcsLTI1LjlDLTMwLjQsLTUwLjgsLTE1LjIsLTc1LjMsMy44LC03Ny41QzIyLjgsLTc5LjcsNDUuNywtNTkuNiw1Ni4yLC0zNi45WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAwIDEwMCkiIC8+Cjwvc3ZnPgo='),
      url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjAwIDIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgPHBhdGggb3BhY2l0eT0iLjUiIGZpbGw9IlNsYXRlQmx1ZSIgZD0iTTU2LjIsLTM2LjlDNjYuOCwtMTQuMiw2NSwxMS4yLDUzLjYsMzEuOUM0Mi4yLDUyLjYsMjEuMSw2OC43LC0zLjgsNzAuOEMtMjguNiw3MywtNTcuMiw2MS4zLC02NC44LDQyLjhDLTcyLjQsMjQuMywtNTksLTEsLTQ0LjcsLTI1LjlDLTMwLjQsLTUwLjgsLTE1LjIsLTc1LjMsMy44LC03Ny41QzIyLjgsLTc5LjcsNDUuNywtNTkuNiw1Ni4yLC0zNi45WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAwIDEwMCkiIC8+Cjwvc3ZnPgo='),
        url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjAwIDIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgPHBhdGggb3BhY2l0eT0iLjUiIGZpbGw9IlNwcmluZ0dyZWVuIiBkPSJNNTYuMiwtMzYuOUM2Ni44LC0xNC4yLDY1LDExLjIsNTMuNiwzMS45QzQyLjIsNTIuNiwyMS4xLDY4LjcsLTMuOCw3MC44Qy0yOC42LDczLC01Ny4yLDYxLjMsLTY0LjgsNDIuOEMtNzIuNCwyNC4zLC01OSwtMSwtNDQuNywtMjUuOUMtMzAuNCwtNTAuOCwtMTUuMiwtNzUuMywzLjgsLTc3LjVDMjIuOCwtNzkuNyw0NS43LC01OS42LDU2LjIsLTM2LjlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMDAgMTAwKSIgLz4KPC9zdmc+Cg==');
    background-position: top .5em right, left 1em center, bottom -50vh center;
    background-size: 40%, 50%, 80%;
    background-attachment: local, scroll, fixed;
    background-repeat: no-repeat;
  }

  /* grid css */
  
  :root { 
      --swatch--black: #122022; 
      --light--primary: var(--swatch--black); 
      --light--secondary: var(--swatch--grey-3); 
      --light--inverse: var(--swatch--black); 
      --light--quaternary: var(--swatch--white); 
      --white: white; 
      --primary: #d14424; 
      --light--background: var(--swatch--grey-1); 
      --light--tertiary: var(--swatch--grey-2); 
      --swatch--grey-2: #d9dada; 
      --swatch--grey-3: #676767; 
      --swatch--grey-1: #efefef; 
      --swatch--white: #f7f7f7; 
      --dark--primary: var(--swatch--white); 
      --dark--secondary: var(--swatch--grey-1); 
      --dark--tertiary: var(--swatch--grey-2); 
      --dark--quaternary: var(--swatch--grey-3); 
      --dark--background: var(--swatch--black); 
      --dark--inverse: var(--swatch--white);
  } 
  
  section { 
      display: block;
  } 
  

  .sidefont {
    letter-spacing: -.03em;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 3.25rem;
    font-weight: 500;
    line-height: .9;
}
  .section-byh { 
      padding-top: 6rem; 
      padding-bottom: 6rem; 
      position: relative;
  } 
  
  .u-bg-vpx { 
      background-color: var(--white);
  } 
  
  :selection { 
      color: var(--swatch--white); 
      background-color: var(--swatch--black);
  } 
  
  .container-opd { 
      width: 90%; 
      max-width: 90rem; 
      margin-left: auto; 
      margin-right: auto; 
      display: block;
  } 
  
  .u-position-yg5 { 
      position: relative;
  } 
  
  .row-ctw { 
      flex-wrap: wrap; 
      align-content: stretch; 
      margin-bottom: -2.5rem; 
      margin-left: -1.25rem; 
      margin-right: -1.25rem; 
      display: flex;
  } 
  
  .u-z-index-fyv { 
      z-index: 1; 
      position: relative;
  } 
  
  .u-mb-4eh { 
      margin-bottom: 5rem;
  } 
  
  .row-kac { 
      z-index: 0; 
      flex-wrap: wrap; 
      align-content: stretch; 
      justify-content: center; 
      align-items: top; 
      margin-bottom: -2.5rem; 
      margin-left: -1.25rem; 
      margin-right: -1.25rem; 
      display: flex; 
      position: absolute; 
      top: 0%; 
      bottom: 0%; 
      left: 0%; 
      right: 0%;
  } 
  
  .col-zh4 { 
      flex: 1; 
      margin-bottom: 2.5rem; 
      padding-left: 1.25rem; 
      padding-right: 1.25rem;
  } 
  
  .col-zh4.col-48i { 
      max-width: 8.33333%; 
      flex-basis: 8.33%;
  } 
  
  .col-zh4.col-vkd { 
      max-width: 41.6667%; 
      flex-basis: 41.67%;
  } 
  
  .col-zh4.col-zzz { 
      max-width: 25%; 
      flex-basis: 25%;
  } 
  
  .col-zh4.col-lyc { 
      max-width: 33.3333%; 
      flex-basis: 33.33%;
  } 
  
  .col-zh4.col-wel { 
      max-width: 50%; 
      flex-basis: 50%;
  } 
  
  .des-fpq { 
      letter-spacing: -.02em; 
      text-transform: uppercase; 
      margin-top: 0; 
      margin-bottom: 0; 
      font-size: .875rem; 
      font-weight: 400; 
      line-height: 1.2;
  } 
  
  .text-axz { 
      text-align: center;
  } 
  
  .des-fpq.text-axz { 
      padding-left: 0;
  } 
  
  .u-img-559 { 
      justify-content: center; 
      align-items: center; 
      display: flex; 
      position: relative; 
      overflow: hidden;
  } 
  
  p { 
      margin-top: 0; 
      margin-bottom: 10px;
  } 
  
  p { 
      margin-top: 0; 
      margin-bottom: 0;
  } 
  
  .tcilv { 
      letter-spacing: -.03em; 
      text-transform: uppercase; 
      margin-top: 0; 
      margin-bottom: 0; 
      font-size: 3.25rem; 
      font-weight: 500; 
      line-height: .9;
  } 
  
  .text-zog { 
      text-align: right;
  } 
  
  .tcilv { 
      text-wrap: balance;
  } 
  
  img { 
      border: 0;
  } 
  
  img { 
      max-width: 100%; 
      vertical-align: middle; 
      display: inline-block;
  } 
  
  .u-img-6k2 { 
      width: 100%; 
      height: 100%; 
      object-fit: cover;
  } 
  
  
  /* These were inline style tags. Uses id+class to override almost everything */
  #style-dYtmF.style-dYtmF {  
     will-change: transform;  
      transform: translate3d(0px, -11.0156%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);  
      transform-style: preserve-3d;  
  }  
  #style-evCsm.style-evCsm {  
     will-change: transform;  
      transform: translate3d(0px, -11.0156%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);  
      transform-style: preserve-3d;  
  }  
  #style-4qQnC.style-4qQnC {  
     will-change: transform;  
      transform: translate3d(0px, -11.0156%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);  
      transform-style: preserve-3d;  
  }  
  

  /* center full banner code */
  @import url('https://fonts.googleapis.com/css?family=Oxygen:300,400,700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800,300i,400i,500i,600i,700i,800i&display=swap'); 


  @media all{ 
    * { 
      box-sizing: inherit;
    } 
  
     .site-main > * { 
      margin-bottom: 20px;
    } 
  }     
  
   .site-main > * { 
      margin-bottom: 0;
  } 
  
  @media all{ 
     .separate-containers .site-main > :last-child { 
      margin-bottom: 0;
    } 
  
 
  }     
  
  body { 
      --wp--preset--color--black: var(--black); 
      --wp--preset--color--contrast: var(--contrast); 
      --wp--preset--color--contrast-2: var(--contrast-2); 
      --wp--preset--color--contrast-3: var(--contrast-3); 
      --wp--preset--color--base: var(--base); 
      --wp--preset--color--base-2: var(--base-2); 
      --wp--preset--color--base-3: var(--base-3); 
      --wp--preset--color--accent: var(--accent); 
      --wp--preset--color--accent-hover: var(--accent-hover); 
      --wp--preset--color--accent-2: var(--accent-2); 
      --wp--preset--color--viking: var(--viking); 
      --wp--preset--color--dark-cerulean: var(--dark-cerulean); 
      --wp--preset--color--light-grey: var(--light-grey); 
      --wp--preset--color--yellow: var(--yellow); 
      --wp--preset--color--dark-blue: var(--dark-blue); 
      --wp--preset--color--column-border: var(--column-border); 
      --wp--preset--color--resources-background: var(--resources-background); 
      --wp--preset--color--teal: var(--teal); 
      --wp--preset--color--turquoise-blue: var(--turquoise-blue); 
      --wp--preset--color--carousel-blue: var(--carousel-blue); 
  } 
 
  @media all{ 
  
    :root { 
      --black: #000; 
      --contrast: #141414; 
      --contrast-2: #1b1b42; 
      --contrast-3: #9898b1; 
      --base: #e1e6ed; 
      --base-2: #f2f5fa; 
      --base-3: #fff; 
      --accent: #482d70; 
      --accent-hover: #5e3f8c; 
      --accent-2: #192b80; 
      --viking: #42a7c6; 
      --dark-cerulean: #035174; 
      --light-grey: #f7f7f7; 
      --yellow: #ecd925; 
      --dark-blue: #002c45; 
      --column-border: #d1d1d1; 
      --teal: #00827e; 
      --turquoise-blue: #59cbe8; 
    } 
  }     
  
  :root { 
      --contrast: #141414; 
      --contrast-2: #1b1b42; 
      --contrast-3: #9898b1; 
      --base: #e1e6ed; 
      --base-2: #f2f5fa; 
      --base-3: #ffffff; 
      --accent: #482d70; 
      --accent-hover: #5e3f8c; 
      --accent-2: #192b80; 
      --viking: #42a7c6; 
      --dark-cerulean: #035174; 
      --light-grey: #f7f7f7; 
      --yellow: #ECD925; 
      --dark-blue: #002C45; 
      --column-border: #D1D1D1; 
      --resources-background: #E4E4E4; 
      --black: #000000; 
      --teal: #00827E; 
      --turquoise-blue: #59CBE8; 
      --carousel-blue: #0b3e61;
  } 
  
  :root { 
      --gp-search-modal-bg-color: var(--base-3); 
      --gp-search-modal-text-color: var(--contrast); 
  } 
  
  @media all{ 
    :root { 
      --animate-duration: 1s; 
    } 
  
    .container-32q { 
      min-height: 700px; 
      margin-top: 6%; 
      background-image: url(https://www.netcraft.com/wp-content/uploads/2023/04/why.jpg); 
      background-repeat: no-repeat; 
      background-position: center center; 
      background-size: cover; 
      display: flex; 
      flex-direction: row; 
      align-items: center;
      background-attachment: fixed;
    } 
  
    .ali-fxl  { 
      margin-left: calc(-100vw / 2 + 100% / 2); 
      margin-right: calc(-100vw / 2 + 100% / 2); 
      max-width: 100vw; 
      width: auto;
    } 
  
    *,:after,:before { 
      box-sizing: inherit;
    } 
  
    .page-header, .sidebar .widget, .site-main > * { 
      margin-bottom: 20px;
    } 
  }     
  
  .page-header, .sidebar .widget, .site-main > *, .widget-area .main-navigation { 
      margin-bottom: 0;
  } 
  
  @media all{ 
    .one-container .site-main > :last-child, .separate-containers .site-main > :last-child { 
      margin-bottom: 0;
    } 
  
    .container-32q > .container-d1s { 
      padding-top: 8%; 
      padding-bottom: 8%; 
      width: 100%;
    } 
  
    .container-2e2 > .container-d1s  { 
      padding-right: 150px; 
      padding-left: 80px; 
      max-width: 1200px; 
      margin-left: auto; 
      margin-right: auto;
    } 
  
    .wrapper-ti2 { 
      display: flex; 
      flex-wrap: wrap;
    } 
  
    .column-o8o { 
      box-sizing: border-box;
    } 
  
    .wrapper-ti2 > .column-fhi  { 
      width: 66.66%;
    } 
  
    .container-coh { 
      margin-right: 0; 
      margin-left: 0;
    } 
  
    .ani-lcm { 
      animation-duration: 1s; 
      animation-duration: var(--animate-duration); 
      animation-fill-mode: both;
    } 
  
    .fa-6jh { 
      animation-name: fadeInLeft;
    } 
  
    .wrapper-ti2 > .column-fhi > .container-rom  { 
      display: flex; 
      flex-direction: column; 
      height: 100%;
    } 
}
   
  @media all{ 
    h2.gb-headline-6nz { 
      color: #fff;
    } 
  
  
  }     
  
  :root .col-oe2  { 
      color: var(--base-3);
  } 
  
  @media all{ 
    .wrapper-k7b { 
      display: flex; 
      flex-wrap: wrap; 
      align-items: flex-start; 
      justify-content: flex-start; 
      clear: both;
    } 
  
    a { 
      transition: color 1s ease-in-out,background-color 1s ease-in-out;
    } 
  
    a { 
      text-decoration: none;
    } 
  }     
  
  a { 
      color: #42a7c6;
  } 
  
  @media all{ 
    .button-lb9 { 
      text-decoration: none;
    } 
  
    .wrapper-k7b .button-4xt  { 
      display: inline-flex; 
      align-items: center; 
      justify-content: center; 
      font-family: 'Oxygen',sans-serif; 
      font-size: 15px; 
      font-weight: 600; 
      text-transform: uppercase; 
      text-align: center; 
      padding: 15px 40px; 
      border-radius: 200px; 
      border: 2px solid #ecd925; 
      background-color: rgba(0,0,0,0); 
      color: #fff; 
      transition: all .4s ease;
    } 
  }     
  
  a:active,a:hover { 
      color: #00827e;
  } 
  
  @media all{ 
    .wrapper-k7b .button-4xt:hover, .wrapper-k7b .button-4xt:active { 
      border-color: #fff; 
      background-color: #fff; 
      color: var(--contrast);
    } 
  
    .wrapper-k7b .button-4xt:hover { 
      opacity: 1;
    } 
  }     
  
  
  @keyframes fadeInLeft { 
    0% {  
        opacity: 0; 
        transform: translate3d(-100%,0,0); 
        opacity: 0; 
        transform: translate3d(-100%, 0px, 0px); 
    }  
    100% {  
        opacity: 1; 
        transform: translateZ(0); 
        opacity: 1; 
        transform: translateZ(0px); 
    }  
  
  } 
  
  /* full banner code end */


  /* mobile version code */

  @media only screen and (max-width:767px) {
    .sidefont {
      line-height: 1.2;
      font-size: 1.2rem;
 
  }

  .u-mb-4eh {
    margin-bottom: 0rem;
}

.col-zh4.col-vkd {
  max-width: 100%;
  flex-basis: 100%;
}

.container-2e2 > .container-d1s {
  padding-right: 2px;
  padding-left: 8px;

}

.wrapper-ti2 > .column-fhi {
  width: 97.66%;
}

.tcilv {

  margin-top: 5px;

  font-size: 2.25rem;

}

.col-zh4.col-zzz {
  max-width: 64%;
  flex-basis: 25%;
}

}