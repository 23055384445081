

/* social icon css */

.icon-bar {
  position: fixed;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 6px;
  transition: all 0.3s ease;
  color: white;
  width: 50px;

}

.icon-bar a:hover {
  transform: scale(1.2);
}


.twitter {

  color: white;
}


.linkedin {

  color: white;
}

.youtube {

  color: white;
}

/* social icon css end */

.border-top-contact
{
  border-top: 5px solid grey;
  display: block;
}
#loc > section {
    height: 100vh;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    background-color: white;
  }
  .col-md-8.col-lg-8.spacetop {
    left:0px;
}
.spacetop {
  position: relative;
  top: 191px;
}
  
  #loc > section.header {
    background-color: #f5f5f5;
  }
  
  #loc > section.showcase {
    justify-content: space-around;
    align-items: unset;
    height: auto;
  }


  .bg-image-abs {
    background-image: url('./../../images/contact-abs.jpg'); /* Replace with your image URL */
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh; /* Step 2: Set height to 100 viewport height */
    background-attachment: fixed;
  }
  


  .contactbg {
    background-color: #fff;
    height:100vh;
    /* background-image: url('./../../images/contact-ser.png'); */
    background-image: url('./../../images/con-22.png');
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-position: 35% 0%;
    /* background-attachment: fixed; */
}
.contact-head-outline {
  width: 100%;
  height: 74vh;
  position: relative;
  display: flex;
  align-items: center;
}
.contact-heading {

  line-height:60px;
  font-size:50px;
  font-family: "Oxygen", sans-serif !important;
  /* font-family: "neue-haas-grotesk-display", sans-serif; */
  font-weight: 900;
/* color: white; */
  letter-spacing: -2px;

  animation: showMain 0.5s 0.6s forwards cubic-bezier(0.17, 0.77, 0.38, 0.87);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
}
.contact-text {
  /* color: white; */
  line-height:25px;
  font-size:22px;
  font-family: "Oxygen", sans-serif !important;
  /* font-family: "neue-haas-grotesk-display", sans-serif; */


  letter-spacing: 2px;

  animation: showMain 0.5s 0.6s forwards cubic-bezier(0.17, 0.77, 0.38, 0.87);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
}
.contact-text-para
{
    /* color: white; */
    line-height: 34px;
    font-size:20px;
    font-family: "Oxygen", sans-serif !important;
    /* font-family: "neue-haas-grotesk-display", sans-serif; */

  
    letter-spacing: 2px;
  
    animation: showMain 0.5s 0.6s forwards cubic-bezier(0.17, 0.77, 0.38, 0.87);
    -webkit-user-select: none;
    user-select: none;
    z-index: 1;
}

.spacetop p{
  /* color: white; */
}
  .version {
    position: absolute;
    height: 250px;
    width: 250px;
    left: calc(50vw - 125px);
    top: calc(50vh - 125px);
    background-color: transparent;
    color: lightgrey;
    display: flex;
    align-items: center;
    justify-content:center;
    font-size: 200px;
    z-index: 0;
  }
  
  .heading {
    color: grey;
    font-size: 30px;
    z-index: 1;
  }
  
  
  .text-wrap {
    position: relative;
    overflow: hidden;
    width: 450px;
    height: 100%;
  }
  
  .text-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .textL {
    position: relative;
  
    width: 100%;  
    height: 100vh;
    
    font-size: 25px;

    font-weight: 900;
    text-align: justify;
    
    background-color: white; 
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  /* .textL.blue {
    color: steelblue;
  }
  
  .textL.red {
    color: red;
  }
  
  .textL.green {
    color: green;
  }
  
  .textL.orange {
    color: orange;
  }
  
  .textL.purple {
    color: purple;
  }
  
  
  .textL.pink {
    color: pink;
  }
   */
  
  

  .heading a {
    font-size: unset;
  }
  
  a.greensock:hover {
    color: #88CE02;
  }
  
  a.easytiger:hover {
    color: #EB008A;
  }
  
  
  
  .heading span {
    display: block;
    margin-bottom: 15px;
  } 
  
  
  
  
  .image-wrap,
  .side-image-wrap {
    border-radius: 5px;
  }
  
  .image-wrap {
    position: relative;
    overflow: hidden;
    width: 450px;
    height: 80vh;
    
    margin-top: 10vh;
  }
  
  .image {
    position: absolute;
    
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
  
 
    background-position: center center;
    background-repeat: no-repeat;  
    background-size: cover; 
  
  

   
  }
  
  
  .rightboxcontact
  {

    display: flex;
    justify-content: center;
    text-align:justify;
    font-weight: 300;
    top: 250px;
  }
  .side-image-wrap {
    position: relative;
    overflow: hidden;
    width: 450px;
    height: 40vh;
    
    margin-top: 30vh;
    margin-left: -20vw;
    
    border: 10px solid white;
  }
  
  .side-image {
    position: absolute;
      
    top: 0%;
    right: 0%;
    bottom: 10%;
    left: 0%;
  
    width: 100%;

    
    /*
    background-position: center center;
    background-repeat: no-repeat;  
    background-size: cover; 
    */
    
    /*like@cuberto.com*/
    background-position: 0px 0px;
    background-size: auto;
    
    /*
    background-position: 50% 50%;
    background-size: auto;
    */
  }
  
  
  
  .side-image.blue,
  .image.blue {
    background-color: steelblue;
    background-image: url("./../../images/feature-library.avif");
  }
  
  /*  
  https://unsplash.com/@matthewwmcb
  */
  
  
  .side-image.red,
  .image.red {
    background-color: red;
    background-image: url("./../../images/data-analytics.jpg");
  }
  
  /*  
  https://unsplash.com/@tuannguyenminh
  */
  
  
  .side-image.green,
  .image.green {
    background-color: green;
    background-image: url("./../../images/spot.jpg");
  }
  
  /*  
  https://unsplash.com/@ghog
  */
  
  
  .side-image.orange,
  .image.orange {
    background-color: orange;
    background-image: url("./../../images/device-mngmnt.jpg");
  }
  
  /*  
  https://unsplash.com/@vinomamba24
  */
  
  
  .side-image.purple,
  .image.purple {
    background-color: purple;
    background-image: url("./../../images/integration.jpg");
  }

  .side-image.pink,
  .image.pink {
    background-color: purple;
    background-image: url("https://images.unsplash.com/photo-1558350315-8aa00e8e4590?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80");
  }
  .side-image.wer,
  .image.wer {
    background-color: purple;
    background-image: url("./../../images/integration.jpg");
  }

  
  /*  
  https://unsplash.com/@virussinside
  */
  
  
  .location-font
  {
    font-size: 5rem;
    font-weight: 600;
    color: black;
  }
  
  .location-para
  {
    font-size: 1rem;
    font-weight: 100;
    color: black;
    width: 40%;
    margin: 0 auto;
    margin-bottom: 25px;
  }
  
  .scrollbar-track {
     background-color: transparent !important; 
     width: 10px !important;
  }
  
  .scrollbar-thumb {
      background-color: green !important;
    margin-right: 2px !important;
  }
  
  .scrollbar-track:hover {
    cursor: pointer !important;
  }
  
  .scrollbar-thumb:hover {
    cursor: grab !important;
  }
  
  .scrollbar-thumb:active {
    cursor: grabbing !important;
  }


  .mb-400
  {
    margin-bottom: 250px;
  }

  .ourlocation {
    position: relative;
    bottom: 159px;
}
.ourlocation p{
  font-size: 17px;
  line-height: 30px;
}

.jumbotron {
    /* background-color: #ececec; */
    padding: 44px;
    border-radius: 32px;
}


/* page not found css */


.content-m12 { 
  margin: 80px auto; 
  text-align: center;
} 

.content-m12 h1  { 
  font-weight: 300; 
  font-size: 2em; 
  margin: 15px 0;
} 

.ins-hhg { 
  margin: 5em auto; 
  max-width: 24em; 
  filter: grayscale(90%); 
  opacity: .9;
} 


.not{

  color: #b7b7b7;
  margin: 0;
  padding: 0;
  font-size: 82%;
}



/* page not found css end */


/* Thankyou page */
#thankyoupage {
  color: #222;
  display: inline-block;
  float: left;
  width: 26em;
  margin: 18%;
  font-size: 1.15vw;
}



#thankyoubg {
  background-color: #cecec9;
  height: 100vh;
  background-image: url('./../../images/thankyoubg.png');
  background-size: cover;
  background-position: 60% 70%;
}

.mo-padding
{
 padding: 0px;
}
/* Thankyou page end css */




@media only screen and (max-width:767px) {
  .block-icon {
    display: flex;
    flex-direction: column;
}


.flex-items-location {
    min-width: 200px;
    
}

.freeflow {
  position: relative;
  bottom: 109px;
  left: 0px;
}


.sec2 h2, .sec3 h2, .sec4 h2, .sec5 h2, .sec6 h2, .sec7 h2, .sec8 h2, .sec9 h2, .sec10 h2, .sec11 h2, .sec12 h2, .sec13 h2, .sec14 h2, .sec15, .sec16 h2, .sec-annoucement h2 {
  font-size: 31px;
  font-weight: 600;
  white-space: pre-line;
}

.send-button {
  position: relative;

}

.input-field {
  width: 43%;

}

.mo-padding
{
 padding: 60px;
}

li.nav-item.locationtab {
  width: 99px;
  margin-right: 61px;
  margin-bottom: 0px;
}
}