.bg-light {
	background-color: transparent !important;
}

.hero22 {
	position: relative;
	background: url('./../../images/camp-bg1.jpg');
	min-height: 100vh;
	background-size: cover;
	background-position: center;
}

.hero22 h1 {
	font-size: 70px;
	font-weight: 800;
	margin: 0;
	padding: 0;
}
.hero22 p {
	padding: 0;
	margin: 1em 0 0 0;
	font-size: 20px;
	font-weight: 500;
	text-align: justify;
}

.btn1 {
	display: inline-block;
	margin: 1em 0 0 0;
	background-color: black;
	color: #ffffff;
	text-transform: uppercase;
	border: none;
	text-decoration: none;
	font-weight: 800;
	padding: 10px 20px;
	border-radius: 30px;
	border: 2px solid black;
}

.btn1:hover {
	background-color: white;
	color: black;
	border: 2px solid black;
	text-decoration: none;
}


/* contact form css */

  .contact-form-wrapper {
	padding: 100px 0;
	background: rgb(4,135,176);
	background: linear-gradient(149deg, rgba(4,135,176,0.468312324929972) 8%, rgba(254,251,248,1) 97%);
  }
  
  .contact-form {
	padding: 30px 40px;
	background-color: #fff;
	border-radius: 12px;
	max-width:600px;
  }
  
  .contact-form textarea {
	resize: none;
  }
  
  .contact-form .form-input,
  .form-text-area {
	background-color: #f0f4f5;
	height: 50px;
	padding-left: 16px;
  }
  
  .contact-form .form-text-area {
	background-color: #f0f4f5;
	height: auto;
	padding-left: 16px;
  }
  
  .contact-form .form-control::placeholder {
	color: #aeb4b9;
	font-weight: 500;
	opacity: 1;
  }
  
  .contact-form .form-control:-ms-input-placeholder {
	color: #aeb4b9;
	font-weight: 500;
  }
  
  .contact-form .form-control::-ms-input-placeholder {
	color: #aeb4b9;
	font-weight: 500;
  }
  
  .contact-form .form-control:focus {
	border-color: #f33fb0;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px #f33fb0;
  }
  
  .contact-form .title {
	text-align: center;
	font-size: 24px;
	font-weight: 500;
  }
  
  .contact-form .description {
	color: #aeb4b9;
	font-size: 14px;
	text-align: center;
  }
  
  .contact-form .submit-button-wrapper {
	text-align: center;
  }
  
  .contact-form .submit-button-wrapper input {
	border: none;
	border-radius: 4px;
	background-color: #f23292;
	color: white;
	text-transform: uppercase;
	padding: 10px 60px;
	font-weight: 500;
	letter-spacing: 2px;
  }
  
  .contact-form .submit-button-wrapper input:hover {
	background-color: #d30069;
  }
  


/* contact form css end*/