.whatwe
{
    width: 98%;
    height: 100vh;
    background-image: -webkit-linear-gradient(#fbfbfb,rgba(88, 169, 176, 0.333));
}
.what h2 {
    font-size: 65px;
    font-weight: 600;
    /* text-shadow: 0px 4px 8px rgba(6,4,4,0.38); */
    text-align: left;

    text-transform: capitalize;
}
.hero-head .ctrl-break {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    background-image: linear-gradient(to bottom, #292c32, #3e3b42, #544b50, #685c5c, #7a6f6a);
}

.whatwe {
    background-color: #fff;
    height: 100vh;
    background-image: url('./../../images/whatwedo.png');
    background-size: cover;
    /* background-position: 60% 70%; */
    background-repeat: no-repeat;
    background-position: bottom -77px right -246px;
}

#whatwe_text {
    color: #222;
    display: inline-block;
    float: left;
    width: 24em;
    margin: 1% 8px;
    font-size: 2.15vw;
}

.telecom{
  width: 100%;

  background-color: #fff;
  background-size: cover;
  background-position: 50% 50%;
  z-index: -999;
  background-image:url(./../../images/telecom.jpg);
  background-attachment: fixed;
  /* dimensions are important, ensure it is tall enough to scroll */ 
  min-height: 100vh;
}

.govt{
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    background-image:url(./../../images/govt-bg.png);
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;
  }


  .health{
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position:top;
    z-index: -999;
    background-image:url(./../../images/health.jpg);
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;
  }

  .manufacturing
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position:top;
    z-index: -999;
    background-image:url(./../../images/const.png);
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;
  }

  .retail {
    width: 100%;
    background-color: #fff;
    background-size: cover;
    /* background-position: 25% 25%; */
    background-position: bottom -234px right -234px;
    z-index: -999;
    background-image: url('./../../images/retail.jpg');
    background-attachment: fixed;
    min-height: 100vh;
}

.metro {
    width: 100%;
    background-color: #fff;
    background-size: cover;
    background-position: 25% 25%;
    background-repeat: no-repeat;

    z-index: -999;
    /* background-image: url('./../../images/metro-globe.jpg'); */
    /* background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("./../../images/metro-globe.jpg"); */
    /* background-attachment: fixed; */
    min-height: 100vh;

}

.endto
{

    width: 100%;
    background-color: #fff;
    background-size: contain;
    /* background-position: 25% 25%; */
    background-repeat: no-repeat;

    z-index: -999;
    background-image: url('./../../images/globe-dots.svg');
    /* background-image: url('./../../images/abst-111-sml.jpg'); */
    /* background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("./../../images/metro-globe.jpg"); */
    background-attachment: fixed;
min-height: 50vh;
}
.width24
{
  width: 60em;
}

.lightlist > li
{
    font-size:25px;
    font-weight:bold;
}
.headingfont
{
    font-size: 3rem;
    font-weight: 400;
}

ul .boldlist  li
{

    font-size: 19px;
    font-weight: 400;
    list-style: disc;
    margin-bottom: 25px;

}

.boldlist li{
 font-size: 19px;
    font-weight: 400;
    list-style: disc;   
}
.list {
    perspective: 600px;
    visibility: hidden;
    height: 100vh;
    width: 100%;
    z-index: 1;
}
.list li img{
    width: 20%;
    height: auto;
}
.list li {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -20%);
    font-weight: 700;
    font-size: clamp(24px, 6vw, 96px);
    color: #030101;
    background:transparent;
    border-radius: 16px;
    padding: 1vw;
    /* border: 1px solid black; */
    width: 800px;

    height: 40vh;
}

.scroll-font{
font-size: 65px;
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
font-weight: 900;
/* background-image: linear-gradient(
to right bottom,
#a7f0f7,
#8ae2f9,
#72d3fb,
#66c2fc,
#6ab0fa 
);*/

background-image: linear-gradient(360deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
}

.ctrl-break-blue {

    /* background-image: linear-gradient(to bottom, #292c32, #3e3b42, #544b50, #685c5c, #7a6f6a); */
}

.list p {
    font-size: 20px;
    background-color: rgb(247 247 247);
    padding: 29px;
    border-radius: 8px;
}

.metro {

    height: 100vh;
    background-image: url('./../../images/metro.png');
    background-size: contain;
    background-position:bottom -57px right -196px;
    background-repeat: no-repeat;
    background-color:rgba(213, 170, 170, 0.2);

}
.displacement {
    filter: url(#displacementFilter);
  }


  /* mobile responsive */



  @media only screen and (max-width:767px) {

    ul .boldlist  li {
   
        margin-bottom: 5px;
    }

    .listM h2.scroll-font {
        font-size: 33px;
    }
    .endto {

        padding: 25px;
    }
    .what h2 {
        font-size: 40px;

    }
    #whatwe_text {

        width: 36em;
      
        font-size: 3.99vw;
    }

    .headingfont {
        font-size: 2rem;
        font-weight: 400;
    }


    .telecom {

        background-position: 5% 50%;


    }

    .health {

        background-position: left;

    }


    .govt {
  
        background-position: 0;
  
    }

    .retail {

        background-position: bottom -234px right -765px;
    
    }

  }
  
