.container-vul { 
    min-height: 700px; 
    margin-top: 6%; 

    background-repeat: no-repeat; 
    background-position: center center; 
    background-size: cover; 
    display: flex; 
    flex-direction: row; 
    align-items: center;
    background-attachment: fixed;
    background-image:
    linear-gradient(45deg,
        rgba(255, 255, 255, 0.75),
        rgba(99, 166, 233, 0.75)), 
          url(
'./../../images/managed-service-bg.jpg');
  } 


    
.container-vul > .container-d1s { 
    padding-top: 8%; 
    padding-bottom: 8%; 
    width: 100%;
  } 