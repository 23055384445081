
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

.pt126
{
  padding-top: 125px;
}
  .min14
  {
    min-height:437px;
  }

  
  .min13
  {
    min-height:283px;
  }

  .border-none
  {
    border:none !important
  }

  .img33 img{
    width: 50% !important;
    align-items: center;
  }

  .img24 img{
    width: 11% !important;
    align-items: center;
  }

  .pb-10
  {
padding-bottom: 50px;
  }
  .telecom-sec1
  {
    position: relative;
    background-image: linear-gradient(360deg,deepskyblue,#d5feff,#ffffff);
    background-size: 180% 180%;

    /* background: linear-gradient(360deg,deepskyblue,#d5feff,#ffffff); */
    /* background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    width: 100%;
    min-height: 100vh;
    /* background-size: 180% 180%;
     
    padding-bottom: 100px; */

    /* width: 100%;
    height: 100%; */
    /* background: rgb(12, 16, 18); */
    position: relative;
    overflow: hidden;
  }

  .hero-section {
    width: 100%;
    min-height: 100vh;
    background-image: linear-gradient(360deg,deepskyblue,#d5feff,#ffffff);
    background-image: url('https://preview.uideck.com/items/appify/assets/img/hero/hero-bg.svg');
    background-position: left bottom;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
}
  .white-color
  {
    color: white;
  }

  .telecom-sec1::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;

    background: linear-gradient(360deg,deepskyblue,#d5feff,#ffffff);
    background-size: 180% 180%;
    animation: moveBackground 8s linear infinite; /* Adjust the animation duration and timing function */
  }
  
  @keyframes moveBackground {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(20%, 20%);
    }
  }

  h2.h2-xs {
    font-size: 4rem;
}
  @keyframes moveBackground {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(20%, 20%);
    }
  }
  
/*    
  .telecom-sec-2
  {
    width: 100%;
  

    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;

    background-attachment: fixed;

    height: 100vh;
    background: linear-gradient(189deg,#172023,#2d1f0f,#342702,#1e1821);

    animation: gradient-animation-4 32s ease infinite;
  } */
.telecom-sec-3
{
    width: 100%;
  

    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    background-image:url(./../../images/mulit-bg-3.jpg);
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    /* height: 100vh; */

}
.imgadjust img
{
width: 600px;
height: 450px;
}
  section.telecom-sec h2 {
    color: #fff;
}
section.telecom-sec p {
    color: #fff;
}

ul.simple-list li {
    color: #212529;
}

.hero-head .ctrl-break {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to bottom, #292c32, #3e3b42, #544b50, #685c5c, #7a6f6a);
}

.telecom-sec-4
{
  width: 100%;
  

    /* background-size: cover; */
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url(./../../images/mulit-bg-3.jpg); */
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    height: 100vh;
  background: linear-gradient(189deg,#172023,#2d1f0f,#342702,#1e1821);
  background-size: 240% 240%;
  animation: gradient-animation-4 32s ease infinite;
}


.pt100
{
  padding-top: 176px;
}
.pb-10{
  padding-bottom: 635px;
}

@keyframes gradient-animation-4 {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.telecom-sec-5
{
  background: linear-gradient(82deg,#acccfd,#d2bce9,#f9cbd5);
  background-size: 180% 180%;
  animation: gradient-animation-5 24s ease infinite;
  background-position: 50% 50%;
}


@keyframes gradient-animation-5 {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


/* tringle bg end */