ul.serlist
{
    padding-left: 0rem;
}

ul.serlist > li
{
    margin-bottom: 25px;
    border-left: 1px solid green;
    padding-left: 15px;
}
.container-fin {
    min-height: 700px;

    background-image: url('././../../images/finser-bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-attachment: fixed;
}
.container-fin-2 {
    min-height: 700px;

    background-image: url('././../../images/finser-bg-2.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-attachment: fixed;
}


.container-fin-3 {
    min-height: 700px;

    background-image: url('././../../images/finser-bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-attachment: fixed;
}


.wrapper-ti2 > .column-fin {
    width: 98.66%;
}

ul.finul
{
    padding-left: 0rem;
    color: white;
    margin-bottom: 25px;
    margin-top: 50px;
}

ul.finul li{
    color: white;
    margin-bottom: 25px;

}