
.pt1-2og { 
    padding-left: 0; 
    padding-right: 0; 
    padding-top: 152px;
} 

.har-bgg { 
    background-image: url(https://colate.io/static/media/hardengg_bg.602e338bd59d03d428a9.png); 
    background-position: 50% 0; 
    background-repeat: repeat; 
    background-size: cover; 
    height: 100vh; 
    padding: 100px 25px; 
    width: 100%;
} 

.har-bgg { 
    background-position: 50% 50%;
} 

.section-4ms { 
    background-position: 50%; 
    background-repeat: no-repeat; 
    background-size: cover;
} 


.engg-heading {
    display: flex;
    align-items: center;
    justify-content: center;
}


.section-jso { 
    background-position: 50%; 
    background-repeat: no-repeat; 
    background-size: cover;
} 

.align-qge { 
    align-items: center;
} 
.section-bg2 {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.section-padding40 {
    padding-bottom: 30px;
    padding-top: 100px;
}

.grad-text {
    -webkit-text-fill-color: #0000;
    background: #000;
    background: linear-gradient(90deg,#000 0,#23538a);
    -webkit-background-clip: text;
}

ul.Skew li {
    color: #000;
    font-size: 20px;
    font-weight: 800;
    padding: 5px;
}

.default-padding {
    padding-top: 120px;
}

.middle-div {
    margin: 0 auto;
    max-width: 1200px;
}

.masonry-grid {
    column-count: 4;
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-gap: 20px;
    -moz-column-gap: 20px;
    -webkit-column-gap: 20px;
    margin: 50px 0;
}
.review-item {
    background-color: #fff;
    box-shadow: 0 0 8px 0 silver;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: inline-block;
    margin: 10px 0;
    padding: 10px;
    width: 100%;
}

.text-black-40, .text-black-70 {
    color: #000;
}

.hardwarep {
    font-size: 23px;
    margin-bottom: 8px;
}

.hardware-main {
    background: linear-gradient(#00000080,#05050580),url('./../images/circuit-hard.png') 50% no-repeat;
    background-attachment: fixed;
    background-blend-mode: screen;
    background-color: #000000c7;
    background-position: 50% 0;
    background-repeat: repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    width: 100%;
}

h2.whitecolor {
    color: #fff;
}
.map_sec {
    padding: 50px 0;
}

.socil_item_inner {
    padding-bottom: 10px;
}

.wrap-collabsible {
    margin: 1.2rem 0;
}

input[type='checkbox'] {
    display: none;
  }

  .collapsible-content p {
    margin-bottom: 0;
}

.collapsible-content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height .25s ease-in-out;
  }
  
  .toggle:checked+.lbl-toggle+.collapsible-content {
    max-height: 350px;
  }
  
  .toggle:checked+.lbl-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
.wrap-collabsible {
    margin: 1.2rem 0;
  }
  

  .lbl-toggle {
    border-radius: 7px;
    color: #ddd;
    cursor: pointer;
    display: block;
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem;
    transition: all .25s ease-out;
}
  .lbl-toggle:hover {
    color: #FFF;
  }
  .toggle:checked+.lbl-toggle {
    background-color: #fff;
    color: #000;
}
  .lbl-toggle::before {
    content: ' ';
    display: inline-block;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-right: .7rem;
    transform: translateY(-2px);
    transition: transform .2s ease-out;
  }
  

  .collapsible-content p {
    margin-bottom: 0;
  }
  
  .collapsible-content .content-inner {
    background: #fafafa33;
    border-bottom: 1px solid #0069ff73;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    color: #fff;
    padding: .5rem 1rem;
}  

li.nav-item {
    display: inline-block!important;
    min-width: 100px;
}

.ibpm-nav .nav-link.active {
    color: var(--bs-nav-tabs-link-active-color);
    font-weight: 800;
    outline: none;
}

.nav-tabs .nav-link {
    background: 0 0;
    border: 1px solid #0000;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    margin-bottom: -1px;
}

section#minimal-statistics .card {
    min-height: 350px;
}

.card {
    --iconS: 6rem;
    --iconOffsetX: calc(var(--iconS) /);
    --iconOffsetY: calc(var(--iconS)/2.5);
    --shadowSurface: 0.5rem 0.5rem 0.5rem #00000040;
    --bgGradient: radial-gradient(circle at bottom right,#0000,#0000001a);
    --shadowInsetLight: inset 0.125rem 0.125rem 0.125rem #ffffff80;
    --shadowInsetDark: inset -0.067rem -0.067rem 0.067rem #00000080;
    background-color: #fff;
    background-image: radial-gradient(circle at calc(var(--iconOffsetX)*-1 + var(--iconS)/2) calc(var(--iconOffsetY)*-1 + var(--iconS)/2),#0006 calc(var(--iconS)/2),#0000 calc(var(--iconS)/1.6)),radial-gradient(circle at calc(var(--iconOffsetX)*-1 + var(--iconS)/2) calc(var(--iconOffsetY)*-1 + var(--iconS)/2),var(--accent-color) calc(var(--iconS)/1.4),#0000 calc(var(--iconS)/1.4 + 1px)),var(--bgGradient);
    border-radius: 1rem;
    color: #000;
    display: flex;
    flex-direction: column;
    margin-top: var(--iconOffsetY);
    margin-inline: var(--iconOffsetX);
    margin-right: 5px;
}.card {
    border-radius: 5px;
    border-top: 4px solid #003566;
    box-shadow: 0 4px 8px #0003;
    padding: 20px;
}

/* blue bg section */

.blur-bg {
    align-items: center;
    background-position: 50% 50%;
    background: linear-gradient(#00000080,#05050580),url('./../images/layer-circuit-1.jpg') 50% repeat;
    border-bottom: 1px solid #333;
    display: flex;
    justify-content: center;
}
.blur-section {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100%;
    padding: 2rem;
}

.blur-card-grid {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    width: 100%;
}

.card__bg {
    background-position: 50%;
    background-size: cover;
    border-radius: 1rem;
    height: 300px;
    min-width: 250px;
    overflow: hidden;
    transform: scale(1);
}

.blur-card, .card__bg {
    position: relative;
    transition: all .5s ease-out;
}

.blur-card {
    filter: drop-shadow(-2px 0 0 #333);
    list-style: none;
    transform: scale(1.02);
}

.card__content {
    left: 1rem;
    padding: 15px;
    position: absolute;
    top: 1rem;
}

h3.card__heading {
    color: #fff;
    font-size: 15px;
    font-weight: 100;
    text-align: left;
}

.card__category {
    color: #fff;
    font-size: 25px;
    font-weight: 600;
}

.card__bg:after {
    background-image: linear-gradient(1turn,#000,#0000);
    bottom: 0;
    content: "";
    height: 150px;
    position: absolute;
    width: 100%;
}

.default-padding-bottom {
    padding-bottom: 120px;
}