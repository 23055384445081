.bluefont
{
    color: #6D4DFF;
}



.best-text h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.5;
    color: #39325A;
    margin-bottom: 15px;
}

.best-text {
    padding: 100px 0 113px;
    background: transparent linear-gradient(360deg, #fff 0%, #F5F3FF 100%) 0% 0% no-repeat padding-box;
}
.mt-30
{
    margin-top: 9rem !important;
}