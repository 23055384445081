

/* Layout: */

.col-main {
  flex: 1;  
  justify-content:center;
  padding:50px 25px;
}  

/* .col-main h2{
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.2105263157894737;
} */


.col-main h2 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 100;
  font-size: 68px;
  line-height: 70px;

}

.col-complementary {
  flex: 1;  
}
.col-complementary img
{
  height:400px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
}
/* Responsive: */

@media only screen and (min-width: 767px) {
  .layout {
    display: flex;
  }
}

/* etc */
.centerimg
{
  align-items: center;
display: flex;
justify-content: center;
}

.container-snap {
  max-width:80em;
  margin-right: auto;
  margin-left: auto;
}

.col-snap {
  padding: 1em;
  margin: 0 2px 2px 0;

}


.pt100
{
  padding-top: 100px;
}


.vh100
{
  height: 100vh;
}
/* four col css */

.flex-container-snap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: normal;
  align-content: normal;
}
.flex-items-snap {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  width: 18em;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  height: auto;
  margin-left: 10px;
  padding: 20px;
}


.flex-items-snap h4{
  font-size: 18px;
  font-weight: 200;
}

/* cookie snap-card css */
.cookieCard {
  width: 300px;
  height: 250px;
  background: linear-gradient(to right,rgb(137, 104, 255),rgb(175, 152, 255));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: center; */
  gap: 20px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  margin-left: 20px;
}

.cookieCard::before {
  width: 150px;
  height: 150px;
  content: "";
  background: linear-gradient(to right,rgb(142, 110, 255),rgb(208, 195, 255));
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  right: -25%;
  top: -25%;
}

.cookieHeading {
  font-size:18px;
  font-weight: 200;
  color: rgb(241, 241, 241);
  z-index: 2;
}

.cookieDescription {
  font-size: 0.9em;
  color: rgb(241, 241, 241);
  z-index: 2;
}

.cookieDescription a {
  color: rgb(241, 241, 241);
}


/* cookie snap-card css end */

/* small box animation css */
.banter-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 72px;
  height: 72px;
  margin-left: -36px;
  margin-top: -36px;
}

.banter-loader__box {
  float: left;
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.banter-loader__box:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #454545;
}

.banter-loader__box:nth-child(3n) {
  margin-right: 0;
  margin-bottom: 6px;
}

.banter-loader__box:nth-child(1):before, .banter-loader__box:nth-child(4):before {
  margin-left: 26px;
}

.banter-loader__box:nth-child(3):before {
  margin-top: 52px;
}

.banter-loader__box:last-child {
  margin-bottom: 0;
}

@keyframes moveBox-1 {
  9.0909090909% {
    transform: translate(-26px, 0);
  }

  18.1818181818% {
    transform: translate(0px, 0);
  }

  27.2727272727% {
    transform: translate(0px, 0);
  }

  36.3636363636% {
    transform: translate(26px, 0);
  }

  45.4545454545% {
    transform: translate(26px, 26px);
  }

  54.5454545455% {
    transform: translate(26px, 26px);
  }

  63.6363636364% {
    transform: translate(26px, 26px);
  }

  72.7272727273% {
    transform: translate(26px, 0px);
  }

  81.8181818182% {
    transform: translate(0px, 0px);
  }

  90.9090909091% {
    transform: translate(-26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(1) {
  animation: moveBox-1 4s infinite;
}

@keyframes moveBox-2 {
  9.0909090909% {
    transform: translate(0, 0);
  }

  18.1818181818% {
    transform: translate(26px, 0);
  }

  27.2727272727% {
    transform: translate(0px, 0);
  }

  36.3636363636% {
    transform: translate(26px, 0);
  }

  45.4545454545% {
    transform: translate(26px, 26px);
  }

  54.5454545455% {
    transform: translate(26px, 26px);
  }

  63.6363636364% {
    transform: translate(26px, 26px);
  }

  72.7272727273% {
    transform: translate(26px, 26px);
  }

  81.8181818182% {
    transform: translate(0px, 26px);
  }

  90.9090909091% {
    transform: translate(0px, 26px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(2) {
  animation: moveBox-2 4s infinite;
}

@keyframes moveBox-3 {
  9.0909090909% {
    transform: translate(-26px, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(0px, 0);
  }

  36.3636363636% {
    transform: translate(-26px, 0);
  }

  45.4545454545% {
    transform: translate(-26px, 0);
  }

  54.5454545455% {
    transform: translate(-26px, 0);
  }

  63.6363636364% {
    transform: translate(-26px, 0);
  }

  72.7272727273% {
    transform: translate(-26px, 0);
  }

  81.8181818182% {
    transform: translate(-26px, -26px);
  }

  90.9090909091% {
    transform: translate(0px, -26px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(3) {
  animation: moveBox-3 4s infinite;
}

@keyframes moveBox-4 {
  9.0909090909% {
    transform: translate(-26px, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(-26px, -26px);
  }

  36.3636363636% {
    transform: translate(0px, -26px);
  }

  45.4545454545% {
    transform: translate(0px, 0px);
  }

  54.5454545455% {
    transform: translate(0px, -26px);
  }

  63.6363636364% {
    transform: translate(0px, -26px);
  }

  72.7272727273% {
    transform: translate(0px, -26px);
  }

  81.8181818182% {
    transform: translate(-26px, -26px);
  }

  90.9090909091% {
    transform: translate(-26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(4) {
  animation: moveBox-4 4s infinite;
}

@keyframes moveBox-5 {
  9.0909090909% {
    transform: translate(0, 0);
  }

  18.1818181818% {
    transform: translate(0, 0);
  }

  27.2727272727% {
    transform: translate(0, 0);
  }

  36.3636363636% {
    transform: translate(26px, 0);
  }

  45.4545454545% {
    transform: translate(26px, 0);
  }

  54.5454545455% {
    transform: translate(26px, 0);
  }

  63.6363636364% {
    transform: translate(26px, 0);
  }

  72.7272727273% {
    transform: translate(26px, 0);
  }

  81.8181818182% {
    transform: translate(26px, -26px);
  }

  90.9090909091% {
    transform: translate(0px, -26px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(5) {
  animation: moveBox-5 4s infinite;
}

@keyframes moveBox-6 {
  9.0909090909% {
    transform: translate(0, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(-26px, 0);
  }

  36.3636363636% {
    transform: translate(0px, 0);
  }

  45.4545454545% {
    transform: translate(0px, 0);
  }

  54.5454545455% {
    transform: translate(0px, 0);
  }

  63.6363636364% {
    transform: translate(0px, 0);
  }

  72.7272727273% {
    transform: translate(0px, 26px);
  }

  81.8181818182% {
    transform: translate(-26px, 26px);
  }

  90.9090909091% {
    transform: translate(-26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(6) {
  animation: moveBox-6 4s infinite;
}

@keyframes moveBox-7 {
  9.0909090909% {
    transform: translate(26px, 0);
  }

  18.1818181818% {
    transform: translate(26px, 0);
  }

  27.2727272727% {
    transform: translate(26px, 0);
  }

  36.3636363636% {
    transform: translate(0px, 0);
  }

  45.4545454545% {
    transform: translate(0px, -26px);
  }

  54.5454545455% {
    transform: translate(26px, -26px);
  }

  63.6363636364% {
    transform: translate(0px, -26px);
  }

  72.7272727273% {
    transform: translate(0px, -26px);
  }

  81.8181818182% {
    transform: translate(0px, 0px);
  }

  90.9090909091% {
    transform: translate(26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(7) {
  animation: moveBox-7 4s infinite;
}

@keyframes moveBox-8 {
  9.0909090909% {
    transform: translate(0, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(-26px, -26px);
  }

  36.3636363636% {
    transform: translate(0px, -26px);
  }

  45.4545454545% {
    transform: translate(0px, -26px);
  }

  54.5454545455% {
    transform: translate(0px, -26px);
  }

  63.6363636364% {
    transform: translate(0px, -26px);
  }

  72.7272727273% {
    transform: translate(0px, -26px);
  }

  81.8181818182% {
    transform: translate(26px, -26px);
  }

  90.9090909091% {
    transform: translate(26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(8) {
  animation: moveBox-8 4s infinite;
}

@keyframes moveBox-9 {
  9.0909090909% {
    transform: translate(-26px, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(0px, 0);
  }

  36.3636363636% {
    transform: translate(-26px, 0);
  }

  45.4545454545% {
    transform: translate(0px, 0);
  }

  54.5454545455% {
    transform: translate(0px, 0);
  }

  63.6363636364% {
    transform: translate(-26px, 0);
  }

  72.7272727273% {
    transform: translate(-26px, 0);
  }

  81.8181818182% {
    transform: translate(-52px, 0);
  }

  90.9090909091% {
    transform: translate(-26px, 0);
  }

  100% {
    transform: translate(0px, 0);
  }
}

.banter-loader__box:nth-child(9) {
  animation: moveBox-9 4s infinite;
}
/* small box css end */




.secpattern::after{
  width: 100%;
  height: 100vh;

  /* --s: 84px; */
  --c1: #f2f2f2;
  --c2: #cdcbcc;
  --c3: #999999;

  --_g: 0 120deg, #0000 0;
  background: conic-gradient(
      from 0deg at calc(500% / 6) calc(100% / 3),
      var(--c3) var(--_g)
    ),
    conic-gradient(
      from -120deg at calc(100% / 6) calc(100% / 3),
      var(--c2) var(--_g)
    ),
    conic-gradient(
      from 120deg at calc(100% / 3) calc(500% / 6),
      var(--c1) var(--_g)
    ),
    conic-gradient(
      from 120deg at calc(200% / 3) calc(500% / 6),
      var(--c1) var(--_g)
    ),
    conic-gradient(
      from -180deg at calc(100% / 3) 50%,
      var(--c2) 60deg,
      var(--c1) var(--_g)
    ),
    conic-gradient(
      from 60deg at calc(200% / 3) 50%,
      var(--c1) 60deg,
      var(--c3) var(--_g)
    ),
    conic-gradient(
      from -60deg at 50% calc(100% / 3),
      var(--c1) 120deg,
      var(--c2) 0 240deg,
      var(--c3) 0
    );
  background-size: calc(var(--s) * 1.732) var(--s);
}


/* snap-card css */


.snap-card::before {
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  top: 20%;
}

.snap-card::before,
.snap-card::after {
  content: "";
  background-color: #b3f7eb4b;
  position: relative;
  filter: blur(10px);
}

.cards-sap {
  display: flex;
  gap: 20px;
  justify-content: center;


  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  padding: 2px calc((100% - (350px * 3)) / 2);

}

.cards-sap .red,
.cards-sap .blue,
.cards-sap .green {
  flex: 0 0 300px;

  /* font-family: "CustomFont", Arial, Helvetica, sans-serif; */
  font-size: 24px;
  font-weight: 600;
  backdrop-filter: blur(30px);
  background-color: rgba(65, 65, 65, 0.11);
  border: 1px solid rgba(144, 161, 255, 0.171);
  padding: 25px;
}

.cards-sap .snap-card {
  /* display: flex; */
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  transition: 400ms;
  background-color: #fff;
}

.cards-sap .snap-card p.tip {
  font-size: 18px;
  font-weight: 700;
  color: color-mix(in oklab, rgb(34, 150, 208) 25%, rgb(11, 17, 100) 40%);
}

.cards-sap .snap-card p.second-text {
  font-size: 0.7em;
}

.cards-sap .snap-card:hover {
  transform: scale(1.1, 1.1);
}

.cards-sap span{
  display: contents;
}

span:hover > .snap-card:not(:hover) {
  filter: blur(5px);
  transform: scale(0.9, 0.9);
}
/* snap-card css end */

/* RFS form-rfs snap */

.flex-container-rfs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: stretch;
  align-content: stretch;
  background-color: #dfd8d8;
}

.flex-items-rfs:nth-child(1) {
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  width: 50%;
  padding: 25px;
  justify-content: center;
  flex-direction: column;
  background-color: #cccccc;
}

.flex-items-rfs:nth-child(2) {
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  background-color: #dfd8d8;
  width: 50%;
  padding: 25px;
  justify-content: center;
}


/* RFS FORM END */

/* artimis css bg */

#watch {
  background-color: #cecec9;
  height: 100vh;
  background-image: url("./../images/spot-bg.png");
  background-size: cover;
  background-position: 60% 70%;
  margin-top: 0px;
}

#spot {
  background-color: #fff;
  height: 100vh;
  background-image: url("./../images/unlock.png");
  background-size: cover;
  background-position: 60% 70%;
}

#snap {
  background-color: #fff;
  height: 100vh;
  background-image: url("./../images/snap-lrg.jpg");
  background-size: cover;
  background-position: bottom top;
}


#watch_text {
  color: #222;
  display: inline-block;
  float: left;
  width: 36em;
  margin:8%;
  font-size: 1.15vw;
}

.section_title {
  /* font-size: 3.8em; */
  font-size: clamp(4.3rem, 10vw, 2rem); 
  font-weight: 100;

  -webkit-font-smoothing: auto;
  text-shadow: 0 0 1px #bbb;
  line-height:65px;
}




.sub_text {
  font-size: 18px;
  font-weight: 200;
  line-height: 1.9vw;
  margin-top: 0.35em;

  word-spacing: -1px;
  -webkit-font-smoothing: antialiased;
}

#create {
  background-color: #fff;
  height: 100vh;
  background-image: url("./../images/xr-iphone.jpg");
  background-size: cover;
  background-position: 60% 70%;
}
#create_text {
  color: #606060;
  display: inline-block;
  float: right;
  width: 36em;
  margin: 12.3% 4%;
  font-size: 1.15vw;
}

.sec-render
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url('./../images/render.jpg'); */
    background-image:url('./../images/render-theme.jpg');


    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 

  }

  .pb100
  {
    padding-bottom: 100px;
  }

  .spot-sec1
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    background-image:url('./../images/empower.jpg');
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 

  }


  .spot-sec2
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    background-image:url('./../images/cost-eff.jpg');
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 

  }

  .spot-sec3
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    background-image:url('./../images/arrug.jpg');
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 

  }


  .spot-sec4
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    background-image:url('./../images/optimize.jpg');
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 

  }

  .sec-snap
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url('./../images/sec-snap.jpg'); */
    background-image:url('./../images/sec-snap-theme.jpg');
    background-attachment: fixed;
    background-color: liner;
    /* dimensions are important, ensure it is tall enough to scroll */ 

  }

  .spot-sec5
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url('./../images/ai-testing.jpg'); */
    background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(56, 55, 55)), url('./../images/ai-testing.jpg');
    background-attachment: fixed;
  
    /* dimensions are important, ensure it is tall enough to scroll */ 

  }

  .spot-sec6
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url('./../images/ai-testing.jpg'); */
    background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(56, 55, 55)), url('./../images/ai-testing.jpg');
    background-attachment: fixed;
    filter: grayscale(100%);
    /* dimensions are important, ensure it is tall enough to scroll */ 

  }

  .spot-sec7
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url('./../images/ai-testing.jpg'); */
    background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(56, 55, 55)), url('./../images/ai-testing.jpg');
    background-attachment: fixed;
    filter: sepia(100%);
    /* dimensions are important, ensure it is tall enough to scroll */ 

  }

  .spot-sec8
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url('./../images/ai-testing.jpg'); */
    background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(56, 55, 55)), url('./../images/ai-testing.jpg');
    background-attachment: fixed;

    /* dimensions are important, ensure it is tall enough to scroll */ 

  }


  .spot-sec9
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url('./../images/ai-testing.jpg'); */
    background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(56, 55, 55)), url('./../images/ar-vr.jpg');
    background-attachment: fixed;

    /* dimensions are important, ensure it is tall enough to scraoll */ 

  }


  
  .spot-sec999
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url('./../images/ai-testing.jpg'); */
    background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(56, 55, 55)), url('./../images/why-move.jpg');
    background-attachment: fixed;

    /* dimensions are important, ensure it is tall enough to scroll */ 

  }

  .spot-sec10
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url('./../images/ai-testing.jpg'); */
    background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(56, 55, 55)), url('./../images/ar-vr.jpg');
    background-attachment: fixed;
    filter: grayscale(100%);
    /* dimensions are important, ensure it is tall enough to scroll */ 

  }

  .spot-sec11
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url('./../images/ai-testing.jpg'); */
    background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(56, 55, 55)), url('./../images/ar-vr.jpg');
    background-attachment: fixed;
    filter: hue-rotate(180deg);
    /* dimensions are important, ensure it is tall enough to scroll */ 

  }

  .spot-sec12
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url('./../images/ai-testing.jpg'); */
    background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(56, 55, 55)), url('./../images/ar-vr.jpg');
    background-attachment: fixed;
    filter: sepia(100%);
    /* dimensions are important, ensure it is tall enough to scroll */ 

  }


  .art-key
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url('./../images/artemis-key.jpg'); */
    background-image:url('./../images/artemis-key-theme.jpg');
    background-attachment: fixed;
    background-color: liner;
    /* dimensions are important, ensure it is tall enough to scroll */ 

  }
  .width34
  {
    width: 32em;
  }
  ul.Skew li {
    color: #000;
    font-size: 20px;
    font-weight: 800;
    padding: 5px;
}
  h2.bigfont {
    /* font-size: 5vw; */
    font-size: clamp(4.3rem, 10vw, 2rem); 
    line-height: 80px;
}
.linheight25
{
  line-height: 32px;
}

.sticky-label {
  position: -webkit-sticky; /* For Safari */
  padding: 20px;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #ffffff; /* Change background color for sticky sections */
}


#dubai {
  background-color: #cecec9;
  height: 100vh;
  background-image: url('./../images/anouncment-bg1.png');
  background-size: cover;
  background-position: 60% 70%;
}

#newlocation {
  background-color: #cecec9;
  height: 100vh;
  background-image: url('./../images/opening-shortly.png');
  background-size: cover;
  background-position: 60% 70%;
}

ul.location-list li {
  list-style: disc !important;
  font-size: 19px;
  margin-bottom: 29px;
  margin-left: -14px;
}

.width50
{
  width: 42em;
}


@media only screen and (max-width:767px) {
#watch_text {

  width: 63em;

}

.section_title {
  /* font-size: 3.8em; */
  font-size: clamp(2.3rem, 10vw, 2rem);
  font-weight: 100;
  -webkit-font-smoothing: auto;
  text-shadow: 0 0 1px #bbb;
  line-height: 39px;
}


#dubai {
  background-color: #cecec9;
  height: 100vh;
  background-image: url('./../images/anouncment-bg1.png');
  background-size: cover;
  background-position: 6% 7%;
}

.lead {

  line-height: 28px;

}

.width34 {
  width: 100%;
}


#watch {

  background-position: 132% 110%;
  margin-top: 30px;
}

}