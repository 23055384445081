


#career.button-17 {
  align-items: center;
  appearance: none;
  background-color: #fff !important;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #f4f6f8;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
 
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}
button.button-17 {
  float: right;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  position: relative;
  top: -11px;
  left: 4px;
}




.button-17:hover {
  background: #717272;
  color: #96d21e;
}

.button-17:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-17:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.button-17:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

#mainMenu li > a {
    font-size: 16px;
    letter-spacing: 1px;
    color: #fff;
    font-weight: 400;
    /* position relative to its previous postion */
    position: relative;
    z-index: 1;
    text-decoration: none;
  }
  
  .main-header.fixed-nav #mainMenu li > a {
    color: #fff;
    text-decoration: none;
  }
  
  #mainMenu li:not(:last-of-type) {
    margin-right: 30px;
  }
  
  #mainMenu li > a::before {
    position: absolute;
    content: "";
    width: calc(100% - 1px);
    height: 1px;
    background: #fff;
    bottom: -6px;
    left: 0;
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: right center;
    -ms-transform-origin: right center;
    transform-origin: right center;
    z-index: -1;
    -webkit-transition: transform 0.5s ease;
    transition: transform 0.5s ease;
  }
  
  #mainMenu li > a:hover::before,
  #mainMenu li > a.active::before {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
  }
  
  .main-header.fixed-nav #mainMenu li > a::before {
    background: #000;
  }
  
  .main-header {
    position: relative;
    left: 0;
    z-index: 99;
    width: 100%;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  
  .main-header.fixed-nav {
    top: 0;
    background: #fff;
    -webkit-box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
    box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  
  /* main-header end */
  
  @media (max-width: 991px) {
    /*header starts*/
  
    .collapse.in {
      display: block !important;
      padding: 0;
      clear: both;
    }
    .navbar-toggler {
      margin: 0;
      padding: 0;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 25px;
      right: 0;
      border: none;
      border-radius: 0;
      outline: none !important;
    }
    .main-header .navbar {
      float: none;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }
    .main-header .navbar-nav {
      margin-top: 70px;
    }
    .main-header .navbar-nav li .nav-link {
      text-align: center;
      padding: 20px 15px;
      border-radius: 0px;
    }
    /**/
    .main-header .navbar-toggler .icon-bar {
      background-color: #fff;
      margin: 0 auto 6px;
      border-radius: 0;
      width: 30px;
      height: 3px;
      position: absolute;
      right: 0;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    .main-header .navbar .navbar-toggler .icon-bar:first-child {
      margin-top: 3px;
    }
    .main-header .navbar-toggler .icon-bar-1 {
      width: 10px;
      top: 0px;
    }
    .main-header .navbar-toggler .icon-bar-2 {
      width: 16px;
      top: 12px;
    }
    .main-header .navbar-toggler .icon-bar-3 {
      width: 20px;
      top: 21px;
    }
    .main-header .current .icon-bar {
      margin-bottom: 5px;
      border-radius: 0;
      display: block;
    }
    .main-header .current .icon-bar-1 {
      width: 18px;
    }
    .main-header .current .icon-bar-2 {
      width: 30px;
    }
    .main-header .current .icon-bar-3 {
      width: 10px;
    }
    .main-header .navbar-toggler:hover .icon-bar {
      background-color: #fff;
    }
    .main-header .navbar-toggler:focus .icon-bar {
      background-color: #fff;
    }
    /*header ends*/
    #mainMenu li {
      width: 120px;
      text-align: left;
      margin-left: 30%;
      margin-bottom: 10px;
    }
  
    .banner {
      padding-top: 150px;
    }
    .dropdown-toggle{
      padding-right: 55px;
    }
    .dropdown-content{
      margin-left: 47%;
    }
  }
  
  /* Dropdown Content (Hidden by Default) */
  
  .dropdown-content {
    display: none;
    position: absolute;
    /* background-color: #f1f1f1; */
    min-width: fit-content;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  
  .dropdown-content a:hover {
    background-color: #ddd;
  }
  
  /* Show the dropdown menu on hover */
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* section starts */
  
  .banner {
    height: 80vh;
    background-image: url("https://images.unsplash.com/photo-1541746972996-4e0b0f43e02a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60");
    background-position: center;
    background-size: cover;
    color: #fff;
  }
  
  .banner h1 {
    font-size: 50px;
    font-weight: 600;
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  }
  
  .banner p {
    padding-top: 15px;
    line-height: 150%;
    font-size: larger;
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  }
  @media (max-width: 321px){
  .banner p{
    display: none;
  }
  .search-job {
    margin-top:-20px;
  }
  }
  /* search bar */
  
  .search-job {
    padding: 30px 0;
    margin: -29px 6% 50px;
    background-image: linear-gradient(to right, #b3b3b3, #57606f);
  }
  @media (max-width: 514px){
  .search-job {
    margin-top:-15px;
  }
  }
  .search-job .form-control {
    max-width: 250px;
    display: inline-flex;
    margin: 10px 5px;
    height: 50px;
    border-radius: 25px;
    box-shadow: none !important;
    border: none !important;
    padding-left: 30px;
  }
  
  .search-job .btn-primary {
    /* width: 150px; */
    border-radius: 25px;
    box-shadow: none !important;
    border: none;
    height: 50px;
    margin: 5px;
    padding: 5px 20px;
    display: table-cell;
    vertical-align: middle;
  }
  
  .search-job .btn-primary:hover {
    border: 1px solid yellow;
  }
  
  /* top recuruiters */
  
  #recruiters {
    padding-top: 50px;
    padding-bottom: 100px;
  }
  
  #recruiters h3 {
    text-align: center;
    padding-bottom: 40px;
  }
  
  h3::before {
    content: "";
    /* background-image: linear-gradient(to right, #6db9ef, #7ce08a); */
    width: 50px;
    height: 30px;
    position: absolute;
    z-index: -10;
    margin-top: -15px;
    border-top-left-radius: 70px;
    border-bottom-right-radius: 90px;
  }
  
  #recruiters img {
    max-width: 100px;
    margin: 10px 20px;
  }
  
  .col-md-3 {
    display: inline-block;
    margin-left: -4px;
  }
  
  .col-md-3 img {
    width: 100%;
    height: auto;
  }
  
  body .carousel-indicators li {
    background-color: red;
  }
  
  body .carousel-control-prev-icon,
  body .carousel-control-next-icon {
    background-color: red;
  }
  
  body .no-padding {
    padding-left: 0;
    padding-right: 0;
  }
  
  /* category wise jobs */

  .apply-form a {
    color: #e59118 !important;
}
  .title-box-yellow {
    background-color: #DF9926;
    color: #ffffff;
    padding: 4px 8px;
    display: inline-block;
    position: relative;
    font-size: 11px;
    letter-spacing: 1px;
position: relative;
left: -35px;
    font-weight: 700;
}
  .title-box-yellow:after {
    border-left: 0 solid transparent;
    border-right: 20px solid transparent;
    border-top: 25px solid #DF9926;
    clear: both;
    content: "";
    height: 0;
    position: absolute;
    right: -20px;
    top: 0;
    width: 0;
}
  
  .category ul li {
    list-style: none;
    text-decoration: none !important;
    text-indent: -31px;
}
  .category {
    padding: 30px;
    border-radius: 10px;
    margin: 20px 0;
    box-shadow: 0 2px 40px 0 rgba(110, 130, 208, 0.18);
  }
  
  .jobcategory {
    width: 20%;
  }
  
  .categories {
    display: inline-flex;
    justify-content: space-evenly;
  }
  

  @media only screen and (max-width: 1000px) {
    .categories {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .jobcategory {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  /* recent jobs */
  
  #jobs {
    background: #f8f8fa;
    padding: 50px 0;
  }
  
  .company-details {
    box-shadow: 0 2px 70px 0 rgba(110, 130, 208, 0.18);
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .job-update {
    background: white;
    padding: 14px 35px;
    border-radius: 5px;
}
  .job-update h4 strong {
    font-size: 13px;
    font-family: "Oxygen", sans-serif !important;
  }
  
  .job-update .fa {
    margin-top: 5px;
    margin-right:0px;
    color: #77d1b1;
  }
  
  .price {
    color: #0c110f;
  }
  
  .location {
    color: rgb(12, 7, 7);
  }
  
  /* .description {
    display: none;
  }
  .company-details :hover .description {
    display: block;
  }
   */
  
  
  .description {
      overflow: hidden; 
      height: 85px; opacity: 1;
    
      transition:1s;
  }
  .company-details :hover .description{
    height: 85px;
    opacity: 1;
      /* height: auto; opacity: 1;
      transition: height 166ms 2ms, opacity 600ms 20ms; */
  }
  
  
  .bookmark{
    color: #000 !important;
    float: right;
    display: inline-flex;
    font-size: 20px;
    cursor: pointer;
  }
  
  .bookmark p{
    margin-left: 5px;
      font-size: 20px;
      cursor: pointer;
  
  }
  .fa-heart-o:before {
      content: "\f08a";
      margin-right: 8px;
        font-size: 20px;
        cursor: pointer;
        
      color: rgb(33, 33, 226) !important;
  
  }
  .fa-heart-o:before .bookmark{
    color: rgb(33, 33, 226) !important;
  }
  .fa-heart:before {
      content: "\f004";
      margin-right: 8px;
        font-size: 20px;
        cursor: pointer;
        color: rgb(33, 33, 226) !important;
  }
  
  
  
  
  .job-update small {
    background: #efefef;
    padding: 1px 5px;
    margin: 0 5px;
  }
  
  .job-update small:hover {
    cursor: pointer;
    background: #23c0e9;
    z-index: 2;
    transition: all 200ms ease-in;
    transform: scale(1.1);
    color: white;
  }
  
  .job-update p {
    margin-bottom: 0 !important;
  }
  
  .job-update a {
    text-decoration: none !important;
    color: #b6bed2;
    float: right;
  }
  
  .job-update a:hover {
    text-decoration: none !important;
    color: rgb(0, 95, 238);
    float: right;
  }
  
  .apply-btn {
    height: 40px;
    /* background-image: linear-gradient(to right, #2d2727, #2c3f57); */
    padding: 5px;
    border-bottom-left-radius: 5px;
  }
  
  .category a {
    color: #0a0e02;
    text-decoration: none !important;
}
.apply-btn .btn-primary {
  padding: 0 30px;
  float: right;
  box-shadow: none !important;
  background: transparent;
  border: 1px solid #3a3a3a;
  /* width: 100px; */
  height: 30px;
  line-height: 30px;
  background: var(--bs-warning-border-subtle);
  color: var(--bs-secondary-text-emphasis);
  font-size: 16px;
  border-radius: 10px;
}
  .pagelink li {
    height: 25px;
    list-style: none;
    width: 25px;
    display: inline-block;
    cursor: pointer;
  }
  
  .pagelink li:hover,
  .activa {
    color: #fff;
    background-image: linear-gradient(to right, #6db9ef, #7ce08a);
  }
  
  .left-arrow {
    color: #999;
  }
  
  .right-arrow {
    color: #29b929;
  }
  
  /* site-stats */
  
  #site-stats {
    padding: 100px 0;
  }
  
  #site-stats {
    margin-bottom: 30px;
  }
  
  .stats-box {
    padding: 30px;
    border-radius: 10px;
    margin: 20px 0;
    box-shadow: 0 2px 40px 0 rgba(110, 130, 208, 0.18);
  }
  
  .stats-box:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    border: 1px solid #cccccc;
    background-color: #00838d;
    transform: scale(1.1);
  }
  
  .stats-box:hover p {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
  
  .stats-box:hover i {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
  
  .stats-box:hover small {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
  
  .stats-box .fa {
    font-size: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  /* footer */
  
  .page-footer {
    background-color: rgb(37, 28, 28);
  }
  
  .icon_name {
    padding-left: 10px;
    cursor: pointer;
  }
  
  .icon_name:hover {
    color: #00ff00;
  }
  
  .dark-grey-text {
    color: #fff;
  }

/* modal css  */
/* src/Modal.css */

button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the overlay is on top */
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 4px;
  width: 80%;
  max-width: 500px;
  position: relative;
  z-index: 1001; /* Ensure the modal box is on top of the overlay */
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5em;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  margin-top: 20px;
}


/* modal css end */