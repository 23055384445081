

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* solution page css */

.topheader
{
  border: 2px solid green;
  height: 50vh;
  clear: both;
}

.header_solution{
  height: 100vh;
}

.header__img {
	position: relative;
	width: 45%;
	height: 100%;
	top: 0;
	right: 100px;
	/* clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%); */
	overflow: hidden;
}

.header__img img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.title .stroke {
	display: inline-block;
	color: transparent;
	-webkit-text-stroke: 1px var(--pr-color);
}
