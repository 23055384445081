.sec20
{
  width: 100%;

  background-color: #fff;
  background-size: cover;
  background-position: 50% 50%;
  z-index: -999;
  overflow-x: hidden;

  
  background:  url(./../images/sec12-bg.png);
  background-attachment: fixed;
  /* dimensions are important, ensure it is tall enough to scroll */ 
  min-height: 100vh;
}
.sec20 h2 {
    font-family: Raleway;
    font-size: 55px;
    font-weight: 600;
  }

  .sec13
  {
    overflow-x: hidden;
  }

  .bg
  {
    background-color: #888;
  }

  /* contact css */
  .image-reveal {
    position: relative;
    overflow: hidden;
  }
  
  .image-reveal img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(73, 14, 14,0.2)
  }
  /* contact css end */


  /* products hero sections */

  .bg-section{


/* background-image: url('./../images/snap-bg1.png'); */

    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    background-attachment: fixed;
    background-repeat: no-repeat;
    min-height: 100vh;
    /* background-color: #fff; */
    /* background-image: url('./../images/snap-svg.svg'); */
  }

  .slide-image {
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    right: 0;
    width: auto !important;
    z-index: -258258;
}

  .bg-section-art {
  

    background-size: cover;
    background-position: 50% 50%;
/* background-image: url('./../images/5g-bg.png'); */
background-color: #084298;
      background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;

    z-index: -999;

    background-attachment: fixed;
    background-repeat: no-repeat;
    min-height: 100vh;
  }


  .bg-section-spot{


    /* background-image: url('./../images/snap-bg1.png'); */
    
        /* dimensions are important, ensure it is tall enough to scroll */ 
        min-height: 100vh;
        background-size: cover;
        background-position: 50% 50%;
        z-index: -999;
        background-attachment: fixed;
        background-repeat: no-repeat;
        min-height: 100vh;
        /* background-color: #fff; */
        background-image: url('./../images/spot-bg.png');
      }
    
  .fontsize53
  {
    font-size: clamp(30px,4vw,53px);
  }

  /**
 * Demo Styles 
 */

html {
  box-sizing: border-box;
}

*, 
*:before, 
*:after {
  box-sizing: inherit;
}



.demo {
  margin: 64px 0;
}

.demo svg {
  width: 144px;
  height: 144px;
}

/**
 * Animation Styles
 */

 #fill
 {
  fill: #6ab0fa;
  animation: fadeInOut 5s infinite;
  position: relative;
 }
 .dot-center {
  background-color: #c11a1a;
  /* -webkit-transform: translate3d(0, 0, 0);
  -webkit-transform-origin: 331px 201px;
  -webkit-transform: translate3d(0, 0, 0); */
  animation: example 5s infinite;
  
}

@keyframes example {
  0%   {fill:red; left:0px; top:0px;}
  25%  {fill:yellow; left:200px; top:0px;}
  50%  {fill:blue; left:200px; top:200px;}
  75%  {fill:green; left:0px; top:200px;}
  100% {fill:red; left:0px; top:0px;}
}
@keyframes rotate-right {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotate-left {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes mymove {
  0%   {top: 0px;}
  25%  {top: 200px;}
  50%  {top: 100px;}
  75%  {top: 200px;}
  100% {top: 0px;}
}
.full-body {
  stroke-dasharray: 10;
  stroke-dashoffset: 10;
  animation: 
    logoStroke 1.75s linear forwards,

} 
@keyframes logoStroke {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeInOut {
  0% {
      opacity: 0;
  }

  25% {
    opacity: 0.2;
}
50% {
  opacity: 0.4;
}
75% {
  opacity: 0.6;
}
  100% {
      opacity: 1;
  }
}

@keyframes draw {
  0% {
      stroke-dashoffset: 1000;
  }
  100% {
      stroke-dashoffset: 0;
  }
}
#strk
{
  fill: none;
  stroke: black;
  stroke-width: 52;
  stroke-dasharray: 10;
  stroke-dashoffset: 100;
  animation: draw 3s ease forwards infinite;
}

@keyframes draw {
  0% {
      stroke-dashoffset: 100;
  }
  25% {
    stroke-dashoffset: 1000;
}
50% {
  stroke-dashoffset: 10000;
}
75% {
  stroke-dashoffset: 100000;
}
  100% {
      stroke-dashoffset: 0;
  }
}
.circleone
{
  animation: draw 3s ease forwards infinite;
}
#linestrka
{
  fill: none;
            stroke: #d3d3d3;
            stroke-width: 0.5;
            stroke-dasharray: 100;
            stroke-dashoffset: 1000;
            animation: draw2 3s steps(10, end) forwards infinite;
}

#linestrk
{
  fill: none;
            stroke: #d3d3d3;
            stroke-width: 0.5;
            stroke-dasharray: 100;
            stroke-dashoffset: 1000;
            animation: draw2 3s steps(10, end) forwards infinite;
}

@keyframes draw2 {
  0% {
      stroke-dashoffset: 200;
  }
  100% {
      stroke-dashoffset: 10000;
  }
}

.circle1 {

  animation: expand 1s ease forwards infinite;
}
@keyframes expand {
  0% {
      transform: scale(0);
      opacity: 0;
  }
  100% {
      transform: scale(1);
      opacity: 1;
  }
}

.ctrl-break-blue {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
  to right bottom,
  #a7f0f7,
  #8ae2f9,
  #72d3fb,
  #66c2fc,
  #6ab0fa
);
/* background-image: linear-gradient(to bottom, #292c32, #3e3b42, #544b50, #685c5c, #7a6f6a); */
}

  .hero-head .ctrl-break {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    /* background-image: linear-gradient(
    to right bottom,
    #a7f0f7,
    #8ae2f9,
    #72d3fb,
    #66c2fc,
    #6ab0fa
  ); */
  background-image: linear-gradient(to bottom, #292c32, #3e3b42, #544b50, #685c5c, #7a6f6a);
}
.fontweight600 {
  font-weight: 600 !important;
}
.hero-head {
    font-size: 65px;
    /* text-align: right; */
    text-transform: uppercase;
    line-height: 65px;
    margin-top: 100px;
}

.ctrl-break {
    display: block;
}

 

  .left-text,
  .right-text {
    padding: 20px;
  }
  @media (min-width: 768px) {
    .left-text {
      order: 1;
    }
    .right-text {
      order: 2;
    }
  }

  /* products hero section end */



    
  @media only screen and (max-width:767px) {
    .hero-head {
      font-size: 41px;
 
      line-height: 41px;
    
  }
  }
  