
blockquote{
    font-size: 1.4em;
    width:40%;
    margin:50px auto;
    font-family:Open Sans;
    font-style:italic;
    color: #555555;
    padding:1.5em 30px 1.2em 75px;
    /* border-left:8px solid #78C0A8 ; */
    line-height:1.6;
    margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
    /* background:#EDEDED; */
  }
  
  blockquote::before{
    font-family:Arial;
    content: "\201C";
    color:#78C0A8;
    font-size:4em;
    position: absolute;
    left: 10px;
    top:-10px;
  }
  
  blockquote::after{
    content: '';
  }
  
  blockquote span{
    display:block;
    color:#333333;
    font-style: normal;
    font-weight: bold;
    margin-top:1em;
  }

  .normal-ptop {
    padding-top: 150px !important;
    margin-top: 22px;
}


.pos-relative
{
  position: relative !important;
}


.ptop-standard{
    padding-top: 3px !important;
    margin-top:180px;
}

.still
{
  background-image: url('./../../images/portal-bg.webp');
  background-repeat: no-repeat;
  background-position:center;
  background-size: cover;
  width: 100%;
  height: 50vh;
  background-attachment: fixed;
}

/* circle grid css */

.circle-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between circles */
  justify-content: center;
}

.circlew {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-color: #4caf50;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .circle {
      width: 150px;
      height: 150px;
      font-size: 28px;
  }
}

/* circle grid end */

/* overlaping css */


.parent-overlaping {
  position: relative;

}

.parent-overlaping.wid
{
  height: 800px;
}

.left-pan, .right-pan {
  position: absolute;
  background-color: white;
  /* border: 1px solid black; */
  padding: 1em 3em;
}
.left-pan {
  padding: .5em 3em;
  top: -9.5em;
  left: -17em;
}
.right-pan {
  top: 0;
  left: 8em; 
  z-index: 1;
  background-color:transparent;
}

/* overlaping css end */

/* accordian css */

 /*--------Accordion-------*/
 .acc-kontainer {
   width: 100%;
   margin: auto;
 }
 .acc-kontainer .acc-body {
   width: 98%;
   width: calc(100% - 20px);
   margin: 0 auto;
   height: 0;
   color: rgba(0, 0, 0, 0);;
   background-color: rgba(255, 255, 255, 0.2);
   line-height: 28px;
   padding: 0 20px;
   box-sizing: border-box;
   transition: 0.5s;
 }
 
 .acc-kontainer label {
   cursor: pointer;
   background-color: rgba(255, 255, 255, 0.1);
   border-bottom: 1px solid rgba(255, 255, 255, 0.1);
   display: block;
   padding: 15px;
   width: 100%;
   color: #515050;
   font-weight: 400;
   box-sizing: border-box;
   z-index: 100;
 }
 
 .acc-kontainer input{
   display: none;
 }
 
 .acc-kontainer label:before {

   font-weight: bolder;
   float: right;
 }
 
 .acc-kontainer input:checked+label {
   background-color: rgba(255, 255, 255, 0.15);
 }
 
 .acc-kontainer input:checked+label:before {

   transition: 0.5s;
 }
 
 .acc-kontainer input:checked~.acc-body {
   height: auto;
   color: #070101;
   font-size: 16px;
   padding: 20px;
   transition: 0.5s;
 }
.text-left
{
  text-align: left;
}
.acc-kontainer label
{
  text-decoration: underline !important;
  text-underline-position: under;
}

.pt250
{
  padding-top: 250px;
}
.acc-kontainer label {
  /* text-decoration: underline !important; */
  border: 1px solid beige;
  font-size: 18px;
  text-shadow: black;
  margin-bottom: 15px;
}
 .col-wjg.col-s8h.clnt2 {
  max-width: 33%;
  flex-basis: 50%;
}
 .col-wjg.col-phw.clnt {
  max-width: 50.33333%;
  flex-basis: 8.33%;
}

.container-rze.clntfull {
  width: 68%;
  max-width: 90rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
}


/* Mobile version */
@media only screen and (max-width: 767px) {


  blockquote {
    font-size: 1em;
    width: 100%;
    margin: 50px auto;
    font-family: Open Sans;
    font-style: italic;
    color: #555555;
    padding: 1.5em 30px 1.2em 75px;
    /* border-left: 8px solid #78C0A8; */
    line-height: 1.6;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background: #EDEDED; */
}

.left-pan, .right-pan {
  position: static;
  padding: 0.2em .2em;
}


.pt250 {
  padding-top: 19px;
}
}