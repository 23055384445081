
  
    .container-aiml { 
        min-height: 700px; 
        margin-top: 6%; 
        background-image: url("./../../images/aiml-center-bg.jpg"); 
        background-repeat: no-repeat; 
        background-position: center center; 
        background-size: cover; 
        display: flex; 
        flex-direction: row; 
        align-items: center;
        background-attachment: fixed;
      } 
    

        
    .container-aiml > .container-d1s { 
        padding-top: 8%; 
        padding-bottom: 8%; 
        width: 100%;
      } 

      /* below grid css */

.angry-grid {
   display: grid; 

   grid-template-rows: 1fr 1fr 1fr;
   grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
   
   gap: 0px;
   height: 100%;
   
}
  
#item-0 {

   background-color: #fff; 
   grid-row-start: 1;
   grid-column-start: 3;

   grid-row-end: 3;
   grid-column-end: 5;
   
}
#item-1 {

   background-color: #585FD7; 
   grid-row-start: 3;
   grid-column-start: 2;

   grid-row-end: 4;
   grid-column-end: 3;
   
}
#item-2 {

   background-color: #A98BAA; 
   grid-row-start: 2;
   grid-column-start: 5;

   grid-row-end: 4;
   grid-column-end: 6;
   
}
#item-3 {

   background-color: #BF9B7D; 
   grid-row-start: 1;
   grid-column-start: 5;

   grid-row-end: 2;
   grid-column-end: 6;
   
}
#item-4 {

   background-color: #F7577E; 
   grid-row-start: 2;
   grid-column-start: 1;

   grid-row-end: 3;
   grid-column-end: 3;
   
}
#item-5 {

   background-color: #8C996D; 
   grid-row-start: 3;
   grid-column-start: 4;

   grid-row-end: 4;
   grid-column-end: 5;
   
}



