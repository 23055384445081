/*  */
.topspace
{
    /* height: 50vh !important; */
    width: 50%;
}

.ptop
{
    padding-top: 100px !important;
    margin-top: 250px;
}

.ptop11
{
    padding-top: 100px !important;
    margin-top: 20px;
}


/* slider sticky */

.iot-sec4
{
  width: 100%;
min-height: 100vh;
  background-color: #fff;
  background-size: contain;
  background-position: 50% 50%;
  z-index: -999;
  /* background-image:url('./../../images/dot-world.svg'); */
  background-attachment: fixed;
  /* dimensions are important, ensure it is tall enough to scroll */ 

}

.iot-sec5
{
  width: 100%;
min-height: 100vh;
  background-color: #fff;
  background-size: contain;
  background-position: 50% 50%;
  z-index: -999;
  background-image:url('./../../images/dot-world\ -green.svg');
  background-attachment: fixed;
  filter: hue-rotate(45deg);

  /* dimensions are important, ensure it is tall enough to scroll */ 

}


.iot-container {
  display: block;
  /* flex-direction: row; */
  /* flex-wrap: nowrap; */
  /* justify-content: flex-end; */
  /* align-items: end; */
  align-content: stretch;
  padding-top: 200px;
  width: 35%;
  margin: 0 auto;
}
.iot-items:nth-child(1) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.iot-items:nth-child(2) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}


/* mobile css */

@media only screen and (max-width:767px) {
.ptop {
  padding-top: 10px !important;
  margin-top: 3px;
}
}