/* .section a {
    color: #333 !important;
    text-decoration: none !important;
} */
 .main {
  height: 100vh;
  overflow: scroll;
 }
 .loader {
  position: absolute;
  left: 45%;
  top: 45%;
  z-index: 999;
 }

.section > .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #131313;
    font-family: "Neuton", serif;
    font-weight: 700;
    line-height: 1.3;
}
.section {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .section a
  {
    text-decoration: none;
    color: #131313;
  }
  
  .section-title {
    position: relative;
    z-index: 1;
    margin-bottom: 15px;
  }
  .section-title::before {
    position: absolute;
    height: 4px;
    width: 100%;
    content: "";
    left: 0;
    top: 0;
    z-index: -1;
  }
  
  .bg-cover {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }


  
  #media img {

    float: left !important;
}

#media .media-body {
  padding-left: 117px;

}

a.media.align-items-center {
  clear: both;
  float: left;
}
.card {
    border: 0;
    position: relative;
  }
  .card-meta {
    padding-left: 0;
  }
  .card-meta li {
    list-style-type: none;
    margin-right: 10px;
    display: inline-block;
  }
  .card-meta li a {
    font-weight: 500;
    color: #888;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  .card-meta li a:hover {
    color: #13AE6F;
  }
  .card-footer {
    background-color: transparent;
  }
  .card-footer span {
    vertical-align: middle;
    color: #888;
  }
  .card-img {
    height: 200px;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  
  .card-image {
    position: relative;
  }

  #cardimg.card-image img {
    border-radius: 0;
    width: 100%;
    height: 450px !important;
    object-fit: cover;
}

#blog .card-text{
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
        line-clamp: 2; 
-webkit-box-orient: vertical
}
#blog .card-img, .card-img-top, .card-img-bottom {
  width: 100%;
  height: auto;
}
  .card-image img {
    border-radius: 0;
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .card-image .post-info {
    position: absolute;
    right: 0;
    top: -5px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .card-image .post-info span {
    display: inline-block;
    line-height: 1.3;
    font-size: 12px;
    padding: 3px 8px;
    padding-top: 4px;
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    color: #fff;
    border-radius: 0;
    margin-top: 5px;
  }
  
  .read-more-btn {

    font-size: 18px;
    text-decoration: underline;
  }
  
.post-title{
    color: #000;
    display: inline;
    text-decoration: none;
    background-image: linear-gradient(#3deaa4, #3deaa4);
    background-position: 0% 80%;
    background-repeat: no-repeat;
    background-size: 0% 8px;
    transition: background-size 0.3s;
  }
  .post-title-sm {
    background-size: 0% 3px;
  }
  
  a.post-title:hover {
    color: #000;
    background-size: 100% 8px;
  }
  
  a.post-title-sm:hover {
    background-size: 100% 3px;
  }

  
/* widget */
.widget-blocks {
    padding-left: 20px;
  }
  @media (max-width: 991px) {
    .widget-blocks {
      padding-left: 0;
    }
  }
  .widget-blocks .row > .col:last-child .widget, .widget-blocks .row > [class*=col-]:last-child .widget {
    margin-bottom: 0 !important;
  }
  
  .widget {
    margin-bottom: 50px;
  }

  h3:not(.v1, .v4) {

    font-size: clamp(18px,1.46vw,18px);

}

.bg-white {
  background: white !important;
}
  .widget-body {
    padding: 30px;
    background-color: #fff;
    border-radius: 0;
    min-height: 400px;
    height: auto;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);
}
  @media (max-width: 1200px) {
    .widget-body-sm {
      padding: 20px;
    }
  }
  @media (max-width: 991px) {
    .widget-body {
      padding: 25px;
    }
  }
  @media (max-width: 575px) {
    .widget-body {
      padding: 15px;
    }
  }
  .widget-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .widget-list .card {
    border-bottom: 1px dashed #ececec;
    padding-bottom: 15px;
  }
  .widget-list .media img {
    height: 80px;
    width: 80px !important;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 0;
  }
  .widget-list .media:not(:last-child) {
    border-bottom: 1px dashed #ececec;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  .widget-list .media:hover {
    opacity: 0.8;
  }
  .widget-list li {
    display: inline-block;
  }
  .widget-list li a {
    background-color: rgba(19, 19, 19, 0.05);
    color: black;
    text-transform: capitalize;
    padding: 6px 16px;
    margin-bottom: 9px;
    margin-right: 9px;
    display: block;
    font-size: 14px;
    border-radius: 0;
  }
  .widget-list li a:hover {
    background-color: rgba(61, 234, 164, 0.25);
  }
  
  .author-thumb-sm {
    height: 150px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  @media (max-width: 991px) {
    .author-thumb-sm {
      height: 250px;
    }
  }
  @media (max-width: 767px) {
    .author-thumb-sm {
      height: 200px;
    }
  }
  
  .post-thumb-sm {
    height: 80px;
    width: 100px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 0;
  }

  
/* blog */
.pagination {
    justify-content: center;
  }
  .pagination .page-item .page-link {
    display: inline-block;
    width: 42px;
    height: 42px;
    border-radius: 0;
    border: 1px solid #ececec;
    text-align: center;
    margin: 0 4px;
    font-weight: 500;
    color: #131313;
    padding: 0;
    line-height: 41px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);
  }
  .pagination .page-item .page-link:focus, .pagination .page-item .page-link:hover {
    box-shadow: none;
    background: #13AE6F;
    color: #fff;
  }
  .pagination .page-item:first-child .page-link, .pagination .page-item:last-child .page-link {
    border-radius: 0;
  }
  .pagination .page-item.active .page-link {
    background: #13AE6F;
    color: #fff;
    border-color: #13AE6F;
  }
  
  .post-meta {
    padding-left: 0;
  }
  .post-meta li {
    list-style-type: none;
    display: inline-block;
    color: #888;
    font-weight: 500;
  }
  .post-meta li a {
    display: inline-block;
    background-color: rgba(19, 19, 19, 0.05);
    color: black;
    text-transform: capitalize;
    padding: 2px 8px;
    margin-bottom: 8px;
    margin-right: 2px;
    font-size: 14px;
    border-radius: 0;
  }
  .post-meta li a:hover {
    background-color: rgba(61, 234, 164, 0.25);
  }
  .post-meta li svg {
    display: inline-block;
  }
  
  .image-fallback {
    font-size: 0;
    height: 220px;
    width: 100%;
    background-color: #f2f2f2;
    text-align: center;
    border-radius: 0;
    display: inline-block;
  }
  .image-fallback::first-letter {
    font-size: 80px;
    line-height: 220px;
    font-weight: bold;
    color: #19234C;
  }
  .image-fallback.post-thumb-sm::first-letter {
    font-size: 50px;
    line-height: 60px;
  }
  .image-fallback-sm {
    height: 120px;
    width: 120px;
    margin-bottom: 20px;
  }
  .image-fallback-sm::first-letter {
    font-size: 50px;
    line-height: 120px;
  }
  .image-fallback-xs {
    height: 80px;
    width: 80px;
  }
  .image-fallback-xs::first-letter {
    font-size: 38px;
    line-height: 80px;
  }
  
  .breadcrumbs {
    background-color: transparent;
    padding: 15px 0;
  }
  .breadcrumbs a {
    color: #131313;
  }
  .breadcrumbs a:hover {
    text-decoration: underline;
  }
  .breadcrumbs a:last-child {
    color: rgba(19, 19, 19, 0.5);
    pointer-events: none;
  }
  

  /* blog top  banner css */

  #imgborderradius img
  {
    border-radius:45px !important;
  }
  .blog-container {
    display: flex;

    width: 100%;
    
}
.blog-col {
    text-align: center;
    flex: 1;


    padding: 1rem;
}

.colpad
{

padding: 25px;
}


.outer {
  position: relative;
  height: 50vh;
}
.inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}