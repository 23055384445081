.flex-basis-666
{
    flex-basis: 666px  !important;
}


.side-image.uic,
.image.uic {
background-color: white;
  background-image: url("./../../images/transport-infra.jpeg");
}


.side-image.uic,
.image.uicc {
background-color: white;
  background-image: url("./../../images/transport-infra.jpeg");
}
.side-image.uic,
.image.cld {
background-color: white;
  background-image: url("./../../images/cloud-computing.jpg");
}



.side-image.network,
.image.network {
background-color: white;
  background-image: url("./../../images/asset-12.png");
}

#loc > section.showcase {
  justify-content: space-around;
  align-items: unset;
  height: auto;
  padding: 5px;
}


/* esim banner */

.divbox
{
  display:block;
  margin: 0 auto;
}

.micro-test-banner {
  padding: 28px 0;
  min-height: 895px;
  text-align: center;
  background: -webkit-gradient(linear, left top, left bottom, from(#FFFEFE), to(#e2e1e8));
  /* background: linear-gradient(#FFFEFE 0%, #D6CCFF 100%); */
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.micro-test-banner::before {
  top: 151px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  max-width: 1920px;
  height: 313px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('./../../images/line-wave.svg');
}
.micro-test-banner-panel::before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
}

.micro-test-banner-panel {
  padding: 48px 70px;
  padding-top: 34px;
  position: relative;
  z-index: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}




.micro-test-banner-panel::before {
  left: 0px;
  bottom: -1px;
  width: 1363px;
  height: 541px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../images/esim-grdn.svg');
}

.micro-test-banner::before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
}

/* esim banner css end */

/* svg ani */


.svg {
  display: inline-block;
  height: 200px;
  width: 200px;
  line-height: 200px;
  text-align: center;
  background-color: #FFF;
  border-radius: 50%;
}

#svg {
  display: inline-block;
  vertical-align: middle;
  fill: #0057B8;
}
#svg path:nth-child(1) {
  -webkit-animation: pweek 1.8s linear infinite;
          animation: pweek 1.8s linear infinite;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
#svg path:nth-child(1):hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#svg path:nth-child(2) {
  -webkit-animation: pweek 1.8s linear infinite;
          animation: pweek 1.8s linear infinite;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
#svg path:nth-child(2):hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#svg path:nth-child(3) {
  -webkit-animation: pweek 1.8s linear infinite;
          animation: pweek 1.8s linear infinite;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
#svg path:nth-child(3):hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#svg path:nth-child(4) {
  -webkit-animation: pweek 1.8s linear infinite;
          animation: pweek 1.8s linear infinite;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
#svg path:nth-child(4):hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#svg path:nth-child(5) {
  -webkit-animation: pweek 1.8s linear infinite;
          animation: pweek 1.8s linear infinite;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
#svg path:nth-child(5):hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#svg path:nth-child(6) {
  -webkit-animation: pweek 1.8s linear infinite;
          animation: pweek 1.8s linear infinite;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
#svg path:nth-child(6):hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#svg path:nth-child(7) {
  -webkit-animation: pweek 1.8s linear infinite;
          animation: pweek 1.8s linear infinite;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
#svg path:nth-child(7):hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#svg path:nth-child(8) {
  -webkit-animation: pweek 1.8s linear infinite;
          animation: pweek 1.8s linear infinite;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
#svg path:nth-child(8):hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#svg path:nth-child(9) {
  -webkit-animation: pweek 1.8s linear infinite;
          animation: pweek 1.8s linear infinite;
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
#svg path:nth-child(9):hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#svg path:nth-child(10) {
  -webkit-animation: pweek 1.8s linear infinite;
          animation: pweek 1.8s linear infinite;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
#svg path:nth-child(10):hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#svg path:nth-child(11) {
  -webkit-animation: pweek 1.8s linear infinite;
          animation: pweek 1.8s linear infinite;
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}
#svg path:nth-child(11):hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#svg path:nth-child(12) {
  -webkit-animation: pweek 1.8s linear infinite;
          animation: pweek 1.8s linear infinite;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
#svg path:nth-child(12):hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#svg path:nth-child(13) {
  -webkit-animation: pweek 1.8s linear infinite;
          animation: pweek 1.8s linear infinite;
  -webkit-animation-delay: 1.3s;
          animation-delay: 1.3s;
}
#svg path:nth-child(13):hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#svg path:nth-child(14) {
  -webkit-animation: pweek 1.8s linear infinite;
          animation: pweek 1.8s linear infinite;
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}
#svg path:nth-child(14):hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#svg path:nth-child(15) {
  -webkit-animation: pweek 1.8s linear infinite;
          animation: pweek 1.8s linear infinite;
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}
#svg path:nth-child(15):hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#svg path:nth-child(16) {
  -webkit-animation: pweek 1.8s linear infinite;
          animation: pweek 1.8s linear infinite;
  -webkit-animation-delay: 1.6s;
          animation-delay: 1.6s;
}
#svg path:nth-child(16):hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#svg path:nth-child(17) {
  -webkit-animation: pweek 1.8s linear infinite;
          animation: pweek 1.8s linear infinite;
  -webkit-animation-delay: 1.7s;
          animation-delay: 1.7s;
}
#svg path:nth-child(17):hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#svg path:nth-child(18) {
  -webkit-animation: pweek 1.8s linear infinite;
          animation: pweek 1.8s linear infinite;
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s;
}
#svg path:nth-child(18):hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

@-webkit-keyframes pweek {
  0% {
    fill: #0057B8;
  }
  20% {
    fill: #F11E4A;
  }
  40% {
    fill: #F8A527;
  }
  60% {
    fill: #266D7F;
  }
  80% {
    fill: #82A;
  }
  100% {
    fill: #0057B8;
  }
}

@keyframes pweek {
  0% {
    fill: #0057B8;
  }
  20% {
    fill: #F11E4A;
  }
  40% {
    fill: #F8A527;
  }
  60% {
    fill: #266D7F;
  }
  80% {
    fill: #82A;
  }
  100% {
    fill: #0057B8;
  }
}


/* wave circle svg */



.app {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  text-align: center;
}
header h1 {
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  header h1 {
    font-size: 3rem;
  }
}
header p {
  color: #94a3b8;
  margin-bottom: 0.5rem;
}
header a {
  color: #7393c1;
}

.tag-list {
  width: 30rem;
  max-width: 90vw;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  gap: 1rem 0;
  position: relative;
  padding: 1.5rem 0;
  overflow: hidden;
}

.loop-slider .inner {
  display: flex;
  width: fit-content;
  animation-name: loop;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: var(--direction);
  animation-duration: var(--duration);
}

.tag {
  display: flex;
  align-items: center;
  gap: 0 0.2rem;
  color: #e2e8f0;
  font-size: 0.9rem;
  background-color: #334155;
  border-radius: 0.4rem;
  padding: 0.7rem 1rem;
  margin-right: 1rem;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2), 0 0.1rem 0.5rem rgba(0, 0, 0, 0.3), 0 0.2rem 1.5rem rgba(0, 0, 0, 0.4);
}
.tag span {
  font-size: 1.2rem;
  color: #64748b;
}

.fade {
  pointer-events: none;
  background: linear-gradient(90deg, #1e293b, transparent 30%, transparent 70%, #1e293b);
  position: absolute;
  inset: 0;
}

@keyframes loop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* mobile version */

@media only screen and (max-width:767px) {
#loc > section.showcase {
  justify-content: space-around;
  align-items: unset;
  height: auto;
  padding: 25px;
}

}