

@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');
@font-face {
  
    font-family: Oxygen;
    font-style: normal;
    font-weight: 300;
    src: url('./../../fonts/Oxygen/Oxygen-Regular.ttf') format('ttf'), url('./../../fonts/Oxygen/Oxygen-Bold.ttf'),url('./../../fonts/Oxygen/Oxygen-Light.ttf');
  }

  
  body{
    font-family: "Oxygen", sans-serif !important;
  font-weight:normal;
  font-style: normal;
    font-size: 18px;
  }

  body, html, .container {
    overflow-x: hidden;
}
  .hero h1 {
    /* font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: "Oxygen", sans-serif;
  font-weight: 700;
  font-style: normal;

    font-size: 47px;

    line-height: 47px;
    letter-spacing: 0px;
    text-align: left;
    color: #222;
  }
  body p {
    /* font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: "Oxygen", sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height:30px;
  }
  .header-bg
  {
    background: RGBA(247,250,251,0.7) !important;
  
  }
  
  li.nav-item a{
    font-size: 15px;
    font-weight: 600;

    letter-spacing: 0em;
    /* text-align: center; */
    color:#343a49;
    text-decoration: none;
    ;
  }
  li.nav-item a:hover
  {
    color: #202020;
  }
  ul.menu-css li a {
    text-decoration: none;
    color: inherit;
    
}

ul.list-unstyled li {
  margin-bottom: 10px;
}
  
.slick-prev:before {
  content: '←';
  color: #ff8b2a;
}

.slick-next:before, [dir=rtl] .slick-prev:before {
  content: '→';
  color: #ff8b2a;
}
  .sec1
  {
    width: 100%;
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
    overflow-x:hidden;
    overflow-y: hidden;
  }
  
  .section-title-square {
      position: absolute;
      display: inline-block;
      width: 130px;
      height: 130px;
      border: 1px solid #4b4b4b;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
  }
  /* #tsparticles
  
  {
  
    width: 500px;
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
  background-repeat: no-repeat;
  position: fixed;
  
  max-height: 500px;
  } */


  
  #tsparticles
  {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    /* z-index: -999; */
    background-attachment: fixed;
  
  }
  #four
  {
    opacity: 0;
  }
  .animate-element
  {
    opacity: 0;
  }

  .rainbow
  {
    background-color: #ace9a0;
    background-image: radial-gradient(at 93% 8%, hsla(219, 92%, 67%, 1) 0, hsla(219, 92%, 67%, 0) 50%),
    radial-gradient(at 70% 35%, hsla(179, 88%, 53%, 1) 0, hsla(179, 88%, 53%, 0) 50%),
    radial-gradient(at 46% 23%, hsla(8, 85%, 58%, 1) 0, hsla(8, 85%, 58%, 0) 50%),
    radial-gradient(at 22% 34%, hsla(33, 94%, 68%, 1) 0, hsla(33, 94%, 68%, 0) 50%),
    radial-gradient(at 26% 54%, hsla(127, 86%, 68%, 1) 0, hsla(127, 86%, 68%, 0) 50%),
    radial-gradient(at 85% 77%, hsla(165, 93%, 64%, 1) 0, hsla(165, 93%, 64%, 0) 50%),
    radial-gradient(at 45% 76%, hsla(118, 93%, 62%, 1) 0, hsla(118, 93%, 62%, 0) 50%),
    radial-gradient(at 59% 52%, hsla(109, 91%, 53%, 1) 0, hsla(109, 91%, 53%, 0) 50%),
    radial-gradient(at 48% 13%, hsla(213, 92%, 66%, 1) 0, hsla(213, 92%, 66%, 0) 50%),
    radial-gradient(at 45% 86%, hsla(170, 95%, 56%, 1) 0, hsla(170, 95%, 56%, 0) 50%),
    radial-gradient(at 6% 46%, hsla(102, 90%, 58%, 1) 0, hsla(102, 90%, 58%, 0) 50%),
    radial-gradient(at 3% 84%, hsla(222, 91%, 53%, 1) 0, hsla(222, 91%, 53%, 0) 50%),
    radial-gradient(at 44% 60%, hsla(24, 92%, 52%, 1) 0, hsla(24, 92%, 52%, 0) 50%),
    radial-gradient(at 92% 43%, hsla(115, 87%, 53%, 1) 0, hsla(115, 87%, 53%, 0) 50%),
    radial-gradient(at 69% 15%, hsla(311, 89%, 61%, 1) 0, hsla(311, 89%, 61%, 0) 50%),
    radial-gradient(at 41% 77%, hsla(320, 87%, 58%, 1) 0, hsla(320, 87%, 58%, 0) 50%),
    radial-gradient(at 68% 17%, hsla(107, 90%, 66%, 1) 0, hsla(107, 90%, 66%, 0) 50%),
    radial-gradient(at 55% 92%, hsla(336, 95%, 67%, 1) 0, hsla(336, 95%, 67%, 0) 50%);
    overflow-x: hidden;
    color: #010100;

  }

  .h2-heading {
    text-transform: uppercase;
    background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
   
      font-size:5vw;
      font-weight: 900;
    
 
  }

  .text-justify
  {
    text-align: righ;
  }



  .clippath
  {
    clip-path: circle(78% at 83% 10%);
  }
  .flex-we-procal {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: center;
  }

  .we-procal{
    width: 600px;
    margin-left: 25px;
    margin-left: 25px;
  }
  /* topnav menu css */

  .dropdown-menu {
  
    background-color: transparent !important;
   
}

.dropdown-toggle::after {
  display: inline-block;
  content: none !important;
}
.bgwhitemenu
{
background-color: white !important;
}
  .navbar-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  /* top menu css end */

 
  .col-megamenu {
    background:#FAF9F6;
    padding: 25px;
   min-height:425px;
     /* max-height:415px;
    overflow-y: auto; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    /* scrollbar-color: #007bff #f1f1f1; */
}
  /* Dimond section */

  .section-5d5 { 
    padding: 10px 0;
} 

.hero-c9w { 
    margin-top: 120px; 
    /* background: #333844 url("./../../images/we-are-procal-bg.jpg") no-repeat center center;  */
    /* padding-bottom: 190px;  */
    position: relative;
} 

:before,:after { 
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box; 
    box-sizing: border-box;
} 

.container-xsm { 
    padding-right: 15px; 
    padding-left: 15px; 
    margin-right: auto; 
    margin-left: auto;
} 

@media (min-width: 768px){ 
  .container-xsm { 
    width: 750px;
  } 
}     

@media (min-width: 992px){ 
  .container-xsm { 
    width: 970px;
  } 
}     

@media (min-width: 1200px){ 
  .container-xsm { 
    width: 1170px;
  } 
}     

.clearfix:before,.clearfix:after, .dl-horizontal dd::before, .dl-horizontal dd::after,.container-xsm:before,.container-xsm:after,.container-fluid:before,.container-fluid:after,.row:before,.row:after, .form-horizontal .form-group::before, .form-horizontal .form-group::after,.btn-toolbar:before,.btn-toolbar:after, .btn-group-vertical > .btn-group::before, .btn-group-vertical > .btn-group::after,.nav:before,.nav:after,.navbar:before,.navbar:after,.navbar-header:before,.navbar-header:after,.navbar-collapse:before,.navbar-collapse:after,.pager:before,.pager:after,.panel-body:before,.panel-body:after,.modal-footer:before,.modal-footer:after { 
    display: table; 
    content: " ";
} 

.clearfix::before, .clearfix::after, .dl-horizontal dd::before, .dl-horizontal dd::after, .container-xsm::before, .container-xsm::after, .container-fluid::before, .container-fluid::after, .row::before, .row::after, .form-horizontal .form-group::before, .form-horizontal .form-group::after, .btn-toolbar::before, .btn-toolbar::after, .btn-group-vertical > .btn-group::before, .btn-group-vertical > .btn-group::after, .nav::before, .nav::after, .navbar::before, .navbar::after, .navbar-header::before, .navbar-header::after, .navbar-collapse::before, .navbar-collapse::after, .pager::before, .pager::after, .panel-body::before, .panel-body::after, .modal-footer::before, .modal-footer::after { 
    display: table; 
    content: " ";
} 

.clearfix:after, .dl-horizontal dd::after,.container-xsm:after,.container-fluid:after,.row:after, .form-horizontal .form-group::after,.btn-toolbar:after, .btn-group-vertical > .btn-group::after,.nav:after,.navbar:after,.navbar-header:after,.navbar-collapse:after,.pager:after,.panel-body:after,.modal-footer:after { 
    clear: both;
} 

.text-wl7 { 
    text-align: center;
} 

.col-ajh { 
    position: relative; 
    min-height: 1px; 

} 

@media (min-width: 992px){ 
  .col-ajh { 
    float: left;
  } 

  .col-ajh { 
    width: 100%;
  } 
}     

.hero-c9w h2  { 
    color: #fff; 
    margin: 0 0 20px 0;
} 



.hero-c9w p  { 
    color: #ACB1B4;
} 



.btn-xdl { 
    display: inline-block; 
    padding: 6px 12px; 
    margin-bottom: 0; 
    font-size: 14px; 
    font-weight: 400; 
    line-height: 1.42857143; 
    text-align: center; 
    white-space: nowrap; 
    vertical-align: middle; 
    -ms-touch-action: manipulation; 
    touch-action: manipulation; 
    cursor: pointer; 
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
    background-image: none; 
    border: 1px solid transparent; 
    border-radius: 4px;
} 

.btn-xdl { 
    font-size: 13px; 
    border: solid 2px; 
    border-radius: 40px; 
    display: inline-block; 
    text-transform: uppercase;
} 

.btn-t6x { 
    padding: 15px 40px;
} 

.hero-c9w .btn-xdl  { 
    margin-top: 10px;
} 

a:active,a:hover { 
    outline: 0;
} 

#x:hover .active > a {
  color:yellow; 

}
a:hover { 
    color: #23527c; 
    text-decoration: underline;
} 

a:hover { 
    color: #D7405D; 
    text-decoration: none;
} 

.btn-xdl:hover { 
    color: #333; 
    text-decoration: none;
} 

.btn-xdl:hover { 
    color: #fff; 
    border-color: #FF5274; 
    background-color: #FF5274;
} 

.logo-dep { 
    -webkit-animation-name: floating-logo; 
    animation-name: floating-logo; 
    -webkit-animation-duration: 2s; 
    animation-duration: 2s; 
    -webkit-animation-iteration-count: infinite; 
    animation-iteration-count: infinite; 
    -webkit-animation-timing-function: ease-in-out; 
    animation-timing-function: ease-in-out;
} 

.hero-c9w .logo-aj4  { 
    width: 230px; 
    height: 230px; 
    position: absolute; 
    bottom: -285px; 
    left: 50%; 
    -webkit-transform: translateX(-50%); 
    -ms-transform: translateX(-50%); 
    transform: translateX(-50%);
} 

img { 
    border: 0;
} 

img { 
    vertical-align: middle;
} 

.hero-c9w i  { 
    /* font-family: "Nunito", sans-serif;  */
    font-weight: 300; 
    font-size: 25px; 
    color: #F69D52; 
    vertical-align: top; 
    margin-left: -8px; 
    font-style: normal;
} 


@keyframes floating-logo { 
  0% {  
      -webkit-transform: translate(-50%, 0); 
      transform: translate(-50%, 0); 
      transform: translate(-50%, 0px); 
  }  
  50% {  
      -webkit-transform: translate(-50%, 10px); 
      transform: translate(-50%, 10px); 
      transform: translate(-50%, 10px); 
  }  
  100% {  
      -webkit-transform: translate(-50%, 0); 
      transform: translate(-50%, 0); 
      transform: translate(-50%, 0px); 
  }  

} 

/* para section */


.section-6kj { 
  padding: 20px 0;
} 

:before,:after { 
  -webkit-box-sizing: border-box; 
  -moz-box-sizing: border-box; 
  box-sizing: border-box;
} 

.container-p7n { 
  padding-right: 15px; 
  padding-left: 15px; 
  margin-right: auto; 
  margin-left: auto;
} 

@media (min-width: 768px){ 
.container-p7n { 
  width: 750px;
} 
}     

@media (min-width: 992px){ 
.container-p7n { 
  width: 970px;
} 
}     

@media (min-width: 1200px){ 
.container-p7n { 
  width: 1170px;
} 
}     

.clearfix:before,.clearfix:after, .dl-horizontal dd::before, .dl-horizontal dd::after,.container-p7n:before,.container-p7n:after,.container-fluid:before,.container-fluid:after,.row-78f:before,.row-78f:after, .form-horizontal .form-group::before, .form-horizontal .form-group::after,.btn-toolbar:before,.btn-toolbar:after, .btn-group-vertical > .btn-group::before, .btn-group-vertical > .btn-group::after,.nav:before,.nav:after,.navbar:before,.navbar:after,.navbar-header:before,.navbar-header:after,.navbar-collapse:before,.navbar-collapse:after,.pager:before,.pager:after,.panel-body:before,.panel-body:after,.modal-footer:before,.modal-footer:after { 
  display: table; 
  content: " ";
} 

.clearfix::before, .clearfix::after, .dl-horizontal dd::before, .dl-horizontal dd::after, .container-p7n::before, .container-p7n::after, .container-fluid::before, .container-fluid::after, .row-78f::before, .row-78f::after, .form-horizontal .form-group::before, .form-horizontal .form-group::after, .btn-toolbar::before, .btn-toolbar::after, .btn-group-vertical > .btn-group::before, .btn-group-vertical > .btn-group::after, .nav::before, .nav::after, .navbar::before, .navbar::after, .navbar-header::before, .navbar-header::after, .navbar-collapse::before, .navbar-collapse::after, .pager::before, .pager::after, .panel-body::before, .panel-body::after, .modal-footer::before, .modal-footer::after { 
  display: table; 
  content: " ";
} 

.clearfix:after, .dl-horizontal dd::after,.container-p7n:after,.container-fluid:after,.row-78f:after, .form-horizontal .form-group::after,.btn-toolbar:after, .btn-group-vertical > .btn-group::after,.nav:after,.navbar:after,.navbar-header:after,.navbar-collapse:after,.pager:after,.panel-body:after,.modal-footer:after { 
  clear: both;
} 

.col-xza { 
  position: relative; 
  min-height: 1px; 
  padding-right: 15px; 
  padding-left: 15px;
} 

@media (min-width: 992px){ 
.col-xza { 
  float: left;
} 

.col-xza { 
  width: 100%;
} 
}     

.blog-jf9 { 
  padding-top: 10px;
} 

section.blog-jf9 { 
  /* background: #F3F4F8; */
} 

.row-78f { 
  margin-right: -15px; 
  margin-left: -15px;
} 

.clearfix::after, .dl-horizontal dd::after, .container-p7n::after, .container-fluid::after, .row-78f::after, .form-horizontal .form-group::after, .btn-toolbar::after, .btn-group-vertical > .btn-group::after, .nav::after, .navbar::after, .navbar-header::after, .navbar-collapse::after, .pager::after, .panel-body::after, .modal-footer::after { 
  clear: both;
} 

.col-c5w,.col-5a4,.col-o7t { 
  position: relative; 
  min-height: 1px; 
  padding-right: 15px; 
  padding-left: 15px;
} 

.col-5a4 { 
  float: left;
} 

.col-5a4 { 
  width: 100%;
} 

@media (min-width: 768px){ 
.col-o7t { 
  float: left;
} 

.col-o7t { 
  width: 100%;
} 
}     

@media (min-width: 992px){ 
.col-c5w { 
  float: left;
} 

.col-c5w { 
  width: 50%;
} 
}     

.blog-jf9 .col-okp  { 
  padding-right: 100px; 
  border-right: solid 1px #E6E9EA;
} 

.blog-jf9 .col-zqv  { 
  padding-left: 100px;
} 


  /* Dimond section end */

  /* usefull bg */

  .bib-t2x { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px;
} 





.jRb-ge9 { 
    box-sizing: border-box; 
    margin: 0px auto; 
    min-width: 0px; 
    display: grid; 
    grid-template-columns: repeat(12, 1fr); 
    gap: 240px 20px; 
    overflow: visible; 
    width: 100%; 
    max-width: 1720px; 
    height: auto; 
    padding-left: 32px; 
    padding-right: 32px;
} 

@media screen and (min-width: 40em){ 
  .jRb-ge9 { 
    padding-left: 32px; 
    padding-right: 32px;
  } 
}     

@media screen and (min-width: 52em){ 
  .jRb-ge9 { 
    padding-left: 48px; 
    padding-right: 48px;
  } 
}     

@media screen and (min-width: 64em){ 
  .jRb-ge9 { 
    padding-left: 64px; 
    padding-right: 64px;
  } 
}     

*,:after,:before { 
    box-sizing: inherit;
} 

:selection { 
    background-color: #000; 
    color: #fff; 
    -webkit-text-fill-color: #fff;
} 

.gLP-zmt { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    grid-column: 1 / -1;
} 

.dlK-r6n { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    justify-content: space-between; 
    flex-wrap: wrap;
} 

.jnp-izk { 
    display: flex;
} 

ul { 
    margin: 0; 
    padding: 0; 
    list-style: none;
} 

.gYF-pqx { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    flex-wrap: wrap; 
    justify-content: space-between;
} 


.bih-7pq { 
    box-sizing: border-box; 
    margin: 40px 0px 0px; 
    min-width: 0px; 
    display: block; 
    width: 100%; 
    color: rgb(255, 255, 255); 
    /* font-family: BauPro;  */
    font-size: 28px; 
    line-height: 1.5;
} 

@media screen and (min-width: 40em){ 
  .bih-7pq { 
    display: none; 
    width: auto;
  } 

  .bih-7pq { 
    font-size: 32px;
  } 
}     

@media screen and (min-width: 52em){ 
  .bih-7pq { 
    font-size: 40px;
  } 
}     

h3 { 
    margin: 0; 
    padding: 0; 
    font-weight: 400;
} 

.hZR-g39 { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    width: 100%; 
    color: rgb(255, 255, 255); 
    /* font-family: BauPro;  */
    font-size: 28px; 
    line-height: 1.5;
} 

@media screen and (min-width: 40em){ 
  .hZR-g39 { 
    width: auto;
  } 

  .hZR-g39 { 
    font-size: 32px;
  } 
}     

@media screen and (min-width: 52em){ 
  .hZR-g39 { 
    font-size: 40px;
  } 
}     

.eWK-5m6 { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    width: 100%; 
    color: rgb(150, 150, 150); 
    /* font-family: BauPro;  */
    font-size: 28px; 
    line-height: 1.5;
} 

@media screen and (min-width: 40em){ 
  .eWK-5m6 { 
    width: auto;
  } 

  .eWK-5m6 { 
    font-size: 32px;
  } 
}     

@media screen and (min-width: 52em){ 
  .eWK-5m6 { 
    font-size: 40px;
  } 
}     

.foj-w17 { 
    box-sizing: border-box; 
    margin: 40px 0px 0px; 
    min-width: 0px; 
    width: 100%;
} 

@media screen and (min-width: 40em){ 
  .foj-w17 { 
    width: calc(41.66% - 10px); 
    margin-top: 160px;
  } 
}     

.kxY-a9g { 
    box-sizing: border-box; 
    margin: 40px 0px 0px; 
    min-width: 0px; 
    width: 100%;
} 

@media screen and (min-width: 40em){ 
  .kxY-a9g { 
    width: calc(50% - 10px); 
    margin-top: 120px;
  } 
}     

a { 
    background-color: transparent;
} 

a { 
    color: inherit; 
    text-decoration: none;
} 

.eWK-5m6 a  { 
    transition: all 0.2s ease-in-out 0s;
} 

.eWK-5m6 a:hover { 
    color: rgb(255, 255, 255);
} 

.bfT-84d { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    display: block; 
    color: rgb(255, 255, 255);
} 

img { 
    border-style: none;
} 

.foj-w17 img  { 
    max-width: 100%; 
    width: 100%;
} 

h4 { 
    margin: 0; 
    padding: 0; 
    font-weight: 400;
} 

.dVm-t9z { 
    box-sizing: border-box; 
    margin: 16px 0px 0px; 
    min-width: 0px; 
    font-size: 18px; 
    line-height: 1.6; 
    color: rgb(150, 150, 150); 
    transition: all 0.2s ease-in-out 0s;
} 

@media screen and (min-width: 40em){ 
  .dVm-t9z { 
    font-size: 28px; 
    line-height: 1.34;
  } 
}     

@media screen and (min-width: 52em){ 
  .dVm-t9z { 
    font-size: 32px;
  } 
}     

.iIT-xd3 { 
    box-sizing: border-box; 
    margin: 8px 0px 0px; 
    min-width: 0px; 
    font-size: 24px; 
    line-height: 1.6; 
    transition: all 0.2s ease-in-out 0s;
} 

@media screen and (min-width: 40em){ 
  .iIT-xd3 { 
    font-size: 28px; 
    line-height: 1.34;
  } 
}     

@media screen and (min-width: 52em){ 
  .iIT-xd3 { 
    font-size: 32px;
  } 
}     

.kxY-a9g img  { 
    max-width: 100%; 
    width: 100%;
} 

.dfA-59k { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    display: block;
} 

@media screen and (min-width: 40em){ 
  .dfA-59k { 
    display: none;
  } 
}     

.iRr-oa5 { 
    box-sizing: border-box; 
    margin: 0px 0px 0px 16px; 
    min-width: 0px; 
    display: inline-block; 
    position: relative; 
    height: 20px; 
    width: 29px; 
    top: 0px;
} 

@media screen and (min-width: 40em){ 
  .iRr-oa5 { 
    display: none; 
    top: -2px;
  } 
}     

.bPq-t24 { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    display: none;
} 

@media screen and (min-width: 40em){ 
  .bPq-t24 { 
    display: block;
  } 
}     

.fKv-bg2 { 
    box-sizing: border-box; 
    margin: 0px 0px 0px 16px; 
    min-width: 0px; 
    display: none; 
    position: relative; 
    height: 20px; 
    width: 29px; 
    top: 0px;
} 

@media screen and (min-width: 40em){ 
  .fKv-bg2 { 
    display: inline-block; 
    top: -2px;
  } 
}     

.iRr-oa5 svg  { 
    fill: rgb(255, 255, 255); 
    height: 100%; 
    width: 100%;
} 

.fKv-bg2 svg  { 
    fill: rgb(150, 150, 150); 
    height: 100%; 
    width: 100%;
} 

  /* usefull bg css end */

  
.gradient-text {
  /* Fallback: Set a background color. */
  background-color: #CA4246;
  
  /* Create the gradient. */
   background-image: linear-gradient(
        45deg,
        #60636a 8.666%, 
        #082637 16.666%, 
        #0a3146 33.333%, 
        #5d6164 33.333%, 
        #7d7d50 50%, 
        #f9f3cc 50%, 
        #ffffef 66.666%, 
        #5d6164 33.333%
        /* #476098 66.666%,  */
        /* #02070b 83.333%, 
        #082637 83.333%) */
   )
        ;

  
  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 


}

.grand1 {
  color: #250529;
  background-image: -webkit-radial-gradient(closest-side, #0a2151 4%, #7550be 52%, #084298 69%);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-4-24 19:45:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
 @-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
            transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
            transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}
@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
            transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
            transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}



 /* grid section  */

 .pb-69f { 
  padding-bottom: 0!important;
} 

.section-zqn { 
  padding: 7rem 0;
} 

*,:after,:before { 
  box-sizing: border-box;
} 

:selection { 
  background: #000000; 
  color: #ffffff;
} 

/* menu background diagram css */

.dropdown-menu {

  border: none !important;

}


.bborder{

}
.cborder {

	height: 50vh;
}
.border {

	height: 100vh;
}

/* industries grid css end */
/* These were inline style tags. Uses id+class to override almost everything */

.freeflow {
  position: relative;
  bottom: 109px;
  left: 100px;
}

/* .bgglobe {
  background-image: radial-gradient(#00000055 1px,transparent 0);
}

.bgglobe {
  background-size: 16px 16px;
} */
.navbar .megamenu{ padding: 1rem; }

/* ============ desktop view ============ */
@media all and (min-width: 992px) {

  .navbar .has-megamenu{position:static!important;}
  .navbar .megamenu{left:0; right:0; width:100%; margin-top:0;  }

}	
/* ============ desktop view .end// ============ */

/* ============ mobile view ============ */
@media(max-width: 991px){
  .navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
    overflow-y: auto;
      max-height: 90vh;
      margin-top:10px;
  }
}
.freeflow-contact {
  position: absolute;
  /* top: 294px; */
  right: 214px;
  z-index: 1 !important;

}
.paddingcard
{
  padding: 100px;
}
/* location tab css */
li.nav-item.locationtab {
  width: auto;
  margin-right: 9px;
  margin-bottom: 25px;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #343a40 !important;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

button.nav-link {
  background-color: transparent !important;
  border: 1px solid #a9a4a4 !important;
  width: 150px;
  margin: 25px;

}

.nav-pills .nav-link {

  border-radius: 30px !important;
}
button.nav-link.active {

  background-color: lightblue !important;
  border: 1px solid #353535;
}
.flex-container-location {
  display: block;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content:left;
  align-items: normal;
  align-content: normal;

}

.block-icon
{
  display: flex;
  flex-direction:row;


}

.loc1
{
  margin-left: 25px;
}

.flex-items-location {
  min-width: 500px;
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  padding: 20px;
  margin-right: 5px;
  background: rgba( 215, 215, 215, 0.05 );
  /*box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );*/
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  backdrop-filter: blur( 4.5px );
  -webkit-backdrop-filter: blur( 4.5px );
  border-radius: 10px;
  min-height: 195px;
}
/* Location tab css end */
.container-mji { 
  padding-right: 15px; 
  padding-left: 15px; 
  margin-right: auto; 
  margin-left: auto;
} 

@media (min-width: 576px){ 
.container-mji { 
  max-width: 540px;
} 
}     

@media (min-width: 768px){ 
.container-mji { 
  max-width: 720px;
} 
}     

@media (min-width: 992px){ 
.container-mji { 
  max-width: 960px;
} 
}     

@media (min-width: 1200px){ 
.container-mji { 
  max-width: 1140px;
} 
}     

.loader-portfolio-p61 { 
  text-align: center; 
  display: none; 
  opacity: 0; 
  visibility: hidden; 
  left: 50%; 
  top: 0px; 
  -webkit-transform: translateX(-50%); 
  -ms-transform: translateX(-50%); 
  transform: translateX(-50%); 
  position: absolute;
} 

.row-3os { 
  display: -ms-flexbox; 
  display: flex; 
  -ms-flex-wrap: wrap; 
  flex-wrap: wrap; 
  margin-right: -15px; 
  margin-left: -15px;
} 

.loader-9cj { 
  width: 2rem; 
  height: 2rem; 
  margin: 2rem auto; 
  border-radius: 50%; 
  border: 0.3rem solid rgba(255, 255, 255, 0.3); 
  border-top-color: #ffffff; 
  -webkit-animation: 1.5s spin infinite linear; 
  animation: 1.5s spin infinite linear;
} 

.col-a3y,.col-tog,.col-pc8 { 
  position: relative; 
  width: 100%; 
  padding-right: 15px; 
  padding-left: 15px;
} 

@media (min-width: 576px){ 
.col-pc8 { 
  -ms-flex: 0 0 50%; 
  flex: 0 0 50%; 
  max-width: 50%;
} 
}     

@media (min-width: 768px){ 
.col-tog { 
  -ms-flex: 0 0 50%; 
  flex: 0 0 50%; 
  max-width: 50%;
} 
}     

@media (min-width: 992px){ 
.col-a3y { 
  -ms-flex: 0 0 33.333333%; 
  flex: 0 0 33.333333%; 
  max-width: 33.333333%;
} 
}     

.item-2rh { 
  border: none; 
  margin-bottom: 30px; 
  border-radius: 4px;
} 

a { 
  color: #007bff; 
  text-decoration: none; 
  background-color: transparent;
} 

a { 
  color: #ff2e63; 
  -webkit-transition: .3s all ease; 
  -o-transition: .3s all ease; 
  transition: .3s all ease;
} 

.gsap-reveal-69z { 
  line-height: 0;
} 

.item-pgv { 
  overflow: hidden;
} 

.item-pgv { 
  display: block; 
  overflow: hidden; 
  position: relative;
} 

.item-2rh a  { 
  display: block; 
  overflow: hidden; 
  position: relative; 
  border-radius: 4px;
} 

a:hover { 
  color: #0056b3; 
  text-decoration: underline;
} 

a:hover { 
  color: #ff2e63; 
  text-decoration: none;
} 

.gsap-reveal-69z .reveal-7r9  { 
  position: relative; 
  overflow: hidden;
} 

.gsap-reveal-69z .reveal-7r9 .cov-h3h  { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
  background: #eeeeee; 
  margin-left: -100%; 
  z-index: 9;
} 

.gsap-reveal-69z .reveal-7r9 .content-4kg  { 
  opacity: 0; 
  visibility: hidden;
} 

/* .item-pgv .overlay-1rh  { 
  position: relative; 
  z-index: 8; 
  opacity: 0; 
  visibility: hidden; 
  -webkit-transition: .3s all ease; 
  -o-transition: .3s all ease; 
  transition: .3s all ease;
}  */

.item-pgv .overlay-1rh  { 
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0;
} 

/* .item-pgv .overlay-1rh , .item-pgv .overlay-1rh::before { 
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0;
}  */

.item-pgv .overlay-1rh::before { 
  background: rgba(0, 0, 0, 0.5); 
  content: "";
} 

.item-pgv:hover .overlay-1rh { 
  opacity: 1; 
  visibility: visible;
} 

img { 
  vertical-align: middle; 
  border-style: none;
} 

.img-hwe { 
  max-width: 100%; 
  height: auto;
} 

.item-pgv img  { 
  -webkit-transition: .3s all ease; 
  -o-transition: .3s all ease; 
  transition: .3s all ease; 
  position: relative; 
  -webkit-transform: scale(1); 
  -ms-transform: scale(1); 
  transform: scale(1);
} 

.item-2rh a img  { 
  position: relative; 
  -webkit-transform: scale(1); 
  -ms-transform: scale(1); 
  transform: scale(1); 
  -webkit-transition: .3s all ease-in-out; 
  -o-transition: .3s all ease-in-out; 
  transition: .3s all ease-in-out;
} 

.item-pgv:hover img { 
  -webkit-transform: scale(1.09); 
  -ms-transform: scale(1.09); 
  transform: scale(1.09);
} 

.item-2rh:hover a img { 
  -webkit-transform: scale(1.05); 
  -ms-transform: scale(1.05); 
  transform: scale(1.05); 
  -webkit-transition: .3s all ease-in-out; 
  -o-transition: .3s all ease-in-out; 
  transition: .3s all ease-in-out;
} 

.item-pgv .overlay-1rh, .item-pgv .overlay-1rh::before { 
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0;
} 

[class*=" icon-"] { 
  /* font-family: 'icomoon' !important;  */
  speak: none; 
  font-style: normal; 
  font-weight: normal; 
  font-variant: normal; 
  text-transform: none; 
  line-height: 1; 
  -webkit-font-smoothing: antialiased; 
  -moz-osx-font-smoothing: grayscale;
} 

.item-pgv .icon-1wx  { 
  position: absolute; 
  right: 20px; 
  color: #ffffff; 
  top: 20px;
} 

.link-6dx:before { 
  content: "\e157";
} 



.item-pgv .content-h8n {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  z-index: 1 !important;
  color: #000 !important;
}
.content-h8n h4{
  color: #000 !important;
}

[class^="icon-"], [class*=" icon-"] { 
  font-family: 'icomoon' !important; 
  speak: none; 
  font-style: normal; 
  font-weight: normal; 
  font-variant: normal; 
  text-transform: none; 
  line-height: 1; 
  -webkit-font-smoothing: antialiased; 
  -moz-osx-font-smoothing: grayscale;
} 

h3 { 
  margin-top: 0; 
  margin-bottom: .5rem;
} 

h3 { 
  margin-bottom: .5rem; 
  font-weight: 500; 
  line-height: 1.2;
} 

h3 { 
  font-size: 1.75rem;
} 

h3 { 

  color: #333;
} 
.item-pgv .content-h8n h3 {
  color: #ffffff;
  margin: 0;
  padding: 0;

  font-weight: 700;
}
.item-pgv .content-h8n h3  { 
  font-size: 27px;
} 

.gsap-reveal-69z .reveal-7r9 .content-4kg .content-h8n h3  { 
  margin-bottom: 10px;
} 

p { 
  margin-top: 0; 
  margin-bottom: 1rem;
} 

.item-pgv .content-h8n p  { 
  color: #ffffff; 
  margin: 0; 
  padding: 0;
} 

.item-pgv .content-h8n p  { 
  font-size: 12px; 
  color: rgba(255, 255, 255, 0.5);
} 


@keyframes spin { 
100% {  
    -webkit-transform: rotate(360deg); 
    transform: rotate(360deg); 
    transform: rotate(360deg); 
}  

} 
/* These were inline style tags. Uses id+class to override almost everything */
#pos-zry.style-aAggA {
  position: relative;
  height: 1420.58px;
} 
#style-VgSH5.style-VgSH5 {  
 position: absolute;  
  left: 0px;  
  top: 0px;  
}  
#style-RosCv.style-RosCv {  
 margin-left: 102%;  
}  
#style-VBoe5.style-VBoe5 {  
 visibility: inherit;  
  opacity: 1;  
}  
#style-geFdo.style-geFdo {  
 visibility: inherit;  
  opacity: 1;  
  transform: matrix(1, 0, 0, 1, 0, 0);  
}  
#style-MKyDl.style-MKyDl {  
 position: absolute;  
  left: 380px;  
  top: 0px;  
}  
#style-6VgTp.style-6VgTp {  
 margin-left: 102%;  
}  
#style-29s3Q.style-29s3Q {  
 visibility: inherit;  
  opacity: 1;  
}  
#style-M9rK4.style-M9rK4 {  
 visibility: inherit;  
  opacity: 1;  
  transform: matrix(1, 0, 0, 1, 0, 0);  
}  
#style-Bqtdn.style-Bqtdn {  
 position: absolute;  
  left: 760px;  
  top: 0px;  
}  
#style-iaTgQ.style-iaTgQ {  
 margin-left: 102%;  
}  
#style-vGqCZ.style-vGqCZ {  
 visibility: inherit;  
  opacity: 1;  
}  
#style-gdj1w.style-gdj1w {  
 visibility: inherit;  
  opacity: 1;  
  transform: matrix(1, 0, 0, 1, 0, 0);  
}  
#style-7oMxj.style-7oMxj {  
 position: absolute;  
  left: 380px;  
  top: 353.763px;  
}  
#style-yIROP.style-yIROP {  
 margin-left: 102%;  
}  
#style-WHChB.style-WHChB {  
 visibility: inherit;  
  opacity: 1;  
}  
#style-jv9Qs.style-jv9Qs {  
 visibility: inherit;  
  opacity: 1;  
  transform: matrix(1, 0, 0, 1, 0, 0);  
}  
#style-xxfl8.style-xxfl8 {  
 position: absolute;  
  left: 760px;  
  top: 479.425px;  
}  
#style-rfsxH.style-rfsxH {  
 margin-left: 102%;  
}  
#style-1Lo3V.style-1Lo3V {  
 visibility: inherit;  
  opacity: 1;  
}  
#style-FE9zG.style-FE9zG {  
 visibility: inherit;  
  opacity: 1;  
  transform: matrix(1, 0, 0, 1, 0, 0);  
}  
#style-LDWzW.style-LDWzW {  
 position: absolute;  
  left: 0px;  
  top: 555px;  
}  
#style-I5s3h.style-I5s3h {  
 margin-left: 102%;  
}  
#style-px3v6.style-px3v6 {  
 visibility: inherit;  
  opacity: 1;  
}  
#style-jNBGk.style-jNBGk {  
 visibility: inherit;  
  opacity: 1;  
  transform: matrix(1, 0, 0, 1, 0, 0);  
}  
#style-poQoe.style-poQoe {  
 position: absolute;  
  left: 760px;  
  top: 742.175px;  
}  
#style-l3oc8.style-l3oc8 {  
 margin-left: 102%;  
}  
#style-NK6MC.style-NK6MC {  
 visibility: inherit;  
  opacity: 1;  
}  
#style-jhw8x.style-jhw8x {  
 visibility: inherit;  
  opacity: 1;  
  transform: matrix(1, 0, 0, 1, 0, 0);  
}  
#style-X2z1n.style-X2z1n {  
 position: absolute;  
  left: 380px;  
  top: 889.313px;  
}  
#style-BczkW.style-BczkW {  
 margin-left: 102%;  
}  
#style-RH5JG.style-RH5JG {  
 visibility: inherit;  
  opacity: 1;  
}  
#style-Ull37.style-Ull37 {  
 visibility: inherit;  
  opacity: 1;  
  transform: matrix(1, 0, 0, 1, 0, 0);  
}  
#style-Szznz.style-Szznz {  
 position: absolute;  
  left: 0px;  
  top: 964.812px;  
}  
#style-4qW3a.style-4qW3a {  
 margin-left: 102%;  
}  
#style-J5cBo.style-J5cBo {  
 visibility: inherit;  
  opacity: 1;  
}  
#style-DaAQe.style-DaAQe {  
 visibility: inherit;  
  opacity: 1;  
  transform: matrix(1, 0, 0, 1, 0, 0);  
}  
#style-dy7i7.style-dy7i7 {  
 position: absolute;  
  left: 760px;  
  top: 1144.34px;  
}  
#style-x8NYN.style-x8NYN {  
 margin-left: 102%;  
}  
#style-9tCo5.style-9tCo5 {  
 visibility: inherit;  
  opacity: 1;  
}  
#style-8CDhJ.style-8CDhJ {  
 visibility: inherit;  
  opacity: 1;  
  transform: matrix(1, 0, 0, 1, 0, 0);  
}  
#style-Ixt2L.style-Ixt2L {  
 position: absolute;  
  left: 380px;  
  top: 1269.31px;  
}  
#style-ZNpdP.style-ZNpdP {  
 margin-left: 102%;  
}  
#style-x94Z9.style-x94Z9 {  
 visibility: inherit;  
  opacity: 1;  
}  
#style-6KT24.style-6KT24 {  
 visibility: inherit;  
  opacity: 1;  
  transform: matrix(1, 0, 0, 1, 0, 0);  
}  
#style-UX8te.style-UX8te {  
 position: absolute;  
  left: 760px;  
  top: 1376.15px;  
}  
#style-oWYBd.style-oWYBd {  
 margin-left: 102%;  
}  
#style-FU6Ph.style-FU6Ph {  
 visibility: inherit;  
  opacity: 1;  
}  
#style-xi1Tt.style-xi1Tt {  
 visibility: inherit;  
  opacity: 1;  
  transform: matrix(1, 0, 0, 1, 0, 0);  
}  
 /* grid section end */

/* new section 3 css */




@media screen and (min-width: 40em){ 
  .hxE-7ac { 
    row-gap: 120px; 
    padding: 80px 32px 64px;
  } 
}     

@media screen and (min-width: 52em){ 
  .hxE-7ac { 
    row-gap: 240px; 
    padding-left: 48px; 
    padding-right: 48px; 
    padding-top: 120px;
  } 
}     

@media screen and (min-width: 64em){ 
  .hxE-7ac { 
    padding-left: 64px; 
    padding-right: 64px; 
    padding-top: 160px;
  } 
}     

*,:after,:before { 
    box-sizing: inherit;
} 

:selection { 
    background-color: #000; 
    color: #fff; 
    -webkit-text-fill-color: #fff;
} 

.gLP-6e7 { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    grid-column: 1 / -1;
} 

.vjW-ebj { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    flex-direction: column;
} 

@media screen and (min-width: 40em){ 
  .vjW-ebj { 
    flex-direction: row;
  } 
}     

@media screen and (min-width: 52em){ 
  .vjW-ebj { 
    flex-direction: row;
  } 
}     

.jnp-zcx { 
    display: flex;
} 

.chd-qdn { 
    box-sizing: border-box; 
    margin: 64px auto 0px; 
    min-width: 0px; 
    display: grid; 
    grid-template-columns: repeat(12, 1fr); 
    gap: 80px 20px; 
    overflow: visible; 
    width: 100%; 
    max-width: 1720px; 
    height: auto; 
    padding-left: 0px; 
    padding-right: 0px;
} 

@media screen and (min-width: 40em){ 
  .chd-qdn { 
    row-gap: 80px;
  } 
}     

@media screen and (min-width: 52em){ 
  .chd-qdn { 
    row-gap: 80px;
  } 
}     

@media screen and (min-width: 64em){ 
  .chd-qdn { 
    row-gap: 120px;
  } 
}     

.gtv-1of { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    grid-column: 1 / -1;
} 

@media screen and (min-width: 40em){ 
  .gtv-1of { 
    grid-column: 1 / -1;
  } 
}     

@media screen and (min-width: 52em){ 
  .gtv-1of { 
    grid-column: 1 / 8;
  } 
}     

.iuu-di4 { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    grid-column: 1 / -1;
} 

@media screen and (min-width: 40em){ 
  .iuu-di4 { 
    grid-column: 1 / -1;
  } 
}     

@media screen and (min-width: 52em){ 
  .iuu-di4 { 
    grid-column: 9 / -1;
  } 
}     

a { 
    background-color: transparent;
} 

a { 
    color: inherit; 
    text-decoration: none;
} 

.cJw-rgp { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    padding-top: 80px;
} 

@media screen and (min-width: 40em){ 
  .cJw-rgp { 
    padding-top: 80px;
  } 
}     

@media screen and (min-width: 52em){ 
  .cJw-rgp { 
    padding-top: 120px;
  } 
}     

@media screen and (min-width: 64em){ 
  .cJw-rgp { 
    padding-top: 160px;
  } 
}     

.cQw-osf { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    padding-top: 80px;
} 

@media screen and (min-width: 40em){ 
  .cQw-osf { 
    padding-top: 80px;
  } 
}     

@media screen and (min-width: 52em){ 
  .cQw-osf { 
    padding-top: 80px;
  } 
}     

@media screen and (min-width: 64em){ 
  .cQw-osf { 
    padding-top: 120px;
  } 
}     

.cYU-8yg { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    font-weight: bold;
} 

.gDc-4xw { 
    box-sizing: border-box; 
    margin: 32px 0px 0px; 
    min-width: 0px; 
    font-weight: normal;
} 

@media screen and (min-width: 40em){ 
  .gDc-4xw { 
    margin-left: 0px; 
    margin-top: 32px;
  } 
}     

@media screen and (min-width: 52em){ 
  .gDc-4xw { 
    margin-left: 48px; 
    margin-top: 0px;
  } 
}     

.cNc-3r8 { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    width: 100%;
} 

img { 
    border-style: none;
} 

.fpv-6x2 { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    max-width: 100%; 
    width: 100%; 
    height: 360px; 
    object-fit: cover;
} 

@media screen and (min-width: 40em){ 
  .fpv-6x2 { 
    height: 480px;
  } 
}     

.bhE-e2o { 
    box-sizing: border-box; 
    margin: 32px 0px 0px; 
    min-width: 0px; 
    color: rgb(95, 98, 103);
} 

.hXJ-6m6 { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    max-width: 100%; 
    width: 100%; 
    height: 275px; 
    object-fit: cover;
} 

.bfd-qam { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    flex-wrap: wrap;
} 

.cjw-jjp { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    color: rgb(0, 0, 0); 
    /* font-family: BauPro;  */
    font-size: 18px; 
    line-height: 1.33;
} 

@media screen and (min-width: 40em){ 
  .cjw-jjp { 
    font-size: 24px;
  } 
}     

.bkw-ck9 { 
    box-sizing: border-box; 
    margin: 0px 8px 0px 0px; 
    min-width: 0px; 
    /* font-family: BauPro;  */
    font-size: 18px; 
    line-height: 1.33;
} 

@media screen and (min-width: 40em){ 
  .bkw-ck9 { 
    font-size: 24px;
  } 
}     

.eZp-tw4 { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    font-size: 16px; 
    letter-spacing: 0px; 
    line-height: 1.61111; 
    /* font-family: BauPro; */
} 

.iqr-8g2 { 
    box-sizing: border-box; 
    margin: 0px; 
    min-width: 0px; 
    /* font-family: BauPro;  */
    font-size: 18px; 
    line-height: 1.33;
} 

@media screen and (min-width: 40em){ 
  .iqr-8g2 { 
    font-size: 24px;
  } 
}     



/* section 3 end */
  .parallax {
  
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    height: 80vh; /* Adjust the height as needed */
  }
  
  
  
  .sec2 {
    width: 100%;
    /* height: 600px; */
    background-color: #fff;
    background-size: 100% auto;
    /* background-position: 50% 50%; */
    z-index: -999;
    background-image: url(./../../images/abstract2.png);
    background-attachment: fixed;
    background-repeat: no-repeat;
    min-height: 100vh;
  }
  .posrelat17ive
  {
    position: relative;
    overflow: hidden !important;
  }
  .sec3
  {
    width: 100%;
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url(./images/data-insight-1.jpg); */
    background-image:url(./../../images/data-insight-1.jpg);
      background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;
  }
  
  
  .sec4
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    background-image:url(./../../images/potential-bg.png);
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;
  }
  .sec4 h2
  {
    /* font-family: Raleway; */
    font-size: 60px;
  }
  
  .sec5
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    background-image:url(./../../images/export-bg.png);
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;
  }
  
  .pos-40
  {
    position: relative;
    top: 200px;
  }
  
  .hero-black {
    background-color: RGBA(000,000,000,0.5);
    padding: 25px;
    border-radius: 11px;
  }
  .sec6
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    background-image:url(./../../images/cost-bg.jpg);
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;
  }
  .pos-30
  {
    position: relative;
    top: 150px;
  }
  
  
  .sec7
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    background-image:url(./../../images/reduce.png);
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;
  }
  
  .sec-annoucement
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    background-image:url(./../../images/dubai-bg.png);
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;
  }
  
  
  .sec8
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    background-image:url(./../../images/security.png);
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;
  }
  
  .sec9
  {
    width: 100%;
    background-image:url(./../../images/client-wave-bg.svg);
    background-color: #fff;
    background-size: cover;
    /* background-position:inherit; */
    background-position: center bottom -344px;
    z-index: -999;
  
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;
    overflow: hidden;
  }

 img.invert
  {
    filter: invert();
  }

  img.bright
  {
    filter:brightness(0.1);
  }

  .scale7
  {
    scale: 1.5;
  }

  .certification-margin
  {
    margin-bottom: 10px;
  }
  
  .sec10
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
  
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;
    overflow: hidden;
  
  }
  .sec11
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    /* background-image:url(./../../images/certificate.png); */
    background-image:url(./../../images/our-certification-black-bg.jpg);
    /* background-attachment: fixed; */
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;
  }
  
  
  /* our-certification */

  .certification {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: normal;
    align-content: normal;
  }
  
  .certification-items {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
  }
  

  /* our-certification css end */
  .sec12
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -999;
    background-image:url(./../../images/sec12-bg.png);
    /* background:  url(./../../images/sec12-bg.png); */
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;
  }
  
  
  .sec13
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    /* background-position: 50% 50%; */
    z-index: -999;
    background-image:url(./../../images/hear-from-you.jpg);
    background: linear-gradient(rgba(39, 33, 33, 0.5), rgba(9, 9, 9, 0.3)), url(./../../images/hear-from-you.jpg) center center no-repeat;
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;
  }
  
  
  .sec14
  {
    width: 100%;
  
    background-color: #fff;
    background-size: cover;
    /* background-position: 50% 50%; */
    z-index: -999;
  
    background-attachment: fixed;
    /* dimensions are important, ensure it is tall enough to scroll */ 
    min-height: 100vh;
    color: green;
  }
  .connectivity {
    background-color: rgba(256, 256, 256, 0.6);
    border-radius: 25px;
    padding: 48px 135px;
  }
  .learnmore
  {
    font-weight: bold;
  }
  
  .connectivity h2 {
    color: black;
    font-size: 38px;
  }
  .pos-20
  {
    position: relative;
    top: 100px;
  }
  
  .sec2 h2,.sec3 h2,.sec4 h2,.sec5 h2,.sec6 h2,.sec7 h2,.sec8 h2,.sec9 h2,.sec10 h2,.sec11 h2,.sec12 h2,.sec13 h2,.sec14 h2,.sec15, .sec16 h2,.sec-annoucement h2 {

    font-size: 53px;
  font-weight: 600;
  white-space: pre-line;
  }

  .satelite {
    width: 400px !important;
    height: auto;
    position: relative;
    left: 696px;
    bottom: -50px;
  }
  
  .lead {
    font-weight: 500 !important;
    margin-top: 1.5rem;
    line-height: 35px;
    word-break: break-word;
}
  .bgcc
  {
    background-color: #333;
  }
  
  .botm-20 {
    position: relative;
    bottom: -350px;
  }
  
  .input-container {
    position: relative;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 27px;
}
  .input-field {
    width: 100%;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #fff;
  }
  
  .send-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 17px 19px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
  }
  .container-orbit{
    position: relative;
    width: 400px;
    height: 400px;
    border: 2px solid black;
    border-radius: 50%;
    margin: 50px auto;
  }
  
   .orbit {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
    top: 50%;
    left: 50%;
  }
  
  .center-orbit {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: black;
    border-radius: 50%;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
  }
  
  /* menu css start */

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    z-index: 152522555 !important;
}

  .titleN
  {
    color: rgb(4, 1, 6);
    font-weight: 800;
    font-size: 20px;
  }
  hr.new2 {
    width: 100%;
    border-top: 0.2px dashed rgb(46, 44, 44);
  }
  
  .top-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color:rgba(255, 255, 255);
  
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #000;
    font-weight: 400;
    height: 50px;
    padding: 1em;
    background-position: fixed;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }
  
  .menu-css {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .menu-css > li {
    margin: 0 1rem;
    cursor: pointer;
  }
  
  
  .menu-css > li:hover {
    animation: bounce .3s linear;
    color: rebeccapurple;
  }
  .menu-button-container {
    display: none;
    height: 100%;
    width: 30px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  #menu-toggle {
    display: none;
  }
  
  .menu-button,
  .menu-button::before,
  .menu-button::after {
    display: block;
    background-color: #000;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: ease-out 100ms;
    border-radius: 2px;


  }

  .globe-scale
  {
    scale: 1.2;
  }
  
  .menu-button::before {
    content: '';
    margin-top: -8px;
  }
  
  .menu-button::after {
    content: '';
    margin-top: 8px;
  }
  
  #menu-toggle:checked + .menu-button-container .menu-button::before {
    margin-top: 0px;
    transform: rotate(405deg);
  }
  
  #menu-toggle:checked + .menu-button-container .menu-button {
    background: rgba(255, 255, 255, 0);
  }
  
  #menu-toggle:checked + .menu-button-container .menu-button::after {
    margin-top: 0px;
    transform: rotate(-405deg);
  }
  
  .p0{
    padding: 0px !important;
  }

  .border-top-right
  {
    border-top-right-radius: 10px;
  }

  .border-top-left
  {
    border-top-left-radius: 10px;
  }

  .border-bottom-left
  {
    border-bottom-left-radius: 10px;
  }

  .border-bottom-right
  {
    border-bottom-right-radius: 10px;
  }
  /* menu css end */
  .pulse {
    animation: pulse 5s infinite ease-in-out alternate;
  }
  @keyframes pulse {
    from {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }
  
  /* clint section flexbox */
  
  .samesizeimg
  {
    width: auto;
    height:366px;
  }
  .flex-container-client img {
    width: 200px;
    height: auto;
    object-fit: scale-down;
  }
  
   .flex-container-client {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: space-between;
  } 
   
  .flex-items-client
  {
    margin-bottom:44px !important;
    padding: 15px !important;
  }
 .greycel
 {
  filter: brightness(0.2);
 }

  .dive-cobalt .box02 {
    height: 370px;
    border-radius: 25px;
  }
  
  .dive-cobalt .box02 img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .dive-cobalt .box03 {
    /* height: 370px; */
    border-radius: 20px;
  }
  .grid-caption {
    padding: 15px 15px 30px;
    position: absolute;
    bottom: 0;
  }
  .box01, .box02, .box03 {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  p.card-text {
    font-size: 15px;
  }
  
  .greytxt
  {
    color:#918E8E;
  }
  /* client sectiond end */
  
  /* card  section start */
  
  /* Create three equal columns that sits next to each other */
  .sixg.card {
    position: relative;
    overflow: hidden;
  }
  
  .sixg .card-img, .card-img-top, .card-img-bottom {
    width: 100%;
    height: 418px;
  }
  .card-img-overlay {
    position: absolute;
    top: 210px !important;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(233, 233, 233, 0.8); /* Adjust opacity here */
    color: #222222;
    padding: 20px;
    opacity:0.9; /* Initially hide overlay */
    transition: opacity 0.5s ease-in-out; /* Smooth transition */
  }
  .card-img-overlay:hover {
    position: absolute;
    top: 0px !important;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(28, 27, 27, 0.8); /* Adjust opacity here */
    color: #fff;
    padding: 20px;
    opacity:0.9; /* Initially hide overlay */
    transition: opacity 0.5s ease-in-out; /* Smooth transition */
    transition: top 2s ease 0;
    transition-timing-function: ease;
    transition: top 1s ease 0s;
  }
  /* card section css end */
  
  /* helmet css */
  
  /* .gallery {
      position: absolute;
      width: 100%;
      height: 100vh;
      overflow: hidden;
  } */
  
  .cards {
      position: absolute;
      width: 14rem;
      height: 18rem;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
  
  .cards li {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 14rem;
      height: 18rem;
      text-align: center;
      line-height: 18rem;
      font-size: 2rem;
      /* font-family: sans-serif; */
      background-color: #9d7cce;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0.8rem;
  }
  
  .actions {
      position: absolute;
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);
  }
  
  button {
    display:inline-block;
    outline: none;
    border: none;
    padding: 8px 14px;
    background: #fd7e14;

    color: #ffffff;
    text-decoration: none;
    margin: 0 auto 10px;
    -webkit-border-radius: 4;
    -moz-border-radius: 4;
    border-radius: 4px;
    padding: 12px 25px;
    /* font-family: "Signika Negative", sans-serif; */
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    font-size: 13px;
    line-height: 18px;
  }
  
  .send-button:hover {
    background: #57a818;
    background-image: -webkit-linear-gradient(top, #57a818, #4d9916);
    background-image: -moz-linear-gradient(top, #57a818, #4d9916);
    background-image: -ms-linear-gradient(top, #57a818, #4d9916);
    background-image: -o-linear-gradient(top, #57a818, #4d9916);
    background-image: linear-gradient(to bottom, #57a818, #4d9916);
    text-shadow: 0px 1px 0px #32610e;
    -webkit-box-shadow: 0px 1px 0px fefefe;
    -moz-box-shadow: 0px 1px 0px fefefe;
    box-shadow: 0px 1px 0px fefefe;
    color: #ffffff;
    text-decoration: none;
  }
  
  button {
    font-size: 20px;
    font-weight: 400;
  }
  
  a {
    color: #88ce02;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  /* helmet css end */
  
  .slide-container
  {
    height: 100vh;
  }
  /* footer cess */
  .footer-social-icon img {
    width: 8% !important;
}
  .sec-footer
  {
    background-color: #212529;
  }
  .sec-footer a {
    color: #6c757d;
  }
  
  .list-inline-item a{
    color: #6c757d;
  }
  a:hover {
    color: #fec503;
    text-decoration: none;
  }
  
  ::selection {
    background: #fec503;
    text-shadow: none;
  }
  
  .footer-column {
    text-align: left !important;
  }
  
  
  .footer-column p {
    text-align: left !important;
    font-size: 17px;
    font-weight: 400;
  }
  
  footer {
  
    background-color: #212529;
    padding:0px;
  }
  footer li.nav-item a {
    
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    /* text-align: center; */
    text-align: left;
    color: #fff;
  }
  .footer-column:not(:first-child) {
    padding-top: 2rem;
  }
  @media (min-width: 768px) {
    .footer-column:not(:first-child) {
      padding-top: 0rem;
    }
  }
  
  .footer-column {
    text-align: center;
  }
  .footer-column .nav-item .nav-link {
    padding: 0.1rem 0;
  }
  .footer-column .nav-item span.nav-link {
    color: #6c757d;
  }
  .footer-column .nav-item span.footer-title {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
  }
  .footer-column .nav-item .fas {
    margin-right: 0.5rem;
  }
  .footer-column ul {
    display: inline-block;
  }
  @media (min-width: 768px) {
    .footer-column ul {
      text-align: left;
    }
  }
  
  ul.social-buttons {
    margin-bottom: 0;
  }
  
  ul.social-buttons li a:active,
  ul.social-buttons li a:focus,
  ul.social-buttons li a:hover {
    background-color: #fec503;
  }
  
  ul.social-buttons li a {
    font-size: 20px;
    line-height: 40px;
    display: block;
    width: 40px;
    height: 40px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    color: #fff;
    border-radius: 100%;
    outline: 0;
    background-color: #1a1d20;
  }
  
  footer .quick-links {
    font-size: 90%;
    line-height: 40px;
    margin-bottom: 0;
    text-align: left !important;
    text-transform: none;
    /* font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif; */
  }
  
  .copyright {
    color: white;
  }
  
  .fa-ellipsis-h {
    color: white;
    padding: 2rem 0;
  }
  .data-ana {
    background-color: rgba(256, 256, 256, 0.6);
    border-radius:25px;
    padding: 60px 80px !important;
    font-weight: 400;
    color: #000 !important;
  }
  
  .block
  {
    clear: both;
    padding-top: 4px;
  }
  
  
  .stickynotes
  {
    overflow: hidden;
    position: relative;
  }
  /* unwanted code */
  
  /* continuesly change bg css */
  
  .sec15
  {
    width:100%;
    /* height: 100vh; */
  }
  .Section_top{
  
    position: relative;
    background-image:url("./../../images/img2.jpg");
  
    animation: change 40s infinite ease-in;
    animation-timing-function: linear;
  }
  .content_bg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
  
  }
  .mb6
  {
    padding-bottom: 1rem;
  }
  .content_bg h1{
    color: #fff;
    font-size: 60px;
    letter-spacing: 15px;
  }
  .content_bgt h1 span{
    color: #111;
  }
  .content_bg a{
    background: #85c1ee;
    padding: 10px 24px;
    text-decoration: none;
    font-size: 18px;
    border-radius: 20px;
  }
  .content_bg a:hover{
    background: #034e88;
    color: #fff;
  }
  
  @keyframes change{
    10%
    {
        background-image: url("./../../images/img3.jpg");
    }
    20%
    {
        background-image:url("./../../images/img2.jpg");
    }
    40%
    {
        background-image:url("./../../images/img5.jpg");
    }
    60%
    {
        background-image:url("./../../images//img4.jpg");
    }
    80%
    {
        background-image: url("./../../images/img5.jpg");
    }
    100%
    {
        background-image: url("./../../images/img1.jpg");
    }
  }
  /* continuesly bg change css end */
  
  /* demo for pin section css */
  
  /* pin section end */
  
  /* image gallery css */
  
  .content-container {
    display: flex;
  }
  .content-container > * {
    width: 50%;
  }
  
  .right-content {
    padding-right: 15px;
  }
  
  .left-content {
    height: 100vh;
    position: relative;
  }
  
  .left-content > * {
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    visibility: hidden;
  }
  
  @media screen and (max-width: 800px) {
    .left-content {
      display: none;
    }
    .right-content {
      padding-left: 15px;
    }
  }
  
  /* image gallery css end */
  
  
  /* footer 2 code */
  ul {
    margin: 0px;
    padding: 0px;
  }
  .footer-section {
  background: #151414;
  position: relative;
  }
  .footer-cta {
  border-bottom: 1px solid #373636;
  }
  .single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
  }
  .cta-text {
  padding-left: 15px;
  display: inline-block;
  }
  .cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
  }
  .cta-text span {
  color: #757575;
  font-size: 15px;
  }
  .footer-content {
  position: relative;
  z-index: 2;
  }
  .footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
  }
  .footer-logo {
  margin-bottom: 30px;
  }
  .footer-logo img {
    max-width: 200px;
  }
  .footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
      color: #7e7e7e;
  line-height: 28px;
  }
  .footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  /* font-family: 'Poppins', sans-serif; */
  margin-bottom: 20px;
  }
  .footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
  }
  .footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
  }
  .facebook-bg{
  background: #3B5998;
  }
  .twitter-bg{
  background: #55ACEE;
  }
  .google-bg{
  background: #DD4B39;
  }
  .footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
  }
  .footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  /* background: #ff5e14; */
  }
  .footer-widget ul li {
  display: inline-block;
  float: left;
  width:60%;
  margin-bottom: 12px;
  }
  .footer-widget ul li a:hover{
  color: #ff5e14;
  }
  .footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
  }

  form#subscribe input {
    margin-right: 25px !important;
}
  .subscribe-form {
  position: relative;
  overflow: hidden;
  }
  .subscribe-form input {
  width: 100%;
  padding: 12px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
  }
  .subscribe-form button {
    position: absolute;
    right: 36px;
    background: #3f3e3d;
    /* padding: 13px 20px; */
    border: 1px solid #3f3e3d;
    top: 34px;
}
  .subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
  }
  .copyright-area{
  background: #202020;
  padding: 25px 0;
  }
  .copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
  }
  .copyright-text p a{
  color: #ff5e14;
  }
  .footer-menu li {
  display: inline-block;
  margin-left: 20px;
  }
  .footer-menu li:hover a{
  color: #ff5e14;
  }
  .footer-menu li a {
  font-size: 14px;
  color: #878787;
  }
  /* footer2 code end */
  
  .client-box {
  
    margin: 10px;
    opacity: 0; /* Initially set to be invisible */
  }
  
  /* unwanted code start1 */
  
  
  section.horizontal {
    overflow-x: hidden;
  }
  
  section.horizontal .pin-wrap,
  section.horizontal .animation-wrap {
      display: flex;
      position: relative;
      z-index: 1;  
      height: 100vh;
  }
  
  
  section.horizontal .item {
      position: relative;
      padding: 150px 80px;
      flex: 0 0 500px;
      height: calc(100vh - 300px);
      display: flex;
      align-items: center;
      line-height: 1.7;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border-right: 1px solid rgba(0, 0, 0, 0.06);
      background-color: transparent;
  }
  
  section.horizontal .item:before {
      position: absolute;
      font-size: 100px;
      opacity: .13;
      font-weight: bold;    
      z-index: -1;
      -webkit-transform: translate(-30px, -50px);
      transform: translate(-30px, -50px);
      line-height: 1;
  }
  
  
  
  
  section.horizontal .animation-wrap.to-right {
      counter-reset: item;
      float: left;
  }
  
  section.horizontal .animation-wrap.to-left {
      counter-reset: item 11;
      float: right; 
  }
  
  section.horizontal .animation-wrap.to-right .item:before {
      counter-increment: item;
      content: counter(item);
  }
  
  section.horizontal .animation-wrap.to-left .item:before {
      counter-increment: item -1;
      content: counter(item);
  }
  
  
  
  section.horizontal .animation-wrap .item:nth-child(2n+2) {
      align-items: flex-start;
  }
  
  section.horizontal .animation-wrap .item:nth-child(4n+4) {
      align-items: flex-end;
  }
  
  /* unwanted code end */
  
  
  /* slider hori */
  #jsx
  {
    width: 50px;
    height: 50px;
    background-color: red;
  
  }
  
  #rdx
  {
    width: 50px;
    height: 50px;
    background-color: gray;
  
  }
  /* slider end */

  

  /* navbar menu animation css */

  @media all and (min-width: 992px) {

    .top-large-margin
      {
        margin-top: 120px !important;
        
      }

      .botm-20 {
        position: relative;
        bottom: -241px;
    }

    .globe-scale
    {
      scale: 1.2;
    }
    
    
    .navbar .dropdown-menu-end{ right:0; left: auto;  }
    .navbar .nav-item .dropdown-menu{  display:block; opacity: 0;  visibility: hidden; transition:.5s; margin-top:0;  }
    .navbar .nav-item:hover .nav-link{ color: #fff;  }
    .navbar .dropdown-menu.fade-down{ top:80%; transform: rotateX(-75deg); transform-origin: 0% 0%; }
    .navbar .dropdown-menu.fade-up{ top:180%;  }
    .navbar .nav-item:hover .dropdown-menu{ transition: .3s; opacity:1; visibility:visible; top:100%; transform: rotateX(0deg); }
  }
  li.nav-item.dropdown.has-megamenu:hover {
    background-color: #efefef;
    border-radius: 6px;
}



ul.navbar-nav.\31 ms-auto:hover {
  background-color: #efefef;
  padding: 5px;
  border-radius: 6px;
  text-align: center;
}

  /* menu css end */
  
  /* below code for only mobile version */



   

  @media all and (min-width:1800px) {

    .sec2 h2, .sec3 h2, .sec4 h2, .sec5 h2, .sec6 h2, .sec7 h2, .sec8 h2, .sec9 h2, .sec10 h2, .sec11 h2, .sec12 h2, .sec13 h2, .sec14 h2, .sec15, .sec16 h2 {
      font-size: 71px;
 
 
  }
  body p {
    font-size: 20px;
    line-height: 35px;
}

.certification-margin
{
margin-bottom: 160px;
}

.globe-scale
{
scale: 1.7;
}


.mobiletopp
{
  margin-top: 0px;
}

.lead {
font-size: 1.75rem !important;
font-weight: 300;
line-height: 45px;
}


.hero h1 {

font-size: 71px;

}


   }

   

  @media (min-width: 768px) {
    .navbar-brand.abs
        {
            position: absolute;
            width: 100%;
            left: 0;
            text-align: center;
        }
    }


  
  @media only screen and (max-width:767px) {
    /* canvas {
      visibility:hidden;
    } */
    #tsparticles {
    display: none;
      top: 137px;

  }
.container-case {
    display: block;
    flex-direction: row;
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;
}
    .display-5 {

      font-weight: 600;
   
  }
    h4 {
 

      margin-top: 25px !important;
  }

    p.card-text {
      font-size: 13px;
      line-height: 23px;
  }
    .connectivity h2 {
      color: black;
      font-size: 30px;
  }

    .connectivity {

      padding:35px 47px;
  }

    .mobiletopp
    {
      margin-top: 10px;
    }
  
    .satelite {
    
      left:0px;
      bottom: 85px;
    }

    .subscribe-form button {
  
      right: 27px;
 
      top: 29px;
  }
  }
  

  
    /* below for menu responsive */
  @media (max-width: 700px) {
  
  
    .menu-button-container {
      display: flex;
    }
    .menu-css {
      position: absolute;
      top: 0;
      margin-top: 50px;
      left: 0;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
    #menu-toggle ~ .menu-css li {
      height: 0;
      margin: 0;
      padding: 0;
      border: 0;
      transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    #menu-toggle:checked ~ .menu-css li {
      border: 1px solid #333;
      height: 2.5em;
      padding: 0.5em;
      transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    .menu-css > li {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0.5em 0;
      width: 100%;
      color: white;
      background-color: #222;
    }
    .menu-css > li:not(:last-child) {
      border-bottom: 1px solid #444;
    }
  }

  /* Acc */
 
  /* :horizontal css end */

  .box {
    transform: translateY(100vh);
    animation: floatUp 4s linear infinite alternate;
  }
  @keyframes floatUp {
    0% {
      transform: translateY(20vh);
    }
    100% {
      transform: translateY(-20vh);
    }
  }
  .boxouter {
    display: inline-block;
    position: relative;
    animation: wiggle 1s ease-in-out infinite alternate;
  }
  @keyframes wiggle {
    0% {
      transform: translateX(-50px);
    }
    100% {
      transform: translateX(50px);
    }
  }
  .goo-defs {
    /* display: none; */
    width: 0;
    height: 0;
  }
  /* h1 {
    width: calc(100% - 400px);
    text-align: center;
    position: absolute;
    top: 30vh;
    color: #fff;
    font: 700 120px/120px 'Orbitron', sans-serif;;
    text-shadow: 0px 0px 4px #ffffff;
  } */
  